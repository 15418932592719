// i18next initialization part (you would normally have this in your i18n config)
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // if using React
  .init({
    resources: {
      en: {
        translation: {
          intlDateTime: "{{val, datetime}}",
          "1ST_HALF": "FIRST HALF",
          "2ND_HALF": "SECOND HALF",
          HALF_TIME: "HALF TIME",
          FULL_TIME: "FULL TIME",
          Stadium: "Stadium",
          Live: "Live",
          "vs.": "vs.",
          leg: `Leg {{val}}`,
          Substitution: "Substitution",
          " replaces ": " replaces ",
          "GOAL!!!": "GOAL!!!",
          disallowed: "disallowed",
          weekday: "{{val, weekday}}",
        },
      },
      de: {
        translation: {
          intlDateTime: "{{val, datetime}}",
          "1ST_HALF": "ERSTE HALBZEIT",
          "2ND_HALF": "ZWEITE HALBZEIT",
          HALF_TIME: "HALBZEIT",
          FULL_TIME: "SPIELENDE",
          Stadium: "Stadion",
          Live: "Live",
          "vs.": "vs.",
          leg: "{{val}}",
          Substitution: "Wechsel",
          " replaces ": " kommt für ",
          "GOAL!!!": "TOR!!!",
          disallowed: "aberkannt",
          weekday: "{{val, weekday}}",
        },
      },
      es: {
        translation: {
          intlDateTime: "{{val, datetime}}",
          "1ST_HALF": "PRIMER TIEMPO",
          "2ND_HALF": "SEGUNDO TIEMPO",
          HALF_TIME: "MEDIO TIEMPO",
          FULL_TIME: "FIN DEL PARTIDO",
          Stadium: "Estadio",
          Live: "En Vivo",
          "vs.": "vs.",
          leg: "Partido {{val}}",
          Substitution: "Cambio",
          " replaces ": " sustituye a ",
          "GOAL!!!": "¡GOL!",
          disallowed: "anulado",
          weekday: "{{val, weekday}}",
        },
      },
      pt: {
        translation: {
          intlDateTime: "{{val, datetime}}",
          "1ST_HALF": "PRIMEIRO TEMPO",
          "2ND_HALF": "SEGUNDO TEMPO",
          HALF_TIME: "INTERVALO",
          FULL_TIME: "FIM DO JOGO",
          Stadium: "Estádio",
          Live: "Ao Vivo",
          "vs.": "vs.",
          leg: "{{val}}",
          Substitution: "Substituição",
          " replaces ": " substitui ",
          "GOAL!!!": "GOL!",
          disallowed: "anulado",
          weekday: "{{val, weekday}}",
        },
      },
      it: {
        translation: {
          intlDateTime: "{{val, datetime}}",
          "1ST_HALF": "PRIMO TEMPO",
          "2ND_HALF": "SECONDO TEMPO",
          HALF_TIME: "INTERVALLO",
          FULL_TIME: "FINE PARTITA",
          Stadium: "Stadio",
          Live: "In Diretta",
          "vs.": "vs.",
          leg: "Gara {{val}}",
          Substitution: "Sostituzione",
          " replaces ": " sostituisce ",
          "GOAL!!!": "GOL!",
          disallowed: "annullato",
          weekday: "{{val, weekday}}",
        },
      },
      fr: {
        translation: {
          intlDateTime: "{{val, datetime}}",
          "1ST_HALF": "PREMIÈRE MI-TEMPS",
          "2ND_HALF": "DEUXIÈME MI-TEMPS",
          HALF_TIME: "MI-TEMPS",
          FULL_TIME: "FIN DU MATCH",
          Stadium: "Stade",
          Live: "En Direct",
          "vs.": "vs.",
          leg: "Manche {{val}}",
          Substitution: "Remplacement",
          " replaces ": " remplace ",
          "GOAL!!!": "BUT!",
          disallowed: "refusé",
          weekday: "{{val, weekday}}",
        },
      },
      zh: {
        translation: {
          intlDateTime: "{{val, datetime}}",
          "1ST_HALF": "上半场",
          "2ND_HALF": "下半场",
          HALF_TIME: "中场休息",
          FULL_TIME: "全场结束",
          Stadium: "体育场",
          Live: "直播",
          "vs.": "对",
          leg: "第{{val}}轮",
          Substitution: "换人",
          " replaces ": " 替换 ",
          "GOAL!!!": "进球!!",
          disallowed: "取消",
          weekday: "{{val, weekday}}",
        },
      },
    },
    lng: "de", // default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
