import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { EntryVisibility, Feed, FeedEntry, GameStateMachine } from "../types";
import Page from "./Page";
import { BreadcrumbNavigation } from "./BreadcrumbNav";
import { Button } from "./ui/button";
import { ToggleGroup, ToggleGroupItem } from "./ui/toggle-group";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { EditableFeedEntryCard, FeedEntryCard } from "./FeedEntryCard";
import { useUser } from "@clerk/clerk-react";
import BellSlashIcon from "@heroicons/react/24/outline/BellSlashIcon";
import { BellIcon } from "@heroicons/react/24/outline";
import { useCurrentFeedSession } from "@/feedSession";
import {
  useFeatureFlags,
  useFeedAndEntries,
  useFixture,
  useMediaQuery,
  useSMContext,
  useSTSContext,
  useWriterAndTranscription,
} from "@/hooks";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "./ui/resizable";
import NaiveJsonDisplay from "./JsonDisplay";
import { ImperativePanelHandle } from "react-resizable-panels";
import { PanelRightIcon } from "lucide-react";
import JsonHeaderDisplay from "./JsonHeaderDisplay";
import {
  datetimeFormatter,
  flattenJson,
  getUrlViewTranscription,
  getUrlViewWriter,
  useBackendToken,
} from "@/utils";
import { formatDistanceToNow } from "date-fns";
import InterventionForm from "./InterventionForm";
import { TranscriptionCard } from "./TranscriptionsPage";
const SessionStream = lazy(() => import("./SessionStream"));
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { WriterCard } from "./WritersPage";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import ApplicationError from "./ErrorBoundary";
import LineupComponent from "./LineupComponent";
import ComponentErrorBoundry from "./ComponentErrorBoundry";
import { Checkbox } from "./ui/checkbox";
import TeamComponent from "./TeamComponent";
import GameData from "./GameStatus";
import GoalScorersDisplay from "./GoalScorerDisplay";
import GameCardDisplay from "./GameCardDisplay";

function DefaultVisibilityToggle({
  value,
  setValue,
}: {
  value: EntryVisibility;
  setValue: (visibility: EntryVisibility) => void;
}) {
  const options = [
    {
      value: "draft",
      label: "Countdown",
      description: "Entries are hidden until the countdown ends.",
    },
    {
      value: "visible",
      label: "Instant",
      description: "Entries are instantly visible.",
    },
    {
      value: "hidden",
      label: "Manual",
      description: "Entries are hidden until manually published.",
    },
  ];

  return (
    <ToggleGroup
      type="single"
      size={"sm"}
      className="space-x-1"
      value={value}
      onValueChange={(v) => v != "" && setValue(v as EntryVisibility)}
    >
      <TooltipProvider>
        {options.map((option) => (
          <Tooltip key={option.value}>
            <TooltipTrigger asChild>
              <ToggleGroupItem
                value={option.value}
                className={`${value === option.value ? " bg-white text-black hover:bg-white" : ""}`}
              >
                {option.label}
              </ToggleGroupItem>
            </TooltipTrigger>
            <TooltipContent>
              <p>{option.description}</p>
            </TooltipContent>
          </Tooltip>
        ))}
      </TooltipProvider>
    </ToggleGroup>
  );
}

export default function FeedPage() {
  const { orgId, feedId } = useParams();
  if (!orgId || !feedId) {
    return null;
  }
  return <FeedPageContent orgId={orgId} feedId={feedId} />;
}

function FeedInfoCard({
  fixture,
  feed,
  session,
  sessionUri,
}: {
  fixture: any;
  feed: Feed;
  session: any | null;
  sessionUri: string;
}) {
  if (feed.id === undefined) {
    throw new Error("Feed not found");
  }

  const { writer, transcription } = useWriterAndTranscription(feed.id);
  const fields = [
    [
      { label: "Feed ID", attr: "feed.id" },
      { label: "Match ID", attr: "fixture.id" },
      {
        label: "Langfuse Session ID",
        attr: "langfuse_session_url",
        href: (v: string) =>
          `https://cloud.langfuse.com/project/clumtlw5v00005l413evhvg9j/sessions/${encodeURIComponent(v)}`,
      },
    ],
    [
      {
        label: "Kickoff",
        attr: "fixture.kickoff",
        formatter: (v: string) => datetimeFormatter(v ? v + "Z" : v),
      },

      {
        label: "Writer Started At",
        attr: "session.created_at",
        formatter: datetimeFormatter,
      },
      {
        label: "Last Healthcheck",
        attr: "session.last_session_healthcheck",
        formatter: (v: string) => {
          try {
            const elapsed =
              (new Date().getTime() - new Date(v).getTime()) / 1000;
            return elapsed > 60 * 20
              ? datetimeFormatter(v)
              : formatDistanceToNow(v);
          } catch {
            return v;
          }
        },
      },
    ],
    [
      {
        label: "Writer ID",
        attr: "writer.id",
        href: (w: string) => getUrlViewWriter(w, feed?.organization_id),
      },
      {
        label: "Transcription ID",
        attr: "transcription.id",
        href: (t: string) => getUrlViewTranscription(t, feed?.organization_id),
      },
      { label: "Persona ID", attr: "feed.persona_id" },
    ],
  ];

  const data = {
    feed,
    session,
    fixture,
    writer,
    transcription,
    langfuse_session_url: sessionUri,
  };
  return (
    <div className="space-y-4">
      <JsonHeaderDisplay
        variant="card"
        title={fixture.name}
        fields={fields}
        data={flattenJson(data)}
      ></JsonHeaderDisplay>
    </div>
  );
}

function FeedPageContent({ orgId, feedId }: { orgId: string; feedId: string }) {
  const { user } = useUser();
  const token = useBackendToken();
  const {
    currentSession: session,
    currentSessionError: sessionError,
    setDefaultVisibility,
    sessionUri,
  } = useCurrentFeedSession(orgId!, feedId!);
  const { feed, entries } = useFeedAndEntries(orgId, feedId);
  const stsContext = useSTSContext(feed?.fixture_id);
  const smContext = useSMContext(feed?.fixture_id);
  const fixtureData = useFixture(feed?.fixture_id || feed?.fixture?.id);

  const [highlightedEntry, setHighlightedEntry] = useState<FeedEntry | null>(
    null,
  );
  const [updateCounter, setUpdateCounter] = useState(0);
  const prevLengthRef = useRef(entries.length);
  const [newEntries, setNewEntries] = useState<FeedEntry[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { isFeatureEnabled } = useFeatureFlags();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const panelRef = useRef<ImperativePanelHandle | null>(null);
  const { writer, transcription } = useWriterAndTranscription(feedId);
  const [showAllObjections, setShowAllObjections] = useState(false);
  const [updateSubstitutions, setUpdateSubstitutions] = useState(true);

  const togglePanel = () => {
    const panel = panelRef.current;
    if (!panel) {
      return;
    }
    if (panel.getSize()) {
      panel.resize(0);
    } else {
      panel.resize(40);
    }
  };

  const bellEnabled = (user?.unsafeMetadata.bell_enabled as boolean) || false;

  useEffect(() => {
    if (!bellEnabled) return;

    if (entries.length > prevLengthRef.current) {
      const audio = new Audio("/bell.mp3");
      audio.volume = 0.3;
      audio.play();
    }

    prevLengthRef.current = entries.length;
  }, [bellEnabled, entries.length]);

  const addEntryDraft = () => {
    const lastEntry = entries[0] || {};
    const defaultGameTime = {
      period: "BEFORE_MATCH",
      period_order: 0,
      label: "",
      has_minute: false,
      minute: 0,
      stoppage_minute: 0,
      seconds: 0,
      stoppage_seconds: 0,
    };

    const newEntry: FeedEntry = {
      feed_id: feedId,
      type: "commentary",
      sport: feed?.sport || "football",
      text: "",
      game_time: {
        ...defaultGameTime,
        ...lastEntry.game_time,
      },
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      visible_at: new Date().toISOString(),
      order: lastEntry.order || 0,
      evaluation: {
        objections: [],
      },
      visibility: "hidden",
      annotations: [],
      metadata: {
        langfuse_trace_url: "",
      },
      images: [],
      audios: [],
      videos: [],
    };
    setNewEntries([newEntry, ...newEntries]);
  };

  function BellButton() {
    return (
      <Button
        disabled={user == null}
        variant="outline"
        onClick={() => {
          user?.update({
            unsafeMetadata: { bell_enabled: !bellEnabled },
          });
        }}
      >
        {bellEnabled ? (
          <>
            <BellIcon title="Alert Disabled" className="h-6 w-6 " />
            <p className="pl-2">Sound</p>
          </>
        ) : (
          <>
            <BellSlashIcon title="Alert Enabled" className="h-6 w-6 " />
            <p className="pl-2">Muted</p>
          </>
        )}
      </Button>
    );
  }

  if (sessionError) {
    return <ApplicationError errorMessage={sessionError.message} />;
  }

  return (
    <div className="h-[calc(100vh-102px)] w-full">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel>
          <div className="h-full overflow-y-scroll pb-8">
            <Page>
              <header>
                <div className="mb-2 mt-4 flex justify-between">
                  <BreadcrumbNavigation
                    items={[
                      { label: "Feeds", href: `./..` },
                      {
                        label: feed?.title || "Feed",
                        href: `.`,
                      },
                    ]}
                  />
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <EllipsisHorizontalIcon className="mr-4 h-6 w-6" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        onClick={() => {
                          setShowAllObjections(!showAllObjections);
                        }}
                      >
                        {showAllObjections
                          ? "Hide All Objections"
                          : "Show All Objections"}
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div className="flex justify-between">
                  <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                    {feed?.title || ""} Feed
                  </h1>
                  {isFeatureEnabled("feed_sidebar") && (
                    <Button
                      className={`cursor-pointer text-gray-400 ${isCollapsed ? "" : "bg-gray-100"}`}
                      onClick={togglePanel}
                      variant={"ghost"}
                    >
                      <PanelRightIcon className="h-6 w-6" />
                    </Button>
                  )}
                </div>
                <div className="flex justify-between">
                  {/* <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                {feed?.title || ""} Feed
              </h1> */}
                  {/* <Button
                variant={"destructive"}
                onClick={() => {
                  if (window.confirm("Do you want to continue?")) {
                    hideAllEntries();
                  }
                }}
              >
                Hide All
              </Button> */}
                </div>
              </header>
              {isFeatureEnabled("feed_info_card") &&
                fixtureData.fixture &&
                feed && (
                  <div className="mt-4">
                    {/* TODO: write a custom <FeedInfoCard {...fixtureData} {...sessionData} {...feedData} /> component that also includes data from FeedSession. It should also make Match ID a Link. */}
                    <FeedInfoCard
                      fixture={fixtureData.fixture}
                      feed={feed}
                      session={session}
                      sessionUri={sessionUri}
                    />
                  </div>
                )}
              <main className="mt-8">
                {isDesktop ? (
                  <div className="mt-4 flex items-center justify-between">
                    <BellButton />
                    <DefaultVisibilityToggle
                      value={session?.default_visibility || "draft"}
                      setValue={setDefaultVisibility}
                    />
                    <Button onClick={addEntryDraft}>Add Entry</Button>
                  </div>
                ) : (
                  <div className="mt-4 flex flex-col items-center space-y-4">
                    <DefaultVisibilityToggle
                      value={session?.default_visibility || "draft"}
                      setValue={setDefaultVisibility}
                    />
                    <div className="flex w-full justify-between">
                      <BellButton />
                      <Button onClick={addEntryDraft}>Add Entry</Button>
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  <div className="space-y-4">
                    {newEntries.map((entry, index) => (
                      <EditableFeedEntryCard
                        key={index}
                        entry={entry}
                        close={() => {
                          setNewEntries(newEntries.filter((e) => e !== entry));
                        }}
                      />
                    ))}
                  </div>
                  <div className="mt-4">
                    <FeedEntryList
                      entries={entries}
                      showAllObjections={showAllObjections}
                      setHighlightedEntry={(entry: FeedEntry | null) => {
                        const panel = panelRef.current;
                        if (panel) {
                          panel.resize(40);
                        }
                        setUpdateCounter((prevCounter) => prevCounter + 1);
                        setHighlightedEntry(entry);
                      }}
                    />
                  </div>
                </div>
              </main>
            </Page>
          </div>
        </ResizablePanel>
        {isFeatureEnabled("feed_sidebar") && (
          // <div className="h-screen overflow-y-scroll">
          <>
            <ResizableHandle />

            <ResizablePanel
              ref={panelRef}
              defaultSize={0}
              onResize={(size) => {
                if (size === 0) {
                  setIsCollapsed(true);
                } else {
                  setIsCollapsed(false);
                }
              }}
            >
              <div className="h-full overflow-y-scroll">
                {isFeatureEnabled("feed_stream_player") && (
                  <Suspense fallback={<div>Loading Stream...</div>}>
                    {token && session && (
                      <SessionStream
                        token={token}
                        feedId={feedId!}
                        session={session}
                        updateCounter={updateCounter}
                        highlightedEntry={highlightedEntry}
                      />
                    )}
                  </Suspense>
                )}
                {(stsContext != null || smContext != null) && (
                  <ComponentErrorBoundry>
                    <GameData stsContext={stsContext} smContext={smContext} />
                    <GoalScorersDisplay
                      stsContext={stsContext}
                      smContext={smContext}
                    />
                  </ComponentErrorBoundry>
                )}
                {(stsContext != null || smContext != null) && (
                  <ComponentErrorBoundry>
                    <div className="prose prose-sm m-4 flex gap-4">
                      <div>
                        <TeamComponent
                          stsContext={stsContext}
                          smContext={smContext}
                          teamRole="home"
                        />
                      </div>
                      <div>
                        <TeamComponent
                          stsContext={stsContext}
                          smContext={smContext}
                          teamRole="away"
                        />
                      </div>
                    </div>
                  </ComponentErrorBoundry>
                )}
                {(stsContext != null || smContext != null) && (
                  <ComponentErrorBoundry>
                    <GameCardDisplay
                      stsContext={stsContext}
                      smContext={smContext}
                    />
                  </ComponentErrorBoundry>
                )}
                <Accordion type="single" collapsible className="w-full">
                  {isFeatureEnabled("feed_time_intervention") &&
                    session?.football_stream_state && (
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="px-4 font-semibold">
                          Time Intervention
                        </AccordionTrigger>
                        <AccordionContent>
                          <InterventionForm
                            sessionURI={sessionUri}
                            gameState={
                              session?.football_stream_state as GameStateMachine
                            }
                          />
                        </AccordionContent>
                      </AccordionItem>
                    )}

                  {stsContext != null && (
                    <AccordionItem value="item-2">
                      <AccordionTrigger className="px-4 font-semibold">
                        Tactical Lineup
                      </AccordionTrigger>
                      <AccordionContent>
                        <ComponentErrorBoundry>
                          <div className="ml-4 flex items-center space-x-2">
                            <Checkbox
                              id="updateSubstitutions"
                              checked={updateSubstitutions}
                              onCheckedChange={(v) =>
                                setUpdateSubstitutions(
                                  v == "indeterminate" ? true : v,
                                )
                              }
                            />
                            <label
                              htmlFor="updateSubstitutions"
                              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              Update with Substitutions
                            </label>
                          </div>
                          <div className="max-w-[28rem]">
                            <LineupComponent
                              stsContext={stsContext}
                              isVertical={true}
                              updateSubstitutions={updateSubstitutions}
                            />
                          </div>
                        </ComponentErrorBoundry>
                      </AccordionContent>
                    </AccordionItem>
                  )}

                  {isFeatureEnabled("transcriptions") && transcription && (
                    <AccordionItem value="item-3">
                      <AccordionTrigger className="px-4 font-semibold">
                        Transcription Task
                      </AccordionTrigger>
                      <AccordionContent className="px-1">
                        <TranscriptionCard transcription={transcription} />
                      </AccordionContent>
                    </AccordionItem>
                  )}
                  {isFeatureEnabled("writers") && writer && (
                    <AccordionItem value="item-4">
                      <AccordionTrigger className="px-4 font-semibold">
                        Writer Task
                      </AccordionTrigger>
                      <AccordionContent className="px-1">
                        <WriterCard writer={writer} />
                      </AccordionContent>
                    </AccordionItem>
                  )}
                  {isFeatureEnabled("dev_tools") && (
                    <>
                      <AccordionItem value="item-5">
                        <AccordionTrigger className="px-4 font-semibold">
                          Full JSON
                        </AccordionTrigger>
                        <AccordionContent>
                          <NaiveJsonDisplay jsonData={session} />
                        </AccordionContent>
                      </AccordionItem>
                      <AccordionItem value="item-6">
                        <AccordionTrigger className="px-4 font-semibold">
                          STS Livedata
                        </AccordionTrigger>
                        <AccordionContent>
                          <NaiveJsonDisplay jsonData={stsContext} />
                        </AccordionContent>
                      </AccordionItem>
                      <AccordionItem value="item-7">
                        <AccordionTrigger className="px-4 font-semibold">
                          SM Livedata
                        </AccordionTrigger>
                        <AccordionContent>
                          <NaiveJsonDisplay jsonData={smContext} />
                        </AccordionContent>
                      </AccordionItem>
                    </>
                  )}
                </Accordion>
              </div>
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </div>
  );
}

function FeedEntryList({
  entries,
  showAllObjections,
  setHighlightedEntry,
}: {
  entries: FeedEntry[];
  showAllObjections: boolean;
  setHighlightedEntry: (entry: FeedEntry | null) => void;
}) {
  useEffect(() => {
    const focusOnElement = () => {
      const hash = window.location.hash;
      const id = hash.replace("#", "");
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };
    focusOnElement();
  }, [entries.length]);

  return (
    <div className="space-y-4">
      {entries.map((entry) => (
        <FeedEntryCard
          key={entry.id}
          showObjections={showAllObjections}
          entry={entry}
          setHighlightedEntry={setHighlightedEntry}
        />
      ))}
    </div>
  );
}
