import { useParams, useSearchParams } from "react-router-dom";
import Page from "./Page";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

import { Card } from "./ui/card";
import {
  flattenJson,
  getOrgBaseUrl,
  getUrlCreateTranscription,
  getUrlCreateWriter,
  getUrlViewTranscription,
} from "../utils";
import { useFeatureFlags, useFixture } from "@/hooks";
import QueryDateRangePicker from "./QueryDateRangePicker";
import JsonHeaderDisplay from "./JsonHeaderDisplay";
import FilteredByFixtures from "./FilteredByFixture";
import { useTranscriptions } from "@/hooks";
import { Task, TranscriptionConfig } from "@/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Switch } from "./ui/switch";
import { Label } from "./ui/label";

const transcriptionFields = [
  [
    {
      label: "ID",
      attr: "id",
    },
    {
      label: "Source",
      attr: "definition.metadata.source",
    },
    {
      label: "Source Language",
      attr: "definition.metadata.language",
    },
    {
      label: "Command",
      attr: "definition.command",
      formatter: (v: string) => `${v.slice(0, 25)}...`,
      detail: {
        title: "Command",
        fields: [
          [
            {
              label: "Command",
              attr: "definition.command",
            },
          ],
        ],
      },
    },
  ],

  [
    {
      label: "Match ID",
      attr: "definition.metadata.fixture_id",
      href: (value: number) =>
        getOrgBaseUrl("fixtures", String(value)).toString(),
    },
    {
      label: "Match",
      attr: "fixture.name",
    },
    {
      label: "Latest Run ID",
      attr: "latest_run_id",
    },
  ],
  [
    {
      label: "Task",
      attr: "definition.task",
    },
    {
      label: "Status",
      attr: "status",
    },
    {
      label: "After",
      attr: "after",
    },
    {
      label: "Dedup ID",
      attr: "dedup_id",
    },
  ],
  [
    {
      label: "Output Directory",
      attr: "definition.metadata.dirpath",
      // NOTE(liamvdv): I not not quite know why, but the slash at the end is important, else it will lose the port.
      href: (value: string) => `http://ticker:8000${value}/`,
    },
    {
      label: "Output URI",
      attr: "definition.metadata.s3_uri",
    },
  ],
];

function TranscriptionCard({
  transcription,
}: {
  transcription: Task<TranscriptionConfig>;
}) {
  const { orgId } = useParams();
  const { isFeatureEnabled } = useFeatureFlags();
  const fixture_id = transcription.definition.metadata.fixture_id;
  const { fixture, isLoading, error } = useFixture(fixture_id);
  if (isLoading) {
    return <Card>Loading...</Card>;
  }
  if (error) {
    return <Card>Error: {String(error)}</Card>;
  }
  const data = { ...transcription, fixture: fixture };
  return (
    <JsonHeaderDisplay
      variant={"card" as const}
      title="Transcription"
      data={flattenJson(data)}
      fields={transcriptionFields}
    >
      {isFeatureEnabled("writers") && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <EllipsisVerticalIcon className="h-6 w-6"></EllipsisVerticalIcon>
            {/* <Button variant="outline">Actions</Button> */}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <a
                href={getUrlViewTranscription(transcription.id, orgId!)}
                target="_blank"
                rel="noreferrer"
              >
                View Transcription
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <a
               
                href={getUrlCreateTranscription(
                  String(transcription.definition.metadata.fixture_id),
                  orgId!,
                  transcription.definition.metadata.language,
                  transcription.definition.metadata.is_video ?? false,
                  transcription.definition.metadata.dirpath + "/original.ts",
                  false,
                  transcription.definition.metadata.backend ?? "api_whisper",
                )}
                target="_blank"
                rel="noreferrer"
              >
                Create from Template
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <a
                href={getUrlCreateWriter(
                  transcription.id,
                  orgId!,
                  "DE_BUNDESLIGA_V12",
                  -104,
                )}
                target="_blank"
                rel="noreferrer"
              >
                Schedule Feed for Home-Fans
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <a
                href={getUrlCreateWriter(
                  transcription.id,
                  orgId!,
                  "DE_BUNDESLIGA_V12",
                  -97,
                )}
                target="_blank"
                rel="noreferrer"
              >
                Schedule Feed for Away-Fans
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem disabled={data.latest_run_id != null}>
              <a
                href={getUrlViewTranscription(transcription.id, orgId!)}
                target="_blank"
                rel="noreferrer"
              >
                Enable/Disable
              </a>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </JsonHeaderDisplay>
  );
}

function TranscriptionList({
  transcriptions,
}: {
  transcriptions: Task<TranscriptionConfig>[];
}) {
  return (
    <ul className="flex flex-col gap-2">
      {transcriptions.map((transcription) => (
        <li key={transcription.id}>
          <TranscriptionCard transcription={transcription} />
        </li>
      ))}
    </ul>
  );
}

function TranscriptionsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || undefined;
  const fixture_id = searchParams.get("fixture_id")
    ? parseInt(searchParams.get("fixture_id") || "")
    : undefined;
  const limit = parseInt(searchParams.get("limit") || "50");
  const language = searchParams.get("language") || "any";
  const source = searchParams.get("source") || undefined;
  const since = searchParams.get("since");
  const until = searchParams.get("until");
  const only_successful_runs =
    searchParams.get("only_successful_runs") !== null;

  const { transcriptions, loading, error } = useTranscriptions({
    id,
    fixture_id,
    limit,
    language: language as "german" | "english" | "any",
    source,
    since: since ?? undefined,
    until: until ?? undefined,
    only_successful_runs,
  });

  if (loading) return <Page>Loading...</Page>;
  if (error) return <Page>Error: {error}</Page>;

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-4">
          <FilteredByFixtures size="default" />

          <Select
            value={language}
            onValueChange={(v) =>
              setSearchParams(
                { ...searchParams, language: v },
                { replace: true },
              )
            }
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filter by Language" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Languages</SelectLabel>
                <SelectItem value="any">Any Language</SelectItem>
                <SelectItem value="german">German</SelectItem>
                <SelectItem value="english">English</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
          <div className="flex items-center space-x-2">
            <Switch
              id="toggle-only-successful-runs"
              checked={only_successful_runs}
              onCheckedChange={(v) => {
                if (v) {
                  setSearchParams(
                    { ...searchParams, only_successful_runs: "" },
                    { replace: true },
                  );
                } else {
                  searchParams.delete("only_successful_runs");
                  setSearchParams(searchParams);
                }
              }}
            />
            <Label htmlFor="toggle-only-successful-runs">
              Only Successful Runs
            </Label>
          </div>
        </div>
        <QueryDateRangePicker />
      </div>
      <div className="mt-4">
        <TranscriptionList transcriptions={transcriptions} />
      </div>
    </Page>
  );
}

export default TranscriptionsPage;
export { TranscriptionList, TranscriptionCard };
