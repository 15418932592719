import {
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  useAuth,
} from "@clerk/clerk-react";
import { signInWithCustomToken } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../firebase";

function DoFirebaseAuth({ children }: { children: React.ReactNode }) {
  const [status, setStatus] = useState<"loading" | "succeeded" | "failed">(
    "loading",
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { isLoaded, orgId, getToken } = useAuth();

  useEffect(() => {
    const signInWithClerk = async () => {
      try {
        const token = await getToken({ template: "integration_firebase" });
        if (!token) {
          setStatus("failed");
          setErrorMessage("Failed to obtain authentication token.");
          return;
        }

        // console.log("token", token);

        // const userCredentials =
        await signInWithCustomToken(auth, token);
        // console.log("userCredentials", userCredentials);
        // console.log(
        //   "claims",
        //   await userCredentials.user?.getIdTokenResult(true),
        //   userCredentials.user?.toJSON(),
        // );

        setStatus("succeeded");
      } catch (error) {
        setStatus("failed");
        if (error instanceof Error) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage(
            "An unexpected error occurred during authentication.",
          );
        }
        console.error("Authentication error:", error);
      }
    };
    if (isLoaded && orgId) {
      // NOTE(liamvdv): orgId is set as soon as there is a active org id. We need that!
      signInWithClerk();
    } else {
      console.log("defer signin to firebase until organization is selected");
    }
  }, [isLoaded, getToken, orgId]);

  if (status === "loading") return <div></div>;
  if (status === "failed") {
    return (
      <div>
        <p>Authentication failed. Error: {errorMessage}</p>
        <p>
          Please try refreshing the page. If the problem persists, contact
          support at info@botbrains.io
        </p>
      </div>
    );
  }
  if (status === "succeeded") return children;
}

function ForceClerkAuth({ children }: { children: React.ReactNode }) {
  return (
    <>
      <SignedIn>{children}</SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
}
export default function ClerkFirebaseAuth({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ForceClerkAuth>
      <DoFirebaseAuth>{children}</DoFirebaseAuth>
    </ForceClerkAuth>
  );
}
