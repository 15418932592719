import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Feed,
  FeedEntry,
  Task,
  TranscriptionConfig,
  WriterConfig,
} from "./types";
import db from "./db";
import { eq, InferSelectModel } from "drizzle-orm";
import { fixtureInDatahub } from "./generated/schema";
import { useUser } from "@clerk/clerk-react";
import {
  endBefore,
  equalTo,
  limitToLast,
  onValue,
  orderByChild,
  query,
  QueryConstraint,
  ref,
  startAfter,
} from "firebase/database";
import { rdb } from "./firebase";
import { entrySorter, useBackendToken } from "./utils";

// type CompetitionSeasonMatchdayId = [string|undefined, string|undefined, string|undefined];
// interface FixtureFilterOptions  {
//   competition_season_matchdays: CompetitionSeasonMatchdayId[];
//   team_ids: string[];
//   date_range: [string, string]; // YYYY-MM-DD, inclusive, allows same day scope
// }

// interface FixtureFilter extends FixtureFilterOptions {
//   limit: number;
//   offset: number;
// }

// General Helpers
export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    setMatches(mediaQueryList.matches);

    mediaQueryList.addEventListener("change", listener);
    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, [query]);

  return matches;
}

export function useFixturesFilterOptions() {
  const { competitionSeasonMatchdays, teamIds, start_date, end_date } =
    useParams();
  const [searchParams] = useSearchParams();
  const limit = searchParams.get("limit") || 10;
  const offset = searchParams.get("offset") || 0;
  return {
    competitionSeasonMatchdays,
    teamIds,
    start_date,
    end_date,
    limit,
    offset,
  };
}

type SqlSelector<T> = () => Promise<T[]>;

// useDatahub is a hook that fetches data from the datahub using a selector. Use ready to control when the query is ready to be executed.
export function useDatahub<T>(
  selector: SqlSelector<T>,
  ready: boolean = true,
): [T[], boolean] {
  const [rows, setRows] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;
    const getRows = async () => {
      setIsLoading(true);
      try {
        const fetchedRows = await selector();
        if (!isCancelled) {
          setRows(fetchedRows);
          setIsLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          setIsLoading(false);
          console.error(error);
        }
      }
    };
    if (ready) getRows();
    return () => {
      isCancelled = true;
    };
  }, [ready, selector]);

  return [rows, isLoading];
}

interface UseFeeds {
  feeds: Feed[];
  isLoading: boolean;
  error: Error | null;
}

export const useFeeds = (
  orgId: string,
  searchParams: URLSearchParams,
): UseFeeds => {
  const [feeds, setFeeds] = useState<Feed[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const limitTo = Math.min(Number(searchParams.get("limit") || 25), 30);

  useEffect(() => {
    const feedsRef = ref(rdb, `organizations/${orgId}/feeds`);
    const parts = [orderByChild("created_at"), limitToLast(limitTo)];

    const paramToCondition: Record<string, (value: string) => QueryConstraint> =
      {
        fixtureId: (id: string) => equalTo(id, "fixture_id"),
        since: (date: string) => startAfter(new Date(date).toISOString()),
        until: (date: string) => endBefore(new Date(date).toISOString()),
      };

    const conditions = Array.from(searchParams.entries())
      .map(([key, value]) => paramToCondition[key]?.(value))
      .filter(Boolean);

    const q = query(feedsRef, ...parts, ...conditions);

    setIsLoading(true);
    const unsubscribe = onValue(
      q,
      (snapshot) => {
        const data: Record<string, Feed> = snapshot.val();
        if (data) {
          const feedList = Object.entries(data).map(([id, feed]) => ({
            id,
            ...feed,
          }));
          setFeeds(feedList);
          setIsLoading(false);
        } else {
          setFeeds([]);
          setIsLoading(false);
        }
      },
      (e) => {
        console.log(e), setError(e);
      },
    );
    return unsubscribe;
  }, [orgId, searchParams, limitTo]);

  return { feeds, isLoading, error };
};

export function useFixture(fixtureId: number | undefined) {
  const fixtureSelector = useCallback(
    () =>
      db
        .select()
        .from(fixtureInDatahub)
        .limit(1)
        .where(eq(fixtureInDatahub.id, fixtureId!)),
    [fixtureId],
  );

  const [fixtures, isLoading] = useDatahub<
    InferSelectModel<typeof fixtureInDatahub>
  >(fixtureSelector, fixtureId !== undefined);

  const [error, setError] = useState<Error | null>(null);
  const [fixture, setFixture] = useState<InferSelectModel<
    typeof fixtureInDatahub
  > | null>(null);

  useEffect(() => {
    if (!isLoading) {
      if (fixtures.length > 1) {
        setError(new Error(`Expected only one fixture for id ${fixtureId}`));
      } else if (fixtures.length === 0) {
        setError(new Error(`Fixture not found for id ${fixtureId}`));
      } else {
        setFixture(fixtures[0]);
        setError(null);
      }
    }
  }, [fixtures, isLoading, fixtureId]);

  return { fixture, isLoading, error };
}

interface UseTranscriptionsParams {
  id?: string;
  fixture_id?: number;
  limit?: number;
  language?: "german" | "english" | "any";
  source?: string;
  since?: string;
  until?: string;
  only_successful_runs?: boolean;
  isHookEnabled?: boolean;
}

export const useTranscriptions = ({
  id,
  fixture_id,
  limit = 50,
  language,
  source,
  since,
  until,
  only_successful_runs,
  isHookEnabled = true,
}: UseTranscriptionsParams) => {
  const [transcriptions, setTranscriptions] = useState<
    Task<TranscriptionConfig>[]
  >([]);
  const token = useBackendToken();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!isHookEnabled || !token) return;
      try {
        const params = new URLSearchParams();
        if (id) params.append("id", id);
        if (fixture_id) params.append("fixture_id", fixture_id.toString());
        if (limit) params.append("limit", limit.toString());
        if (language && language != "any") params.append("language", language);
        if (source) params.append("source", source);
        if (since) params.append("since", new Date(since).toISOString());
        if (until) params.append("until", new Date(until).toISOString());
        if (only_successful_runs) params.append("only_successful_runs", "true");

        const response = await fetch(
          `https://staging.api.liveticker.ai/v1/transcriptions?${params.toString()}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data: Task<TranscriptionConfig>[] = await response.json();
        setTranscriptions(data);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    isHookEnabled,
    id,
    fixture_id,
    limit,
    language,
    source,
    since,
    until,
    only_successful_runs,
    token,
  ]);

  return { transcriptions, loading, error };
};

interface UseWriterProps {
  id?: string;
  fixture_id?: number;
  limit?: number;
  language?:
    | "german"
    | "english"
    | "spanish"
    | "portuguese"
    | "italian"
    | "french"
    | "mandarin"
    | "any";
  source?: string;
  since?: string;
  until?: string;
  transcription_id?: string;
  transcription_uri?: string;
  feed_id?: string;
}

export const useWriters = ({
  id,
  fixture_id,
  limit = 50,
  language,
  since,
  until,
  transcription_id,
  transcription_uri,
  feed_id,
}: UseWriterProps) => {
  const token = useBackendToken();
  const [writers, setWriters] = useState<Task<WriterConfig>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return;
      try {
        const params = new URLSearchParams();
        if (id) params.append("id", id);
        if (fixture_id) params.append("fixture_id", fixture_id.toString());
        if (limit) params.append("limit", limit.toString());
        if (language && language != "any") params.append("language", language);
        if (transcription_id)
          params.append("transcription_id", transcription_id);
        if (transcription_uri)
          params.append("transcription_uri", transcription_uri);
        if (feed_id) params.append("feed_id", feed_id);
        if (since) params.append("since", new Date(since).toISOString());
        if (until) params.append("until", new Date(until).toISOString());

        const response = await fetch(
          `https://staging.api.liveticker.ai/v1/writers?${params.toString()}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data: Task<WriterConfig>[] = await response.json();
        setWriters(data);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    id,
    fixture_id,
    limit,
    language,
    transcription_id,
    transcription_uri,
    feed_id,
    since,
    until,
    token,
  ]);

  return { writers, loading, error };
};

export const useWriterAndTranscription = (feedId: string) => {
  if (!feedId) throw new Error("feedId is required");
  const { writers, loading: isWriterLoading } = useWriters({
    feed_id: feedId,
  });
  const writer: Task<WriterConfig> | undefined = writers[0];
  const { transcriptions, loading: isTranscriptionLoading } = useTranscriptions(
    {
      fixture_id: writer?.definition?.metadata?.fixture_id,
      isHookEnabled: !isWriterLoading,
    },
  );
  if (writers.length > 1) throw new Error("Expected at most one writer");
  if (writers.length < 1 && !isWriterLoading) {
    console.log("Expected exactly one writer - may be missing if testing run.");
  }
  const transcription = transcriptions.find(
    (t) => t.id === writer?.definition?.metadata.transcription_id,
  );
  return {
    writer: writer,
    transcription: transcription,
    isLoading: isWriterLoading || isTranscriptionLoading,
  };
};

export const useFeatureFlags = () => {
  const { user } = useUser();

  const isFeatureEnabled = useCallback(
    (feature: string) => {
      const baseFeatures: string[] = ["feed_sidebar", "feed_stream_player"];
      const adminFeatures = [
        ...baseFeatures,
        "feed_time_intervention",
        "feed_info_card",
        "transcriptions",
        "writers",
        // dev_tools are helpful for interal users, specifically developers
        "dev_tools",
        // internal_tools are helpful for internal users, developers and business users alike
        "internal_tools",
      ];
      const allFeatures = [...adminFeatures];
      if (!allFeatures.includes(feature)) {
        throw new Error(`Feature ${feature} is not recognized"`);
      }
      const isAdmin = user?.publicMetadata?.sys_admin == true;
      const appliedFeatures = isAdmin ? adminFeatures : baseFeatures;
      const decision = appliedFeatures.includes(feature);
      return decision;
    },
    [user],
  );
  return { isFeatureEnabled };
};

export const useFeedAndEntries = (orgId: string, feedId: string) => {
  const [feed, setFeed] = useState<Feed | null>(null);
  const [entries, setEntries] = useState<FeedEntry[]>([]);

  useEffect(() => {
    const feedRef = ref(rdb, `organizations/${orgId}/feeds/${feedId}`);

    const unsubscribe = onValue(
      feedRef,
      (snapshot) => {
        const newFeed = snapshot.val() as Feed;
        if (newFeed) {
          if (typeof newFeed.entries === "boolean")
            throw new Error(
              "Expected entries to be an array [non-shallow read]",
            );
          const entries = newFeed.entries
            ? Object.values(newFeed.entries).sort(entrySorter)
            : [];
          setFeed(newFeed);
          setEntries(entries);
        }
      },
      (e) => {
        console.log(e);
      },
    );
    return unsubscribe;
  }, [orgId, feedId]);

  return { feed, entries };
};

export const useSMContext = (fixtureId: number | undefined) => {
  const [context, setContext] = useState<any | null>(null);

  useEffect(() => {
    if (!fixtureId) return;

    const contextRef = ref(rdb, `provider/sm/live/fixture/${fixtureId}`);
    const unsubscribe = onValue(
      contextRef,
      (snapshot) => {
        const newContext = snapshot.val() as any;
        setContext(newContext);
      },
      (e) => {
        console.log(e);
      },
    );
    return unsubscribe;
  }, [fixtureId]);

  return context;
};

export const useSTSContext = (fixtureId: number | undefined) => {
  const [context, setContext] = useState<any | null>(null);
  useEffect(() => {
    if (!fixtureId) return;

    const contextRef = ref(rdb, `provider/sts/live/${fixtureId}`);
    const unsubscribe = onValue(
      contextRef,
      (snapshot) => {
        const newContext = snapshot.val() as any;
        setContext(newContext);
      },
      (e) => {
        console.log(e);
      },
    );
    return unsubscribe;
  }, [fixtureId]);

  return context;
};
