// View ticker.botbrains.io#src/ticker/structs.py for the Python source equivalent
import { parseISO } from "date-fns";
import { z } from "zod";

const EntryVisibilitySchema = z.enum(["draft", "visible", "hidden"]);

export type EntryVisibility = z.infer<typeof EntryVisibilitySchema>;

export const visibilityValues: EntryVisibility[] = [
  "draft",
  "visible",
  "hidden",
];

const FixtureSchema = z.object({
  id: z.number(),
});

export const periods: Period[] = [
  // DO NOT REORDER
  "BEFORE_MATCH",
  "1ST_HALF",
  "HALF_TIME",
  "2ND_HALF",
  "FULL_TIME",
  "BEFORE_EXTRA_TIME",
  "EXTRA_1ST_HALF",
  "EXTRA_HALF_TIME",
  "EXTRA_2ND_HALF",
  "EXTRA_FULL_TIME",
  "BEFORE_PENALTY_SHOOTOUT",
  "PENALTY_SHOOTOUT",
  "PENALTY_FULL_TIME",
];

const PeriodEnum = z.enum([
  "BEFORE_MATCH",
  "1ST_HALF",
  "HALF_TIME",
  "2ND_HALF",
  "FULL_TIME",
  "BEFORE_EXTRA_TIME",
  "EXTRA_1ST_HALF",
  "EXTRA_HALF_TIME",
  "EXTRA_2ND_HALF",
  "EXTRA_FULL_TIME",
  "BEFORE_PENALTY_SHOOTOUT",
  "PENALTY_SHOOTOUT",
  "PENALTY_FULL_TIME",
]);

type PeriodEnumType = z.infer<typeof PeriodEnum>;

export const periodChronologicalOrder: {
  [key in PeriodEnumType]: number;
} = {
  BEFORE_MATCH: 0,
  "1ST_HALF": 10,
  HALF_TIME: 20,
  "2ND_HALF": 30,
  FULL_TIME: 40,
  BEFORE_EXTRA_TIME: 40,
  EXTRA_1ST_HALF: 50,
  EXTRA_HALF_TIME: 60,
  EXTRA_2ND_HALF: 70,
  EXTRA_FULL_TIME: 80,
  BEFORE_PENALTY_SHOOTOUT: 80,
  PENALTY_SHOOTOUT: 90,
  PENALTY_FULL_TIME: 100,
};

export const periodsWithMinutes: Period[] = [
  "1ST_HALF",
  "2ND_HALF",
  "EXTRA_1ST_HALF",
  "EXTRA_2ND_HALF",
];

// Inferred type from the Zod enum
export type Period = z.infer<typeof PeriodEnum>;

const TimestampType = z
  .string()
  .transform((val) => {
    return val.replace("Z", "+00:00");
  })
  .refine(
    (val) => {
      const date = parseISO(val);
      return !isNaN(date.getTime());
    },
    {
      message: "Invalid Datetime",
    },
  );

export const GameStateMachineSchema = z.object({
  period: PeriodEnum,
  period_entered_map: z.object({
    BEFORE_MATCH: z.number().nullable().optional(),
    "1ST_HALF": z.number().nullable().optional(),
    HALF_TIME: z.number().nullable().optional(),
    "2ND_HALF": z.number().nullable().optional(),
    FULL_TIME: z.number().nullable().optional(),
    BEFORE_EXTRA_TIME: z.number().nullable().optional(),
    EXTRA_1ST_HALF: z.number().nullable().optional(),
    EXTRA_HALF_TIME: z.number().nullable().optional(),
    EXTRA_2ND_HALF: z.number().nullable().optional(),
    EXTRA_FULL_TIME: z.number().nullable().optional(),
    BEFORE_PENALTY_SHOOTOUT: z.number().nullable().optional(),
    PENALTY_SHOOTOUT: z.number().nullable().optional(),
    PENALTY_FULL_TIME: z.number().nullable().optional(),
  }),
  enable_extra_time: z.boolean(),
  enable_penalty_shootout: z.boolean(),
  enable_state_duration_constraint: z.boolean(),
});

export const InterventionSchema = z.object({
  id: z.string(),
  game_state_machine: GameStateMachineSchema,
});

const FeedSessionSchema = z.object({
  id: z.string(),
  feed_id: z.string(),
  context: z.record(z.unknown()),
  football_stream_state: GameStateMachineSchema,
  default_visibility: EntryVisibilitySchema,
  visibility_delay: z.number(),
  last_session_healthcheck: z.date(),
  created_at: z.date(),
  updated_at: z.date(),
});

export function splitGameMinute(input: string): [number, number] | null {
  const parts = input.split("+");
  if (parts.length > 2) {
    return null; // Invalid input: too many '+' symbols
  }
  if (parts.length === 0) {
    return null; // Invalid input: no parts
  }
  const minutes = parseInt(parts[0]);
  if (isNaN(minutes) || minutes < 0) {
    return null; // Invalid minutes
  }
  let additionalTime = 0;
  if (parts.length === 2) {
    additionalTime = parseInt(parts[1]);
    if (isNaN(additionalTime) || additionalTime < 0) {
      return null; // Invalid additional time
    }
  }
  return [minutes, additionalTime];
}

const LabelSchema = z.string().refine(
  (val) => {
    const parts = splitGameMinute(val);
    return parts !== null || val === "";
  },
  {
    message: "Game time must be in the format 'X' or 'X+Y'",
  },
);

const FeedGameTimeSchema = z
  .object({
    period: PeriodEnum, // editable
    period_order: z.number(),
    label: LabelSchema, // editable
    has_minute: z.boolean(),
    minute: z.number(), // derived
    stoppage_minute: z.number().refine((val) => 30 >= val && val >= 0, {
      message: "Stoppage minute must be between 0 and 30",
    }), // editable
    seconds: z.number(), // derived
    stoppage_seconds: z.number(), // derived
  })
  .refine(
    (data) => {
      // Minute range checks
      switch (data.period) {
        case "1ST_HALF":
          return data.minute >= 1 && data.minute <= 45;
        case "2ND_HALF":
          return data.minute >= 45 && data.minute <= 90;
        case "EXTRA_1ST_HALF":
          return data.minute >= 90 && data.minute <= 105;
        case "EXTRA_2ND_HALF":
          return data.minute >= 106 && data.minute <= 120;
        default:
          return data.minute == 0;
      }
    },
    {
      message: "Minute is out of valid range for the given period",
    },
  )
  .refine(
    (data) => {
      // Check that stoppage time only occurs at the end of valid periods
      const isStoppageTime =
        data.stoppage_minute > 0 || data.stoppage_seconds > 0;
      if (!isStoppageTime) return true; // No stoppage time, always valid

      switch (data.period) {
        case "1ST_HALF":
          return data.minute === 45;
        case "2ND_HALF":
          return data.minute === 90;
        case "EXTRA_1ST_HALF":
          return data.minute === 105;
        case "EXTRA_2ND_HALF":
          return data.minute === 120;
        default:
          return false; // Stoppage time not allowed in other periods
      }
    },
    {
      message: "Stoppage time can only occur at the end of valid periods",
    },
  )
  .refine(
    (data) => {
      // Check that stoppage time is less than 15 minutes
      return data.stoppage_minute < 15;
    },
    {
      message: "Stoppage time must be less than 15 minutes",
    },
  )
  .refine(
    (data) => {
      // Periods without minutes should have minute 0 and has_minute false
      if (periodsWithMinutes.includes(data.period)) {
        return data.minute > 0 && data.has_minute;
      } else {
        return data.minute === 0 && !data.has_minute;
      }
    },
    {
      message:
        "Periods without minutes should have minute 0 and has_minute false",
    },
  );

// FeedEntryAnnotation schema
const FeedEntryAnnotationSchema = z.object({
  type: z.enum(["player", "coach", "official", "event"]),
  uris: z.array(z.string()),
});

// Image schema
const ImageSchema = z.object({
  url: z.string().url(),
  caption: z.string(),
  height: z.number(),
  width: z.number(),
});

// Audio schema
const AudioSchema = z.object({
  url: z.string().url(),
  caption: z.string(),
  duration: z.number(),
});

// Video schema
const VideoSchema = z.object({
  url: z.string().url(),
  caption: z.string(),
  duration: z.number(),
  height: z.number(),
  width: z.number(),
});

const basicDetailSchema = z.object({});

const objectionSchema = z.object({
  is_dismissed: z.boolean(),
  detail_text: z.string(),
  source: z.string(),
  evaluation_type: z.enum(["MISC", "NAMES", "EVENT_INTEGRITY"]),
  detail: basicDetailSchema.nullable().optional(),
});

const humanDetailSchema = basicDetailSchema.extend({
  user_id: z.string(),
  org_id: z.string(),
  entry_snapshot: z.any(),
  created_at: TimestampType,
});

export const HumanObjectionSchema = objectionSchema.extend({
  source: z.enum(["HUMAN"]),
  evaluation_type: z.enum([
    "WRONG_SCORE",
    "WRONG_PLAYER",
    "MISSING_DETAILS",
    "OTHER",
  ]),
  detail: humanDetailSchema,
});

const evaluationSchema = z.object({
  objections: z.array(z.union([objectionSchema, HumanObjectionSchema])),
});

const FeedEntryMetadataSchema = z.object({
  langfuse_trace_url: z.string().optional(),
  stream_time: z.tuple([z.number(), z.number()]).optional(),
  // NOTE(memben): add more metadata fields as needed
});

// FeedEntry schema
export const FeedEntrySchema = z.object({
  id: z.string().optional(),
  feed_id: z.string(),
  sport: z.string(),
  type: z.enum(["commentary", "summary"]),
  text: z.string(), // editable
  order: z.number(), // potentially editable
  game_time: FeedGameTimeSchema, // partially editable
  created_at: TimestampType,
  updated_at: TimestampType,
  visible_at: z.preprocess((val) => {
    if (val === undefined) {
      return null;
    }
    if (val instanceof Date) {
      return val.toISOString();
    }
    return val;
  }, TimestampType.nullable()),
  visibility: EntryVisibilitySchema, // editable
  annotations: z.array(FeedEntryAnnotationSchema),
  metadata: FeedEntryMetadataSchema,
  evaluation: evaluationSchema,
  images: z.array(ImageSchema),
  audios: z.array(AudioSchema),
  videos: z.array(VideoSchema),
});

const FeedSchema = z.object({
  id: z.string().optional(),
  organization_id: z.string(),
  sport: z.string(),
  title: z.string(),
  language: z.string(),
  persona_id: z.string(),
  fixture_id: z.number(),
  fixture: FixtureSchema,
  created_at: TimestampType,
  updated_at: TimestampType,
  entries: z.record(z.string(), FeedEntrySchema),
});

// Types inferred from the schemas
export type Fixture = z.infer<typeof FixtureSchema>;
export type Feed = z.infer<typeof FeedSchema>;
export type FeedSession = z.infer<typeof FeedSessionSchema>;
export type GameStateMachine = z.infer<typeof GameStateMachineSchema>;
export type Intervention = z.infer<typeof InterventionSchema>;
export type FeedGameTime = z.infer<typeof FeedGameTimeSchema>;
export type HumanObjection = z.infer<typeof HumanObjectionSchema>;
export type FeedEntry = z.infer<typeof FeedEntrySchema>;
export type FeedEntryAnnotation = z.infer<typeof FeedEntryAnnotationSchema>;
export type Image = z.infer<typeof ImageSchema>;
export type Audio = z.infer<typeof AudioSchema>;
export type Video = z.infer<typeof VideoSchema>;

// ---

export interface CacheState {
  cache: Record<string, any>;
  loading: Record<string, boolean>;
  error: Record<string, string | null>;
}

export interface FetchDataArgs {
  query: string;
  resultType: "array" | "scalar";
}

export interface FetchDataPayload {
  result: any;
}

// ---

export interface TranscriptionConfig {
  s3_uri: string;
  dirpath: string;
  language: string;
  fixture_id: number;
  source: string;
  is_video?: boolean;
  backend?: string;
  preferred_team_id?: number;
}

interface LLmKwargs {
  model: string;
  temperature: number;
  presence_penalty: number;
  frequency_penalty: number;
}

export interface WriterConfig {
  s3_uri: string;
  feed_id: string;
  language: string;
  fixture_id: number;
  llm_kwargs: LLmKwargs;
  persona_id: string;
  transcription_id: string;
  preferred_team_id: number;
}

export interface TaskDefinition<T> {
  task: "transcribe" | "write";
  command: string;
  metadata: T;
}

export interface Task<T> {
  id: string;
  dedup_id: string;
  after: string;
  latest_run_id: string;
  definition: TaskDefinition<T>;
  status: string;
}
