export default function ApplicationError({ errorMessage = "" }) {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-emerald-600">Critical</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          An error occurred
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, an unexpected client side error occurred. Please retry. <br />{" "}
          Please reach out to{" "}
          <a className="font-bold underline" href="mailto:liam@botbrains.io">
            liam@botbrains.io
          </a>{" "}
          if the error persists.
        </p>
        {errorMessage && (
          <p className="mt-4 rounded-md bg-red-100 p-3 text-sm text-red-600">
            Error details: {errorMessage}
          </p>
        )}
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="#"
            onClick={() => window.location.reload()}
            className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Refresh
          </a>
          <a
            href="mailto:info@botbrains.io"
            className="text-sm font-semibold text-gray-900"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}
