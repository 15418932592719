import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  useFixture,
  useFeeds,
  useTranscriptions,
  useFeatureFlags,
  useWriters,
} from "@/hooks";

import NaiveJsonDisplay from "./JsonDisplay";
import { BreadcrumbNavigation } from "./BreadcrumbNav";
import Page from "./Page";
import Spinner from "./Loading";
import { FeedList } from "./FeedsPage";
import FixtureInfoCard from "./FixtureInfoCard";
import { TranscriptionList } from "./TranscriptionsPage";
import { WriterList } from "./WritersPage";

function Transcriptions() {
  const { fixtureId } = useParams();
  const { transcriptions, loading, error } = useTranscriptions({
    fixture_id: parseInt(fixtureId!),
  });
  if (loading) return <Spinner />;
  if (error) return <div>Error: {error}</div>;
  return <TranscriptionList transcriptions={transcriptions} />;
}

function Writers() {
  const { fixtureId } = useParams();
  const { writers, loading, error } = useWriters({
    fixture_id: parseInt(fixtureId!),
  });
  if (loading) return <Spinner />;
  if (error) return <div>Error: {error}</div>;
  return <WriterList writers={writers} />;
}

function FixturePage() {
  const { isFeatureEnabled } = useFeatureFlags();
  const { orgId, fixtureId } = useParams();
  const params = useMemo(
    () => new URLSearchParams({ fixtureId: fixtureId! }),
    [fixtureId],
  );
  const fixtureData = useFixture(Number(fixtureId));
  const { feeds, isLoading: isFeedsLoading } = useFeeds(orgId!, params);

  return (
    <Page>
      <header>
        <div className="mb-4">
          <BreadcrumbNavigation
            items={[
              { label: "Fixtures", href: `./..` },
              {
                label: fixtureData.fixture?.name || `Fixture ${fixtureId}`,
                href: `.`,
              },
            ]}
          />
        </div>
        <FixtureInfoCard {...fixtureData} />
      </header>
      <div className="mt-4">
        <h2 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          Feeds
        </h2>
        <div className="mt-4">
          {isFeedsLoading ? <Spinner /> : <FeedList feeds={feeds} />}
        </div>
      </div>
      {isFeatureEnabled("transcriptions") && (
        <div className="mt-4">
          <h2 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
            Transcriptions
          </h2>
          <div className="mt-2">
            <Transcriptions />
          </div>
        </div>
      )}
      {isFeatureEnabled("writers") && (
        <div className="mt-4">
          <h2 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
            Writers
          </h2>
          <div className="mt-2">
            <Writers />
          </div>
        </div>
      )}
      <NaiveJsonDisplay jsonData={fixtureData.fixture} />
    </Page>
  );
}

export default FixturePage;
