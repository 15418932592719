import { useClerk } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RedirectToOrg() {
  const { user } = useClerk();
  const navigate = useNavigate();

  useEffect(() => {
    async function redirectToOrg() {
      if (user) {
        const orgId = user.organizationMemberships[0]?.organization.id;

        if (orgId) {
          navigate(`/${orgId}/feeds`);
        } else {
          console.error("User is not part of an organization");
          navigate("/no-org-found");
        }
      }
    }

    redirectToOrg();
  }, [user, navigate]);

  return null;
}
