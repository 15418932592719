import Page from "./Page";
import { Button } from "./ui/button";

function UsagePage() {
  return (
    <Page>
      <h2 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
        Usage
      </h2>
      <div className="prose mt-2">
        <p>
          We are working to show you realtime charts of your current usage.{" "}
          <br />
          In the meantime, please request your usage from our support team.
        </p>
        <Button
          variant="outline"
          onClick={() => {
            window.$crisp.push([
              "set",
              "message:text",
              ["Hey, what is my usage this billing period?"],
            ]);
            window.$crisp.push(["do", "chat:open"]);
          }}
        >
          Request Usage
        </Button>
      </div>
    </Page>
  );
}

export default UsagePage;
