import { useEffect, useState } from "react";
import { rdb } from "../firebase";
import { Form, FormLabel, FormMessage } from "./ui/form";
import { format } from "date-fns";
import {
  FeedEntry,
  FeedEntrySchema,
  HumanObjection,
  periodChronologicalOrder,
  periodsWithMinutes,
  splitGameMinute,
} from "../types";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import EllipsisVerticalIcon from "@heroicons/react/24/solid/EllipsisVerticalIcon";
import { Button } from "./ui/button";
import EyeSlashIcon from "@heroicons/react/24/solid/EyeSlashIcon";
import EyeIcon from "@heroicons/react/24/solid/EyeIcon";
import { useForm } from "react-hook-form";
import { FormControl, FormField, FormItem } from "./ui/form";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { Calendar } from "./ui/calendar";
import { TimePicker } from "./ui/time-picker";
import { cn } from "@/lib/utils";
import { useParams } from "react-router-dom";
import { useFeatureFlags } from "@/hooks";
import { push, ref, remove, set, update } from "firebase/database";
import { v7 as uuidv7 } from "uuid";
import { Card } from "./ui/card";
import { arrify, periodTranslations } from "@/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import HumanEvaluationForm from "./HumanEvaluationForm";

const evaluationTypeMap = {
  WRONG_SCORE: "Wrong Score",
  WRONG_PLAYER: "Wrong Player",
  MISSING_DETAILS: "Missing Details",
  OTHER: "Other",
  MISC: "Misc",
  NAMES: "Names",
  EVENT_INTEGRITY: "Event Integrity",
};

// NOTE(memben): add you class for tailwind compiler
// border-l-green-700 text-green-700 border-l-red-600 text-red-600 border-l-yellow-500 text-yellow-500
const getVisibilityColor = (prefix: string, entry: FeedEntry): string => {
  switch (entry.visibility) {
    case "visible":
      return `${prefix}-green-700`;
    case "hidden":
      return `${prefix}-red-600`;
    case "draft":
      return `${prefix}-yellow-500`;
  }
};
const getVisibilityText = (current: Date | null, entry: FeedEntry) => {
  switch (entry.visibility) {
    case "visible":
      return "Visible";
    case "hidden":
      return "Hidden";
    case "draft":
      return entry.visible_at && current
        ? `Publishing in ${Math.round(Math.max(0, new Date(entry.visible_at).getTime() - current.getTime()) / 1000)}s`
        : "Draft";
  }
};

export function FeedEntryCard({
  entry,
  showObjections,
  setHighlightedEntry,
}: {
  entry: FeedEntry;
  showObjections: boolean;
  setHighlightedEntry: (entry: FeedEntry) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isShowingObjections, setIsShowingObjections] =
    useState<boolean>(false);
  const [isShowingDismissedObjections, setIsShowingDismissedObjections] =
    useState<boolean>(false);
  const [current, setCurrent] = useState<Date>(new Date());
  const { orgId, feedId } = useParams();
  const { isFeatureEnabled } = useFeatureFlags();
  const entryPath = `organizations/${orgId}/feeds/${feedId}/entries/${entry.id}`;
  const entryRef = ref(rdb, entryPath);

  const objections = arrify(entry.evaluation?.objections);

  useEffect(() => {
    if (entry.visibility !== "draft") return;
    const interval = setInterval(() => setCurrent(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [entry.visibility]);

  useEffect(() => {
    setIsShowingObjections(showObjections);
    setIsShowingDismissedObjections(showObjections);
  }, [showObjections]);

  // NOTE(memben): to handle dismissing all objections
  useEffect(() => {
    if (
      objections.filter((objection) => !objection.object.is_dismissed).length ==
        0 &&
      !isShowingDismissedObjections
    ) {
      // NOTE(memben): keep objections open when global state is true
      setIsShowingObjections(showObjections);
    }
  }, [isShowingDismissedObjections, objections]);

  const href = new URL(window.location.href);
  href.hash = `entry_${entry.id}`;

  if (isEditing) {
    return (
      <EditableFeedEntryCard entry={entry} close={() => setIsEditing(false)} />
    );
  }

  const toggleVisibility = async () => {
    await set(
      ref(rdb, `${entryPath}/visibility`),
      entry.visibility === "hidden" ? "visible" : "hidden",
    );
  };

  const invertObjection = async (index: number) => {
    const objection = objections[index];

    await set(
      ref(
        rdb,
        `${entryPath}/evaluation/objections/${objection.key}/is_dismissed`,
      ),
      !objection.object.is_dismissed,
    );
  };

  const pushObjection = async (objection: HumanObjection) => {
    const objectionsRef = ref(rdb, `${entryPath}/evaluation/objections`);
    await push(objectionsRef, objection);
  };

  const deleteEntry = async () => {
    await remove(entryRef);
  };

  return (
    <div
      id={`entry_${entry.id}`}
      className={`group rounded-[8px] ${isShowingObjections ? " bg-gray-100" : "bg-red-600"}
      relative before:pointer-events-none before:absolute before:inset-[0px] before:rounded-[10px] before:border-2 before:border-red-600`}
    >
      <Card
        className={`relative z-10 border-l-8 p-2 ${getVisibilityColor("border-l", entry)}   ${location.hash == `#entry_${entry.id}` && "bg-sky-50 ring ring-blue-400"} flex `}
      >
        <Dialog>
          <div className="flex items-center">
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6"
              onClick={toggleVisibility}
            >
              {entry.visibility == "hidden" ? (
                <EyeSlashIcon
                  title="Hidden"
                  className={`h-6 w-6 ${getVisibilityColor("text", entry)}`}
                />
              ) : (
                <DialogTrigger>
                  <EyeIcon
                    title="Visible"
                    className={`h-6 w-6 ${getVisibilityColor("text", entry)}`}
                  />
                </DialogTrigger>
              )}
            </Button>
          </div>
          <div className="flex w-full flex-col pl-4">
            <header className="flex w-full justify-between">
              <div>
                <p className={`${getVisibilityColor("text", entry)}`}>
                  {getVisibilityText(current, entry)}
                </p>
                <p>
                  {entry.game_time.label} (
                  {periodTranslations[entry.game_time.period]})
                </p>
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <EllipsisVerticalIcon className="h-7 w-7 "></EllipsisVerticalIcon>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuLabel>Feed Entry</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() =>
                      navigator.clipboard.writeText(href.toString())
                    }
                  >
                    Copy Link
                  </DropdownMenuItem>
                  {/* Need to inverse order here, else the Dialog will not work. */}
                  <DialogTrigger className="w-full">
                    <DropdownMenuItem>Evaluate</DropdownMenuItem>
                  </DialogTrigger>
                  {objections.length > 0 && (
                    <DropdownMenuItem
                      onClick={() => {
                        setIsShowingDismissedObjections(true);
                        setIsShowingObjections(true);
                      }}
                    >
                      Show All Objections
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem onClick={() => setIsEditing(true)}>
                    Edit
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      if (
                        window.confirm(
                          "Deleting is inreversible, do you want to continue?",
                        )
                      ) {
                        deleteEntry();
                      }
                    }}
                  >
                    Delete
                  </DropdownMenuItem>

                  {isFeatureEnabled("feed_stream_player") && (
                    <DropdownMenuItem
                      onClick={() => {
                        setHighlightedEntry(entry);
                      }}
                    >
                      Jump to Video
                    </DropdownMenuItem>
                  )}
                  {isFeatureEnabled("internal_tools") &&
                    entry.metadata.langfuse_trace_url !== "" && (
                      <DropdownMenuItem>
                        <a
                          href={entry.metadata.langfuse_trace_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Langfuse Trace
                        </a>
                      </DropdownMenuItem>
                    )}
                  {isFeatureEnabled("dev_tools") && (
                    <DropdownMenuItem
                      onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(entry));
                        console.log(entry);
                      }}
                    >
                      View/Copy JSON
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </header>
            <main>
              <h3 className="text-sm font-semibold sm:text-lg">{entry.text}</h3>
            </main>
          </div>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Evaluate Entry</DialogTitle>
              <DialogDescription>
                Provide us with your feedback on this entry
              </DialogDescription>
              <HumanEvaluationForm
                entry={entry}
                submitObjection={pushObjection}
              />
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </Card>
      {objections.length > 0 &&
        (objections.filter((objection) => !objection.object.is_dismissed)
          .length > 0 ||
          isShowingDismissedObjections) && (
          <div className="text-red-black rounded-full p-2 py-1 text-gray-700">
            {isShowingObjections ? (
              <div className="rounded-full  py-1 text-sm">
                <ul className="space-y-2">
                  {objections.map(
                    (objection, index) =>
                      (!objection.object.is_dismissed ||
                        isShowingDismissedObjections) && (
                        <li key={index} className="flex space-x-3 text-sm">
                          <button
                            onClick={() => invertObjection(index)}
                            className="text-red-600"
                          >
                            {objection.object.is_dismissed ? "Undo" : "Dismiss"}
                          </button>
                          <p
                            className={`${objection.object.is_dismissed && `line-through`}`}
                          >
                            [
                            {evaluationTypeMap[
                              objection.object.evaluation_type
                            ] || "Unknown"}
                            ] {objection.object.detail_text}
                          </p>
                        </li>
                      ),
                  )}
                </ul>
                <button
                  // NOTE(memben): we don't undo the show all objections state
                  onClick={() => setIsShowingObjections(!isShowingObjections)}
                  className="mt-2 w-full text-center text-sm font-semibold text-red-600 sm:text-base"
                >
                  Hide Objections
                </button>
              </div>
            ) : (
              <button
                onClick={() => setIsShowingObjections(!isShowingObjections)}
                className="w-full text-center text-sm font-semibold text-white sm:text-base"
              >
                Show Objections
              </button>
            )}
          </div>
        )}
    </div>
  );
}

export function EditableFeedEntryCard({
  entry,
  close,
}: {
  entry: FeedEntry;
  close: () => void;
}) {
  // NOTE(memben) use console.log(form.formState.errors) to debug for not shown errors on save
  const form = useForm<FeedEntry>({
    resolver: zodResolver(FeedEntrySchema),
    defaultValues: {
      ...entry,
      images: entry.images ?? [],
      audios: entry.audios ?? [],
      videos: entry.videos ?? [],
      evaluation: entry.evaluation ?? {
        objections: [],
      },
      annotations: entry.annotations ?? [],
    },
  });

  const { orgId, feedId } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isAdvancedEditing, setIsAdvancedEditing] = useState(false);

  const watchGameTimeLabel = form.watch("game_time.label");
  const watchGameTimePeriod = form.watch("game_time.period");
  const configurableGameTime = periodsWithMinutes.includes(watchGameTimePeriod);
  const gameTimeRootError = form.formState.errors?.game_time?.root?.message;

  const undraft = async () => {
    const entryRef = ref(
      rdb,
      `/organizations/${orgId}/feeds/${feedId}/entries/${entry.id}`,
    );
    if (entry.visibility !== "draft") return;
    await update(entryRef, {
      visibility: "hidden",
    });
  };

  useEffect(() => {
    undraft();
  }, []);

  useEffect(() => {
    if (watchGameTimeLabel) {
      const parts = splitGameMinute(watchGameTimeLabel);
      if (parts) {
        const [minute, stoppage_minute] = parts;
        form.setValue("game_time.minute", minute);
        form.setValue("game_time.stoppage_minute", stoppage_minute);
        form.setValue("game_time.seconds", minute * 60 - 60); // 1 minute in game has the start of 0 seconds
        form.setValue("game_time.stoppage_seconds", stoppage_minute * 60 - 60);
      }
    }
  }, [watchGameTimeLabel, form]);

  useEffect(() => {
    form.setValue(
      "game_time.period_order",
      periodChronologicalOrder[watchGameTimePeriod],
    );
    if (!configurableGameTime) {
      form.setValue("game_time.has_minute", false);
      form.setValue("game_time.label", "");
      form.setValue("game_time.minute", 0);
      form.setValue("game_time.stoppage_minute", 0);
      form.setValue("game_time.seconds", 0);
      form.setValue("game_time.stoppage_seconds", 0);
    } else {
      form.setValue("game_time.has_minute", true);
    }
  }, [watchGameTimePeriod, form]);

  const upsertSave = async (changedEntry: FeedEntry) => {
    setIsSaving(true);
    try {
      console.log("create/update", changedEntry);
      if (changedEntry.id) {
        await update(
          ref(
            rdb,
            `/organizations/${orgId}/feeds/${feedId}/entries/${changedEntry.id}`,
          ),
          changedEntry,
        );
      } else {
        const id = uuidv7();
        await set(
          ref(rdb, `/organizations/${orgId}/feeds/${feedId}/entries/${id}`),
          {
            ...changedEntry,
            id,
          },
        );
      }
      close();
    } catch (error) {
      console.error("Error saving entry:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card
      className={`border-l-8 p-2 ${getVisibilityColor("border-l", entry)} flex`}
    >
      <div className="flex items-center">
        <Button
          variant="ghost"
          size="icon"
          className="h-6 w-6"
          onClick={() => {}} // NOTE(memben): we don't allow toggling visibility in edit mode
        >
          {entry.visibility == "hidden" ? (
            <EyeSlashIcon
              title="Hidden"
              className={`h-6 w-6 ${getVisibilityColor("text", entry)}`}
            />
          ) : (
            <EyeIcon
              title="Visible"
              className={`h-6 w-6 ${getVisibilityColor("text", entry)}`}
            />
          )}
        </Button>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(upsertSave)}
          className="flex w-full flex-col pl-4"
        >
          <div className="flex w-full flex-col">
            <header className="flex w-full justify-between">
              <div>
                <p className={`${getVisibilityColor("text", entry)}`}>
                  {getVisibilityText(null, entry)}
                </p>
                <div>
                  <div className="flex gap-2">
                    {" "}
                    <FormField
                      control={form.control}
                      name="game_time.label"
                      render={({ field }) => (
                        <FormItem>
                          <Input
                            placeholder={
                              configurableGameTime
                                ? "Game Time (e.g. 45+2)"
                                : "No Game Time"
                            }
                            disabled={!configurableGameTime}
                            {...field}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="game_time.period"
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select the period" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {Object.entries(periodTranslations).map(
                                ([key, value]) => (
                                  <SelectItem key={key} value={key}>
                                    {value}
                                  </SelectItem>
                                ),
                              )}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  {gameTimeRootError && (
                    <FormMessage>{gameTimeRootError}</FormMessage>
                  )}
                </div>
              </div>
            </header>
            <main className="mt-2">
              <FormField
                control={form.control}
                name="text"
                render={({ field }) => (
                  <FormItem>
                    {/* <FormLabel></FormLabel> */}
                    <Textarea
                      placeholder="Engaging commentary for the feed"
                      className="text-sm font-semibold sm:text-lg"
                      {...field}
                    />
                    {/* <FormDescription>Enter a meaningful name.</FormDescription> */}
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isAdvancedEditing && (
                <div className="mt-2">
                  <FormField
                    control={form.control}
                    name="visible_at"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-left">DateTime</FormLabel>
                        <Popover>
                          <FormControl>
                            <PopoverTrigger asChild>
                              <Button
                                variant="outline"
                                className={cn(
                                  "w-[280px] justify-start text-left font-normal",
                                  !field.value && "text-muted-foreground",
                                )}
                              >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {field.value ? (
                                  format(field.value, "PPP HH:mm:ss")
                                ) : (
                                  <span>Pick a date</span>
                                )}
                              </Button>
                            </PopoverTrigger>
                          </FormControl>
                          <PopoverContent className="w-auto p-0">
                            <Calendar
                              mode="single"
                              selected={
                                field.value ? new Date(field.value) : undefined
                              }
                              onSelect={field.onChange}
                              initialFocus
                            />
                            <div className="border-t border-border p-3">
                              <TimePicker
                                setDate={field.onChange}
                                date={
                                  field.value
                                    ? new Date(field.value)
                                    : undefined
                                }
                              />
                            </div>
                          </PopoverContent>
                        </Popover>
                      </FormItem>
                    )}
                  />
                </div>
              )}

              <div className="mt-4 flex justify-between">
                <Button
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsAdvancedEditing(!isAdvancedEditing);
                  }}
                >
                  Advanced
                </Button>
                <div className="flex gap-4">
                  <Button
                    variant="outline"
                    onClick={(e) => {
                      e.preventDefault();
                      close();
                    }}
                    type="button"
                    disabled={isSaving}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSaving}>
                    {isSaving ? "Saving..." : "Save"}
                  </Button>
                </div>
              </div>
              <FormMessage />
            </main>
          </div>
        </form>
      </Form>
    </Card>
  );
}
