import { FeedEntry, HumanObjection, HumanObjectionSchema } from "@/types";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "./ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { useUser } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";
import { Textarea } from "./ui/textarea";
import { Button } from "./ui/button";
import { DialogClose } from "./ui/dialog";

interface HumanEvaluationFormProps {
  entry: FeedEntry;
  submitObjection: (objection: HumanObjection) => void;
}

export default function HumanEvaluationForm({
  entry,
  submitObjection,
}: HumanEvaluationFormProps) {
  const { orgId } = useParams();
  const { user } = useUser();

  if (!user) {
    throw new Error("User not found");
  }

  if (orgId === undefined) {
    throw new Error("Organization ID not found");
  }

  const form = useForm<HumanObjection>({
    resolver: zodResolver(HumanObjectionSchema),
    defaultValues: {
      is_dismissed: false,
      detail_text: "",
      source: "HUMAN",
      evaluation_type: "OTHER",
      detail: {
        user_id: user?.id,
        org_id: orgId,
        entry_snapshot: structuredClone(entry),
        created_at: new Date().toISOString(),
      },
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submitObjection)}>
        <div className="mt-4 space-y-4">
          <FormField
            control={form.control}
            name="evaluation_type"
            render={({ field }) => (
              <FormItem>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select the Failure Case" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="WRONG_SCORE">Wrong Score</SelectItem>
                    <SelectItem value="WRONG_PLAYER">Wrong Player</SelectItem>
                    <SelectItem value="MISSING_DETAILS">
                      Missing Details
                    </SelectItem>
                    <SelectItem value="OTHER">Other</SelectItem>
                  </SelectContent>
                </Select>
                <FormDescription>
                  Please select the type of failure you are reporting.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="detail_text"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Textarea
                    {...field}
                    placeholder="Describe the failure in detail"
                  />
                </FormControl>
                <FormDescription>
                  Please provide a detailed description of the failure.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <DialogClose asChild>
            <Button type="submit" className="w-full">
              Submit
            </Button>
          </DialogClose>
        </div>
      </form>
    </Form>
  );
}
