import db from "@/db";
import { fixtureInDatahub } from "@/generated/schema";
import { useCallback } from "react";
import {
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { eq } from "drizzle-orm";
import { useDatahub } from "@/hooks";
import { Button } from "./ui/button";
import { XCircleIcon } from "@heroicons/react/24/outline";

function FilteredByFixtures({
  size,
}: {
  size: "sm" | "default" | "lg" | "icon";
}) {
  const { orgId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const fixtureId = Number(searchParams.get("fixture_id")) || null;

  const fixtureSelector = useCallback(
    () =>
      db
        .select()
        .from(fixtureInDatahub)
        .limit(1)
        .where(eq(fixtureInDatahub.id, fixtureId!)),
    [fixtureId],
  );
  const [fixtures] = useDatahub(fixtureSelector, fixtureId != null);
  const fixture = fixtures[0];
  return fixtureId === null ? (
    <div>
      <Link to={`/${orgId}/fixtures`}>
        <Button variant="outline" size={size}>
          Select Fixture
        </Button>
      </Link>
    </div>
  ) : (
    <div className="flex space-x-0">
      <Link to={`/${orgId}/fixtures`}>
        <Button variant="outline" size={size}>
          Change {fixture?.sm_home_team_name} vs {fixture?.sm_away_team_name}
        </Button>
      </Link>
      <Button
        variant={"ghost"}
        size={size}
        onClick={() => {
          searchParams.delete("fixture_id");
          setSearchParams(searchParams);
        }}
      >
        <XCircleIcon className="h-6 w-6" />
      </Button>
    </div>
  );
}

export default FilteredByFixtures;
