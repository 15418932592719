import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import React from "react";
import { Link } from "react-router-dom";

type ToUrl = (v: any) => string;
type Formatter = (v: any) => string;

interface CardHeaderEntry {
  label: string;
  attr: string;
  href?: ToUrl;
  detail?: BaseCardHeaderProps;
  formatter?: Formatter;
}

interface BaseCardHeaderProps {
  title: string;
  fields: CardHeaderEntry[][];
}

interface CardHeaderProps extends BaseCardHeaderProps {
  data: Record<string, any>;
  variant: "card" | "inline";
  children?: React.ReactNode;
}

interface CardHeaderEntryProps extends CardHeaderEntry {
  data: Record<string, any>;
}

function defaultFormatter(v: any): string {
  switch (typeof v) {
    case "boolean":
      return v ? "Yes" : "No";
    case "undefined":
      return "-";
    case "object":
      if (v === null) return "-";
      else return JSON.stringify(v);
    case "string":
      return v;
    default:
      return v.toString();
  }
}

function CardHeaderEntry({
  label,
  attr,
  href,
  detail,
  data,
  formatter,
}: CardHeaderEntryProps) {
  if (typeof formatter === "undefined") formatter = defaultFormatter;

  if (typeof formatter !== "function")
    throw new Error("formatter must be a function");

  // NOTE(liamvdv): value needs to be React component [for hover shadcn)! Thus <span>.
  if (typeof href === "undefined")
    href =
      typeof data[attr] === "string" && data[attr].startsWith("http")
        ? (v: any) => v
        : href;
  const hrefValue = href ? href(data[attr]) : null;
  const isExternal =
    !!hrefValue &&
    new URL(hrefValue).origin !== new URL(window.location.href).origin;

  const className = "break-words";
  const value = hrefValue ? (
    isExternal ? (
      <a
        href={hrefValue}
        target="_blank"
        rel="noreferrer"
        className={className}
      >
        {formatter(data[attr])}
      </a>
    ) : (
      <Link to={hrefValue} className={className}>
        {formatter(data[attr])}
      </Link>
    )
  ) : (
    <span className={className}>{formatter(data[attr])}</span>
  );
  return (
    <>
      <div className="text-sm text-gray-500">
        <span>{label}</span>
      </div>
      <div className="text-sm font-medium">
        {Array.isArray(data[attr]) ? (
          (data[attr] as any[]).map((val, idx) => (
            <div key={idx} className="">
              {val}
            </div>
          ))
        ) : detail ? (
          <HoverCard>
            <HoverCardTrigger asChild>{value}</HoverCardTrigger>
            <HoverCardContent className="w-80">
              <ObjectCardHeader
                variant="inline"
                title={detail.title}
                fields={detail.fields}
                data={data}
              />
            </HoverCardContent>
          </HoverCard>
        ) : (
          value
        )}
      </div>
    </>
  );
}

function ObjectCardHeader({
  title,
  fields,
  data,
  children,
  variant = "card",
}: CardHeaderProps) {
  const cols = fields.length;

  const varianClasses = {
    card: "w-full rounded-lg border border-gray-200 bg-white p-4 shadow-lg",
    inline: "",
  };

  return (
    <div className={varianClasses[variant]}>
      <div className="flex items-center justify-between border-b border-gray-200 pb-4">
        <h2 className="text-xl font-semibold">{title}</h2>
        <div>{children}</div>
      </div>
      {/* NOTE(memben): for tailwind grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 */}
      <div className={`grid-cols-${cols} grid gap-4 py-4`}>
        {fields.map((column, colIndex) => (
          <div key={colIndex} className="flex flex-col space-y-4">
            {column.map((field, rowIndex) => (
              <div key={rowIndex}>
                <CardHeaderEntry {...field} data={data} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ObjectCardHeader;
