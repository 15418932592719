// TODO(liamvdv): make this an endpoint that automatically selects the correct context & values centrally.

interface MinimalGoalEvent {
  teamRole: "home" | "away";
  playerLastName: string;
  minuteOfPlay: number;
  result: string;
}

interface GoalScorerData {
  homeTeam: string;
  awayTeam: string;
  result: string;
  goalEvents: MinimalGoalEvent[];
}

const getGoalScorerFromStsContext = (stsContext: any): GoalScorerData => {
  const { events } = stsContext;

  const goalEvents = events.GameSection.flatMap(
    (section: any) =>
      section.ShotAtGoals?.filter(
        (shot: any) => shot.ShotResult === "SuccessfulShot",
      ) || [],
  )
    .sort(
      (a: any, b: any) =>
        new Date(a.EventTime).getTime() - new Date(b.EventTime).getTime(),
    )
    .map(
      (goal: any): MinimalGoalEvent => ({
        teamRole: goal.TeamRole,
        playerLastName: goal.PlayerLastName,
        minuteOfPlay: goal.MinuteOfPlay,
        result: goal.Result,
      }),
    );

  return {
    homeTeam: events.HomeTeamName,
    awayTeam: events.GuestTeamName,
    result: events.Result,
    goalEvents,
  };
};

function getGoalScorerFromSmContext(smContext: any): GoalScorerData {
  const { events, scores, participants } = smContext;

  const home = participants.find((team: any) => team.meta.location === "home");
  const away = participants.find((team: any) => team.meta.location === "away");

  const goalEvents = events
    .filter((event: any) => event.type.name === "Goal")
    .sort((a: any, b: any) => a.minute - b.minute)
    .map(
      (goal: any): MinimalGoalEvent => ({
        teamRole: goal.participant_id == home.id ? "home" : "away",
        playerLastName: goal.player.lastname,
        minuteOfPlay: goal.minute,
        result: goal.result,
      }),
    );

  const homeScore =
    scores.find(
      (score: any) =>
        score.description === "CURRENT" && score.score.participant === "home",
    )?.score.goals || 0;
  const awayScore =
    scores.find(
      (score: any) =>
        score.description === "CURRENT" && score.score.participant === "away",
    )?.score.goals || 0;

  const homeTeam = home?.name || "";
  const awayTeam = away?.name || "";

  return {
    homeTeam,
    awayTeam,
    result: `${homeScore}-${awayScore}`,
    goalEvents,
  };
}

const GoalScorersDisplay = ({
  stsContext,
  smContext,
}: {
  stsContext: any;
  smContext: any;
}) => {
  let fn, ctx;
  if (stsContext) {
    fn = getGoalScorerFromStsContext;
    ctx = stsContext;
  } else if (smContext) {
    fn = getGoalScorerFromSmContext;
    ctx = smContext;
  } else return;

  const { homeTeam, awayTeam, result, goalEvents } = fn(ctx);

  return (
    <div className="mx-auto mt-4 w-full max-w-md rounded-lg bg-gray-100 p-4">
      <div className="mb-2 flex items-center justify-between font-bold">
        <span className="w-4/12 text-left">{homeTeam}</span>
        <span className="w-4/12 text-center">{result}</span>
        <span className="w-4/12 text-right">{awayTeam}</span>
      </div>
      {goalEvents.map((goal, index) => (
        <div key={index} className="flex items-center justify-between text-sm">
          {goal.teamRole === "home" ? (
            <>
              <span className="w-3/12 text-right">{`${goal.playerLastName} `}</span>
              <span className="w-2/12 text-center">{`${goal.minuteOfPlay}'`}</span>
              <span className="w-2/12 text-center">{goal.result}</span>
              <span className="w-5/12"></span>
            </>
          ) : (
            <>
              <span className="w-5/12"></span>
              <span className="w-2/12 text-center">{goal.result}</span>
              <span className="w-2/12 text-center">{`${goal.minuteOfPlay}'`}</span>
              <span className="w-3/12 text-left">{`${goal.playerLastName} `}</span>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default GoalScorersDisplay;
