// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// NOTE(memben): visit cloud console under: https://console.firebase.google.com/

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXxYzjiwMx8w6AZY_WFB7CfwxnI7a2c7U",
  authDomain: "botbrains-gate.firebaseapp.com",
  projectId: "botbrains-gate",
  storageBucket: "botbrains-gate.appspot.com",
  messagingSenderId: "289004803724",
  appId: "1:289004803724:web:48d3d84395f625912ecda9",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const rdb = getDatabase(app, import.meta.env.VITE_REALTIME_DATABASE_URL);
export const auth = getAuth(app);
