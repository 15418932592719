import { Button } from "./ui/button";
import Page from "./Page";

function HomePage() {
  return (
    <Page>
      <div className="max-w-7xl text-slate-700">
        <section>
          <h1 className="text-3xl font-bold leading-tight tracking-tight">
            Onboarding in 10 minutes
          </h1>
          <div className="grid h-[25%] w-full grid-cols-1 gap-6 sm:grid-cols-3">
            <div>
              <h3 className="text-lg font-bold">
                Part 1
                <span className="ml-2 text-sm text-gray-400">(4 minutes)</span>
              </h3>
              <div className="relative h-0 pb-[62.5%]">
                <iframe
                  src="https://www.loom.com/embed/7e2b175a5ca5457fb37a8e75836af9a1?sid=4e3375a7-b4f6-4788-a9e2-5a15b5b6ace5?autoplay=false&hide_owner=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  className="absolute left-0 top-0 h-full w-full"
                />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-bold">
                Part 2
                <span className="ml-2 text-sm text-gray-400">(5 minutes)</span>
              </h3>
              <div className="relative h-0 pb-[62.5%]">
                <iframe
                  src="https://www.loom.com/embed/7d43dbce85334725b9c924c5f060cbc5?sid=f3572a7b-6c22-4f7d-b0ce-012882862788?autoplay=false&hide_owner=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  className="absolute left-0 top-0 h-full w-full"
                />
              </div>
            </div>
            <div className="">
              <h3 className="text-lg font-bold">
                Part 3
                <span className="ml-2 text-sm text-gray-400">(1 minute)</span>
              </h3>
              <div className="prose ">
                <ul>
                  <li>
                    Wir erstellen Feeds pro Sprache und pro Spiel. Feeds sind in
                    der Regel 1 Tag vor Anpfiff verfügbar.
                  </li>
                  <li>
                    Einträge zu Feeds werden i. d. R. alle 2 Minuten erstellt.
                    Kontaktiert uns bitte bei keinem Eintrag nach 5 Minuten
                    Spielzeit.
                  </li>
                </ul>
              </div>
              <div className="mt-4 flex w-full flex-row justify-evenly">
                <a title="Request Help">
                  <Button
                    onClick={() => window.$crisp.push(["do", "chat:open"])}
                  >
                    <span className=" ml-2 mr-2 font-medium">
                      Frage stellen
                    </span>
                  </Button>
                </a>
                <a title="Request Help">
                  <Button
                    variant={"outline"}
                    onClick={() => window.$crisp.push(["do", "chat:open"])}
                  >
                    <span className=" ml-2 mr-2 font-medium">
                      Meeting vereinbaren
                    </span>
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h1 className="mt-10 text-3xl font-bold leading-tight tracking-tight">
            Home
          </h1>
          <div className="flex w-full flex-col items-center justify-center">
            <p className="font-bold">Work in Progress</p>
            <p>
              What would you like to see here? Give Feedback (top right corner)
            </p>
          </div>
        </section>
      </div>
    </Page>
  );
}

export default HomePage;
