interface Card {
  MinuteOfPlay: number;
  PlayerLastName: string;
  TeamName: string;
  CardRating: "yellow" | "red" | "yellowRed" | "canceled";
}

const RefereeCard = ({
  color,
  isCanceled = false,
}: {
  color: "yellow" | "red" | "yellowRed";
  isCanceled?: boolean;
}) => {
  const cardColor = {
    yellow: "bg-yellow-400",
    red: "bg-red-600",
    yellowRed: "bg-yellow-400",
  }[color];

  return (
    <div className="flex flex-row">
      <div className={`${cardColor} h-6 w-4 rounded-sm`}></div>
      {color === "yellowRed" && (
        <div className={`-ml-2 -mt-1 h-6 w-4 rounded-sm bg-red-600`}></div>
      )}
      {isCanceled && (
        <div
          className={
            (color === "yellowRed" ? "-ml-6" : "-ml-3") +
            " font-bold text-white"
          }
        >
          F
        </div>
      )}
    </div>
  );
};

function getCardsFromStsContext(stsContext: any): Card[] {
  return stsContext.events.GameSection.flatMap(
    (s: any) => s.Cards || [],
  ).filter((c: any) => c.CardRating !== "unvalued");
}

function getCardsFromSmContext(smContext: any): Card[] {
  const mapping: Record<string, string> = {
    YELLOWCARD: "yellow",
    REDCARD: "red",
    YELLOWREDCARD: "yellowRed",
  };
  return smContext.events
    .filter((e: any) => Object.keys(mapping).includes(e.type.developer_name))
    .sort((a: any, b: any) => a.sort_order - b.sort_order)
    .map((e: any) => ({
      MinuteOfPlay:
        `${e.minute}.` + (e.extra_minute ? `+${e.extra_minute}` : ""),
      PlayerLastName: e.player.lastname,
      TeamName: e.participant.name,
      CardRating: mapping[e.type.developer_name],
    }));
}

function GameCardDisplay({
  stsContext,
  smContext,
}: {
  stsContext: any;
  smContext: any;
}) {
  let fn, ctx;
  if (stsContext) {
    fn = getCardsFromStsContext;
    ctx = stsContext;
  } else if (smContext) {
    fn = getCardsFromSmContext;
    ctx = smContext;
  } else return;
  const cards = fn(ctx);

  return (
    <div className="m-4">
      <div className="prose-sm">
        <strong>Cards</strong>
      </div>
      <ul>
        {cards.map((card: Card, index: number) => (
          <li key={index} className="prose prose-sm flex gap-1">
            {`${card.MinuteOfPlay} ${card.PlayerLastName} (${card.TeamName})`}
            <RefereeCard
              color={
                card.CardRating === "canceled" ? "yellow" : card.CardRating
              }
              isCanceled={card.CardRating === "canceled"}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GameCardDisplay;
