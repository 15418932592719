import { useAuth } from "@clerk/clerk-react";
import { FeedEntry, Period } from "./types";
import { useEffect, useState } from "react";
import { toZonedTime } from "date-fns-tz/toZonedTime";
import { parseISO } from "date-fns";

export function getOrgBaseUrl(...parts: string[]): URL {
  const url = new URL(window.location.href);
  const segments = url.pathname.split("/").filter(Boolean).slice(0, 1);
  segments.push(...parts);
  url.pathname = "/" + segments.join("/");
  return url;
}

export function flattenJson(
  data: any,
  parentKey: string = "",
  result: any = {},
): any {
  if (typeof data === "object" && data !== null) {
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        flattenJson(
          item,
          `${parentKey}${parentKey ? "." : ""}${index}`,
          result,
        );
      });
    } else {
      Object.keys(data).forEach((key) => {
        flattenJson(
          data[key],
          `${parentKey}${parentKey ? "." : ""}${key}`,
          result,
        );
      });
    }
  } else {
    result[parentKey] = data;
  }
  return result;
}

const _formatter = new Intl.DateTimeFormat("de-DE", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: undefined,
  timeZone: "Europe/Berlin",
});

function warnInplicitTimezone(timestamp: string) {
  // either a value like +00:00 or 'Z'
  const hasExplicitTimezone = /Z|[+-]\d{2}:\d{2}$/.test(timestamp);

  if (!hasExplicitTimezone) {
    console.warn(
      'Warning: The timestamp does not contain an explicit timezone (e.g., "Z" or "+00:00"). This might lead to incorrect time interpretation.',
    );
  }
}

export function convertToLocalTime(isoTimestamp: string): Date {
  if (!isValidISOString(isoTimestamp)) {
    throw new Error("Invalid ISO timestamp format");
  }
  warnInplicitTimezone(isoTimestamp);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDate = toZonedTime(parseISO(isoTimestamp), localTimeZone);
  return localDate;
}

function isValidISOString(str: string): boolean {
  try {
    return Boolean(new Date(str).toISOString());
  } catch {
    return false;
  }
}

export function dateFormatter(timestamp: string | undefined | null) {
  if (timestamp == null) return "-";
  const date = timestamp.slice(0, "YYYY-MM-DD".length);
  return _formatter.format(new Date(date));
}

export function datetimeFormatter(timestamp: string | undefined | null) {
  if (timestamp) warnInplicitTimezone(timestamp);
  return timestamp == null ? "-" : _formatter.format(new Date(timestamp));
}

export const periodTranslations: Record<Period, string> = {
  BEFORE_MATCH: "Before Match",
  "1ST_HALF": "1st Half",
  HALF_TIME: "Half Time",
  "2ND_HALF": "2nd Half",
  FULL_TIME: "Full Time",
  BEFORE_EXTRA_TIME: "Before Extra Time",
  EXTRA_1ST_HALF: "Extra 1st Half",
  EXTRA_HALF_TIME: "Extra Half Time",
  EXTRA_2ND_HALF: "Extra 2nd Half",
  EXTRA_FULL_TIME: "Extra Full Time",
  BEFORE_PENALTY_SHOOTOUT: "Before Penalty Shootout",
  PENALTY_SHOOTOUT: "Penalty Shootout",
  PENALTY_FULL_TIME: "Penalty Full Time",
};

function orderBy(e: FeedEntry) {
  return [
    e.game_time.period_order,
    e.game_time.minute,
    e.game_time.stoppage_minute,
    e.visible_at!,
  ];
}

export function entrySorter(a: FeedEntry, b: FeedEntry): number {
  const orderA = orderBy(a);
  const orderB = orderBy(b);
  for (let i = 0; i < orderA.length; i++) {
    if (orderA[i] > orderB[i]) return -1;
    if (orderA[i] < orderB[i]) return 1;
  }
  return 0;
}

// NOTE(memben): needed as firebase translates our v1 array style objects to arrays
export function arrify<T>(
  input: Array<T> | Record<string, T> | undefined,
): { object: T; key: string | number }[] {
  const result: { object: T; key: string | number }[] = [];
  if (!input) return result;

  if (Array.isArray(input)) {
    input.forEach((obj, idx) => {
      result.push({ object: obj, key: idx });
    });
  } else {
    Object.entries(input).forEach(([key, obj]) => {
      result.push({ object: obj, key: key });
    });
  }
  return result;
}

export function useBackendToken() {
  const { getToken } = useAuth();
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    const fetchToken = async () => {
      const token = await getToken({ template: "web_liveticker_ai" });
      setToken(token);
    };
    fetchToken();
  }, [getToken]);
  return token;
}

export function streamlitUrl(
  path: string,
  params: Record<string, string>,
): string {
  const env =
    import.meta.env.VITE_ENVIRONMENT != "local"
      ? import.meta.env.VITE_ENVIRONMENT
      : "staging";
  params.env = env;
  const q = new URLSearchParams(params);
  path = path.replace(/^\//, "");
  const url = new URL(`http://ticker.botbrains.io/${path}`);
  url.search = q.toString();
  return url.toString();
}

export function getUrlViewWriter(id: string, organization_id: string) {
  return streamlitUrl("Writer", { id, organization_id });
}

export function getUrlCreateWriter(
  transcription_id: string,
  organization_id: string,
  persona_id: string,
  preferred_team_id: number,
) {
  return streamlitUrl("Create_Writer", {
    transcription_id,
    organization_id,
    persona_id,
    preferred_team_id: preferred_team_id.toString(),
  });
}

export function getUrlViewTranscription(id: string, organization_id: string) {
  return streamlitUrl("Transcription", { id, organization_id });
}

export function getUrlCreateTranscription(
  fixture_id: string,
  organization_id: string,
  language: string,
  is_video: boolean,
  source: string,
  is_deduplication: boolean,
  backend: string,
) {
  return streamlitUrl("Create_Transcription", {
    fixture_id,
    organization_id,
    language,
    is_video: is_video ? "1" : "0",
    source,
    is_deduplication: is_deduplication ? "1" : "0",
    backend,
  });
}
