import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ClerkProvider } from "@clerk/clerk-react";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  Outlet,
  redirect,
  RouterProvider,
} from "react-router-dom";
import EnsureAuthorized from "./EnsureAuthorized.tsx";
import store from "./store";
import ErrorBoundary from "./components/ErrorBoundary";
import Navigation from "./components/Navigation.tsx";
import { setupAnalytics } from "./analytics.ts";
import { setupMonitoring } from "./sentry.ts";
import FixturesPage from "./components/FixturesPage.tsx";
import RedirectToOrg from "./components/RedirectToOrg.tsx";
import ClerkFirebaseAuth from "./components/ClerkFirebaseAuth.tsx";
import FeedsPage from "./components/FeedsPage.tsx";
import FeedPage from "./components/FeedPage.tsx";
import FixturePage from "./components/FixturePage.tsx";
import UsagePage from "./components/UsagePage.tsx";
import HomePage from "./components/HomePage.tsx";
import SettingsPage from "./components/SettingsPage.tsx";
import TranscriptionsPage from "./components/TranscriptionsPage.tsx";
import WritersPage from "./components/WritersPage.tsx";
import ShowCasePage from "./components/ShowCasePage.tsx";
import "./i18n"; // Initialize i18n
import NoOrgFoundPage from "./components/NoOrgFoundPage.tsx";
import { Toaster } from "./components/ui/toaster.tsx";

// Import your publishable key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

setupMonitoring();
setupAnalytics();

// NOTE(liamvdv): could wrap every route in a React.lazy() load and Suspense.

const router = createBrowserRouter([
  {
    path: "/",
    element: <EnsureAuthorized />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Navigation />,
        children: [
          {
            path: "/",
            element: <RedirectToOrg />,
          },
          { path: "/:orgId", loader: () => redirect("./feeds") },
          {
            path: "/:orgId",
            element: (
              <ClerkFirebaseAuth>
                <Outlet />
              </ClerkFirebaseAuth>
            ),
            children: [
              {
                path: "/:orgId/home",
                element: <HomePage />,
              },
              {
                path: "/:orgId/feeds",
                element: <FeedsPage />,
              },
              {
                path: "/:orgId/feeds/:feedId",
                element: <FeedPage />,
              },
              {
                path: "/:orgId/fixtures",
                element: <FixturesPage />,
              },
              {
                path: "/:orgId/fixtures/:fixtureId",
                element: <FixturePage />,
              },
              { path: "/:orgId/settings", element: <SettingsPage /> },
              {
                path: "/:orgId/usage",
                element: <UsagePage />,
              },
              {
                path: "/:orgId/transcriptions",
                element: <TranscriptionsPage />,
              },
              { path: "/:orgId/writers", element: <WritersPage /> },
            ],
          },
          {
            path: "/break",
            element: (
              <button
                onClick={() => {
                  throw new Error("Error Monitoring Test");
                }}
              >
                Error Monitoring: Throw Error
              </button>
            ),
          },
        ],
      },
      {
        path: "/no-org-found",
        element: <NoOrgFoundPage />,
      },
    ],
  },
  {
    path: "embed/:orgId/feeds/:feedId",
    element: <ShowCasePage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <Provider store={store}>
        <RouterProvider router={router} />
        <Toaster />
      </Provider>
    </ClerkProvider>
  </React.StrictMode>,
);
// Chunk rum and DataDog dependency for faster initial load
import("./rum").then(({ setupRum }) => setupRum());
