import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CacheState, FetchDataArgs, FetchDataPayload } from "../types";

export const fetchData = createAsyncThunk<
  FetchDataPayload,
  FetchDataArgs,
  { rejectValue: { query: string; error: string } }
>(
  "data/fetchData",
  async ({ query, resultType }: FetchDataArgs, { rejectWithValue }) => {
    try {
      const response = await fetch("/datahub/sql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query, result: resultType }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      return { query, result };
    } catch (error: any) {
      return rejectWithValue({ query, error: error.message });
    }
  },
  {
    condition: (args: FetchDataArgs, { getState }) => {
      const { data }: { data: CacheState } = getState() as { data: CacheState };
      const { query } = args;

      // Prevent fetching if already loading or data is cached
      if (data.loading[query] || data.error[query]) {
        return false;
      }
    },
  },
);

const initialState: CacheState = {
  cache: {},
  loading: {},
  error: {},
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        const { query } = action.meta.arg;
        state.loading[query] = true;
        delete state.error[query];
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        const { query } = action.meta.arg;
        const { result } = action.payload;
        state.loading[query] = false;
        state.cache[query] = result;
      })
      .addCase(fetchData.rejected, (state, action) => {
        const { query } = action.meta.arg;
        state.loading[query] = false;
        state.error[query] = action.payload?.error || "Unknown error";
      });
  },
});

export default dataSlice.reducer;
