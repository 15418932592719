import { drizzle } from "drizzle-orm/pg-proxy";

// NOTE(liamvdv): https://github.com/orgs/clerk/discussions/1751#discussioncomment-9997572
export const getBackendClerkToken = async () => {
  if (!window.Clerk?.session) return null;
  return (
    (await window?.Clerk?.session?.getToken({
      template: "web_liveticker_ai",
    })) ?? null
  );
};

const cache = new Map();

const db = drizzle(async (sql, params, method) => {
  const cacheKey = JSON.stringify({ sql, params, method });
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }
  const token = await getBackendClerkToken();
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_BASE_URL}/datahub/query`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sql, params, method }),
      },
    );

    if (!response.ok) {
      throw new Error(`Error from pg proxy server: ${response.statusText}`);
    }

    const data = await response.json();

    const result = { rows: data };
    cache.set(cacheKey, result);
    return result;
  } catch (e: any) {
    console.error("Error from pg proxy server: ", e.message);
    return { rows: [] };
  }
});

export default db;
