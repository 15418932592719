import { useEffect, useState } from "react";
import {
  DataSnapshot,
  ref,
  query,
  limitToLast,
  onValue,
  update,
  orderByChild,
} from "firebase/database";
import { EntryVisibility, FeedSession, visibilityValues } from "./types";
import { rdb } from "./firebase";

function useCurrentFeedSession(orgId: string, feedId: string) {
  const [sessionUri, setSessionUri] = useState<string>("");
  const [currentSession, setCurrentSession] = useState<FeedSession | null>(
    null,
  );
  const [currentSessionError, setCurrentSessionError] = useState<Error | null>(
    null,
  );

  useEffect(() => {
    const path = `organizations/${orgId}/writers/${feedId}/sessions`;
    const q = query(
      ref(rdb, path),
      orderByChild("last_session_healthcheck"),
      limitToLast(1),
    );
    const handleData = (snapshot: DataSnapshot) => {
      const data = snapshot.val();
      if (data) {
        const key = Object.keys(data)[0];
        setSessionUri(`${path}/${key}`);
        setCurrentSession(data[key]);
      } else {
        setSessionUri("");
        setCurrentSession(null);
      }
    };
    const handleError = (error: Error) => {
      console.error("Error getting feed session", error);
      setCurrentSessionError(error);
    };

    const unsubscribe = onValue(q, handleData, handleError);
    return unsubscribe;
  }, [orgId, feedId]);

  const setDefaultVisibility = (
    visibility: EntryVisibility,
    delay: number = 10,
  ) => {
    if (!sessionUri) throw new Error("No Session URI");
    if (!visibilityValues.includes(visibility))
      throw new Error(
        `Invalid Visibility value '${visibility}' (${typeof visibility})`,
      );
    const r = ref(rdb, sessionUri);
    const action = async () =>
      await update(r, {
        default_visibility: visibility,
        visibility_delay: visibility == "draft" ? delay : 0,
      });
    action();
  };

  return {
    currentSession,
    currentSessionError,
    setDefaultVisibility,
    sessionUri,
  };
}

export { useCurrentFeedSession };
