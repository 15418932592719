import { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  OrganizationSwitcher,
  useOrganization,
  UserButton,
  useUser,
} from "@clerk/clerk-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LinkWithQuery from "./LinkWithQuery";
import { HandRaisedIcon } from "@heroicons/react/24/solid";
import Footer from "./Footer";
import { useFeatureFlags } from "@/hooks";
import { Button } from "./ui/button";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function useLocalNav() {
  const { pathname } = useLocation();
  const { organization, isLoaded } = useOrganization();
  const { isFeatureEnabled } = useFeatureFlags();
  if (!isLoaded || !organization) {
    return [];
  }

  const localNavigation = [
    { name: "Home", href: `/${organization.id}/home` },
    { name: "Feeds", href: `/${organization.id}/feeds` },
    { name: "Fixtures", href: `/${organization.id}/fixtures` },
    { name: "Usage", href: `/${organization.id}/usage` },
    { name: "Settings", href: `/${organization.id}/settings` },
  ];

  if (isFeatureEnabled("transcriptions"))
    localNavigation.push({
      name: "Transcriptions",
      href: `/${organization.id}/transcriptions`,
    });
  if (isFeatureEnabled("writers"))
    localNavigation.push({
      name: "Writers",
      href: `/${organization.id}/writers`,
    });

  return localNavigation.map((item) => ({
    ...item,
    current: pathname.startsWith(item.href),
  }));
}

function LocalNav() {
  const localNavigation = useLocalNav();
  return (
    <div className="flex items-baseline overflow-y-auto sm:space-x-4">
      {localNavigation.map((item) => (
        <div
          className={classNames(
            item.current ? "border-b-2 border-emerald-600" : "",
            "p-1 pb-2",
          )}
          key={item.name}
        >
          <LinkWithQuery
            to={item.href}
            key={item.name}
            className="whitespace-nowrap rounded-md px-2 py-1 text-sm font-medium text-gray-900 hover:bg-gray-100"
          >
            {item.name}
          </LinkWithQuery>
        </div>
      ))}
    </div>
  );
}

export default function Navigation() {
  const { organization, isLoaded } = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const navigation = useLocalNav();
  const { user } = useUser();

  const orgId = organization?.id;
  const pathname = location.pathname;
  const search = location.search;
  const hash = location.hash;

  /* /org_XXXXXXXX/feeds/XXXXXXXX-XXXX-XXXX-XXXXXXX */
  const hideFooter =
    location.pathname.split("/").length != 4 &&
    location.pathname.split("/")[2] !== "feeds";

  useEffect(() => {
    // Change org on org change
    if (isLoaded && orgId) {
      const parts = pathname.split("/");
      const updated = parts.map((part) =>
        part.startsWith("org_") ? orgId : part,
      );
      const newPathname = updated.join("/");

      if (newPathname !== pathname) {
        navigate({
          pathname: newPathname,
          search: search,
          hash: hash,
        });
      }
    }
  }, [isLoaded, orgId, pathname, search, hash, navigate]);

  return (
    <div className="flex min-h-screen flex-col">
      <div>
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex items-center gap-3">
                    {/* Logo */}
                    <LinkWithQuery to="/">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          className="block h-10 w-auto lg:hidden"
                          src="/botbrains-square.svg"
                          alt="botBrains Logo"
                        />
                        <img
                          className="hidden h-6 w-auto lg:block"
                          src="/botbrains-logo.png"
                          alt="botBrains Logo"
                        />
                      </div>
                    </LinkWithQuery>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                    {/* Fix Alignment */}
                    <div className="pt-2">
                      <OrganizationSwitcher
                        hidePersonal={true}
                        afterSelectOrganizationUrl={(org) => `/${org.id}/feeds`}
                      />
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    <Menu as="div" className="relative ml-3">
                      <div className="flex flex-row items-center justify-between gap-2">
                        <a title="Request Help">
                          <Button
                            variant="outline"
                            className="rounded-full"
                            onClick={() =>
                              window.$crisp.push(["do", "chat:open"])
                            }
                          >
                            <HandRaisedIcon className="h-6 w-6 text-yellow-400" />
                            <span className=" ml-2 mr-2 font-medium">Help</span>
                          </Button>
                        </a>
                        <a title="Give Feedback">
                          <Button
                            variant="outline"
                            className="rounded-full"
                            onClick={() =>
                              window.$crisp.push(["do", "chat:open"])
                            }
                          >
                            <span className="font-medium">Feedback</span>
                          </Button>
                        </a>
                        <UserButton />
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {/* {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              >
                              {item.name}
                              </a>
                              )}
                              </Menu.Item>
                            ))} */}
                          <Menu.Item>
                            <UserButton />
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  {/* Mobile menu button */}
                  <div className="-mr-2 flex items-center sm:hidden">
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
                <div className="hidden sm:block">
                  <LocalNav />
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pb-3 pt-2">
                  {navigation.map((item) => (
                    <LinkWithQuery
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "border-emerald-500 bg-emerald-50 text-emerald-700"
                          : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                      )}
                    >
                      {item.name}
                    </LinkWithQuery>
                  ))}
                </div>
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="my-3 space-y-4">
                    <div className="mx-4 flex flex-row items-center gap-4">
                      <UserButton />{" "}
                      <span className="text-base font-medium text-gray-600">
                        {user?.fullName || ""}
                      </span>
                    </div>

                    {/* {[{name: "l", href: "#"}].map((item) => (
                      <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                      {item.name} HIHIH
                      </Disclosure.Button>
                    ))} */}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div className="flex-grow">
        <Outlet />
      </div>

      {hideFooter && <Footer />}
    </div>
  );
}
