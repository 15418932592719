import {
  fixtureInDatahub,
  leagueInSportmonks,
  seasonInSportmonks,
} from "@/generated/schema";
import { format, parseISO } from "date-fns";
import db from "@/db";
import { useDatahub } from "@/hooks";
import { useCallback, useEffect, useState } from "react";
import Page from "./Page";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  eq,
  or,
  ne,
  desc,
  InferSelectModel,
  isNotNull,
  and,
  inArray,
  gt,
} from "drizzle-orm";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { MultiSelect } from "./multi-select";
import { Card, CardDescription, CardHeader, CardTitle } from "./ui/card";
import Spinner from "./Loading";
import { useUser } from "@clerk/clerk-react";
import { convertToLocalTime } from "@/utils";

export interface Team {
  team_id: number; // Adjust the type based on your actual data
  team_name: string;
}

function FixturesPage() {
  const { user } = useUser();
  const { orgId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resetKey, setResetKey] = useState(0);
  const initialLeagueId = searchParams.get("leagueId");
  const initialSeasonId = searchParams.get("seasonId");
  const initialStageId = searchParams.get("stageId");
  const initialMatchDay = searchParams.get("matchDay");
  const initialTeamIds = searchParams.getAll("teamIds");

  const [selectedLeagueId, setSelectedLeagueId] = useState<number | null>(
    initialLeagueId ? Number(initialLeagueId) : null,
  );
  const [selectedSeasonId, setSelectedSeasonId] = useState<number | null>(
    initialSeasonId ? Number(initialSeasonId) : null,
  );
  const [selectedStageId, setSelectedStageId] = useState<number | null>(
    initialStageId ? Number(initialStageId) : null,
  );

  const [selectedMatchDay, setSelectedMatchDay] = useState<string>(
    initialMatchDay ? initialMatchDay : "future",
  ); // future, all, or a specific match day

  if (!orgId) {
    throw new Error("Organization ID not found");
  }

  const defaultTeams: number[] | null =
    (user?.unsafeMetadata[orgId] as any)?.fixtures?.default_teams ?? null;

  const [selectedTeamIds, setSelectedTeamIds] = useState<number[] | null>(
    initialTeamIds.length ? initialTeamIds.map(Number) : defaultTeams,
  );

  const leagueSelector = useCallback(
    () => db.select().from(leagueInSportmonks).orderBy(leagueInSportmonks.id),
    [],
  );
  const [leagues] = useDatahub(leagueSelector);

  const seasonSelector = useCallback(() => {
    if (!selectedLeagueId) {
      return db.select().from(seasonInSportmonks).limit(0);
    }

    const query = db
      .select()
      .from(seasonInSportmonks)
      .orderBy(desc(seasonInSportmonks.starting_at))
      .limit(25)
      .where(eq(seasonInSportmonks.league_id, selectedLeagueId));
    return query;
  }, [selectedLeagueId]);

  const [seasons, seasonsIsLoading] = useDatahub(seasonSelector);

  const selectedSeason = seasons.find(
    (season) => season.id === selectedSeasonId,
  );

  const stageSelector = useCallback(() => {
    if (!selectedSeasonId) {
      return db.select().from(fixtureInDatahub).limit(0);
    }

    return db
      .selectDistinctOn([fixtureInDatahub.stage])
      .from(fixtureInDatahub)
      .where(
        and(
          eq(fixtureInDatahub.sm_season_id, selectedSeasonId),
          isNotNull(fixtureInDatahub.sm_stage_id),
        ),
      )
      .orderBy(desc(fixtureInDatahub.stage))
      .limit(20);
  }, [selectedSeasonId]);

  const [stages, stagesIsLoading] = useDatahub(stageSelector);

  const matchDaySelector = useCallback(() => {
    if (!selectedSeasonId) {
      return db.select().from(fixtureInDatahub).limit(0);
    }

    const query = db
      .selectDistinctOn([fixtureInDatahub.matchday])
      .from(fixtureInDatahub)
      .where(
        and(
          eq(fixtureInDatahub.sm_season_id, selectedSeasonId),
          selectedStageId
            ? eq(fixtureInDatahub.sm_stage_id, selectedStageId)
            : undefined,
          isNotNull(fixtureInDatahub.matchday),
          ne(fixtureInDatahub.matchday, ""),
        ),
      )
      .orderBy(desc(fixtureInDatahub.matchday))
      .limit(40);
    return query;
  }, [selectedSeasonId, selectedStageId]);

  const [matchDays, matchDaysIsLoading] = useDatahub(matchDaySelector);

  const teamSelector = useCallback(() => {
    if (!selectedSeasonId) {
      if (defaultTeams == null || defaultTeams.length === 0) {
        return db.select().from(fixtureInDatahub).limit(0);
      }
      // NOTE(memben): fetch the default teams to display them correctly
      return db
        .selectDistinct({
          team_id: fixtureInDatahub.sm_home_team_id,
          team_name: fixtureInDatahub.sm_home_team_name,
        })
        .from(fixtureInDatahub)
        .where(or(inArray(fixtureInDatahub.sm_home_team_id, defaultTeams)))
        .limit(10);
    }

    const homeTeamsQuery = db
      .selectDistinct({
        team_id: fixtureInDatahub.sm_home_team_id,
        team_name: fixtureInDatahub.sm_home_team_name,
      })
      .from(fixtureInDatahub)
      .where(
        or(
          eq(fixtureInDatahub.sm_season_id, selectedSeasonId),
          defaultTeams !== null
            ? inArray(fixtureInDatahub.sm_home_team_id, defaultTeams)
            : undefined,
        ),
      )
      .limit(50);

    const awayTeamsQuery = db
      .selectDistinct({
        team_id: fixtureInDatahub.sm_away_team_id,
        team_name: fixtureInDatahub.sm_away_team_name,
      })
      .from(fixtureInDatahub)
      .where(eq(fixtureInDatahub.sm_season_id, selectedSeasonId))
      .limit(50);

    // Combine the results
    return homeTeamsQuery.union(awayTeamsQuery);
  }, [selectedSeasonId, defaultTeams]);

  // @ts-expect-error TODO(memben) wrong type hinting from drizzle-orm
  const [teams, teamsLoading] = useDatahub<Team>(teamSelector);

  const fixturesSelector = useCallback(() => {
    const seasonFinished = selectedSeason?.finished ?? false;
    const now = new Date();
    const twelveHoursAgo = new Date(
      now.getTime() - 12 * 60 * 60 * 1000,
    ).toISOString();

    console.log("TWELVE HOURS AGO", selectedMatchDay, twelveHoursAgo);

    const whereStmt = and(
      selectedLeagueId
        ? eq(fixtureInDatahub.sm_league_id, selectedLeagueId)
        : undefined,
      selectedSeasonId
        ? eq(fixtureInDatahub.sm_season_id, selectedSeasonId)
        : undefined,
      selectedStageId
        ? eq(fixtureInDatahub.sm_stage_id, selectedStageId)
        : undefined,
      selectedMatchDay &&
        selectedMatchDay !== "all" &&
        selectedMatchDay !== "future"
        ? eq(fixtureInDatahub.matchday, selectedMatchDay)
        : undefined,
      selectedTeamIds
        ? or(
            inArray(fixtureInDatahub.sm_home_team_id, selectedTeamIds),
            inArray(fixtureInDatahub.sm_away_team_id, selectedTeamIds),
          )
        : undefined,
      selectedMatchDay === "future"
        ? gt(fixtureInDatahub.kickoff, twelveHoursAgo)
        : undefined,
    );

    return db
      .select()
      .from(fixtureInDatahub)
      .where(whereStmt)
      .orderBy(
        seasonFinished
          ? desc(fixtureInDatahub.kickoff)
          : fixtureInDatahub.kickoff,
      )
      .limit(50);
  }, [
    selectedLeagueId,
    selectedSeasonId,
    selectedStageId,
    selectedMatchDay,
    selectedTeamIds,
    selectedSeason?.finished,
  ]);

  const [fixtures, fixturesIsLoading] = useDatahub(fixturesSelector);

  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedLeagueId) params.append("leagueId", String(selectedLeagueId));
    if (selectedSeasonId) params.append("seasonId", String(selectedSeasonId));
    if (selectedStageId) params.append("stageId", String(selectedStageId));
    if (selectedMatchDay) params.append("matchDay", selectedMatchDay);
    if (selectedTeamIds?.length)
      selectedTeamIds.forEach((id) => params.append("teamIds", String(id)));

    setSearchParams(params);
  }, [
    selectedLeagueId,
    selectedSeasonId,
    selectedStageId,
    selectedMatchDay,
    selectedTeamIds,
  ]);

  useEffect(() => {
    if (seasonsIsLoading) return;

    const isValidSeason = seasons.some(
      (season) => season.id === selectedSeasonId,
    );

    if (!isValidSeason) {
      const defaultSeasonId = seasons[0]?.id ?? null;
      setSelectedSeasonId(defaultSeasonId);
    }
  }, [seasons, seasonsIsLoading, selectedSeasonId]);

  useEffect(() => {
    if (stagesIsLoading) return;

    if (selectedStageId === null) return;

    const isValidStage = stages.some(
      (stage) => stage.sm_stage_id === selectedStageId,
    );

    if (!isValidStage) {
      setSelectedStageId(null);
    }
  }, [stages, stagesIsLoading, selectedStageId]);

  useEffect(() => {
    if (!matchDays) return;
    if (matchDaysIsLoading) return;
    if (selectedMatchDay === "future" && selectedSeason?.finished) {
      setSelectedMatchDay("all");
      return;
    }
    if (selectedMatchDay === "future" || selectedMatchDay === "all") return;
    // Check if the selected match days are valid
    if (selectedMatchDay) {
      const isValidMatchDay = matchDays.some(
        (matchDay) => matchDay.matchday === selectedMatchDay,
      );
      if (isValidMatchDay) return;
    }
    setSelectedMatchDay("future");
  }, [
    matchDays,
    matchDaysIsLoading,
    selectedMatchDay,
    selectedSeason?.finished,
  ]);

  useEffect(() => {
    if (teamsLoading) return;
    if (teams.length === 0) {
      // we allow the default teams to be selected e
      if (selectedTeamIds == defaultTeams) {
        return;
      }
      setSelectedTeamIds(null);
      return;
    }
    // Check if the selected teams are valid
    if (selectedTeamIds) {
      const isValidTeam = selectedTeamIds.every((teamId) =>
        teams.some((team) => team.team_id === teamId),
      );

      if (isValidTeam) return;

      if (selectedTeamIds == defaultTeams) {
        return;
      }
    }
    setSelectedTeamIds(null);
    setResetKey((prevKey) => prevKey + 10);
  }, [teams, teamsLoading, selectedTeamIds, defaultTeams]);

  return (
    <Page>
      <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
        Fixtures
      </h1>
      <div className="mt-2 space-y-2">
        <div className="mb-4 mt-2 space-y-4 sm:space-y-2">
          <div className="flex flex-col space-y-2 sm:flex-row sm:justify-between sm:space-y-0">
            <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
              <Select
                key={resetKey}
                onValueChange={(value) => {
                  setSelectedLeagueId(Number(value));
                }}
                defaultValue={
                  selectedLeagueId ? String(selectedLeagueId) : undefined
                }
              >
                <SelectTrigger className="w-full sm:w-[180px]">
                  <SelectValue placeholder="Select a competition" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Competitions</SelectLabel>
                    {leagues.map((competition, i) => (
                      <SelectItem key={i} value={String(competition.id)}>
                        <div className="flex items-center space-x-2">
                          {competition.image_path && (
                            <img
                              src={competition.image_path}
                              className="h-8 w-auto"
                            />
                          )}
                          <p className="line-clamp-2 text-sm">
                            {competition.name}
                          </p>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
              <Select
                key={resetKey + 1}
                disabled={!selectedLeagueId}
                onValueChange={(value) => {
                  setSelectedSeasonId(Number(value));
                }}
                defaultValue={
                  selectedSeasonId ? String(selectedSeasonId) : undefined
                }
              >
                <SelectTrigger className="w-full sm:w-[180px]">
                  <SelectValue placeholder="Select a season" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Seasons</SelectLabel>
                    {seasons.map((season, i) => (
                      <SelectItem key={i} value={String(season.id)}>
                        {season.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
              {stages.length > 1 && (
                <Select
                  key={resetKey + 2}
                  onValueChange={(value) => {
                    if (value === "all") {
                      setSelectedStageId(null);
                      return;
                    }
                    setSelectedStageId(Number(value));
                  }}
                  defaultValue={
                    selectedStageId ? String(selectedStageId) : "all"
                  }
                  disabled={!selectedSeasonId}
                >
                  <SelectTrigger className="w-full sm:w-[180px]">
                    <SelectValue placeholder="Select a stage" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Stages</SelectLabel>
                      <SelectItem value="all">All Stages</SelectItem>
                      {stages.map((stage, i) => (
                        <SelectItem key={i} value={String(stage.sm_stage_id!)}>
                          {stage.stage}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )}

              <Select
                key={resetKey + 3}
                onValueChange={(value) => {
                  setSelectedMatchDay(value);
                }}
                defaultValue={selectedMatchDay}
                disabled={!selectedSeasonId}
              >
                <SelectTrigger className="w-full sm:w-[180px]">
                  <SelectValue placeholder="Select a match day" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {/* <SelectLabel>Match Days</SelectLabel> */}
                    <SelectItem value="future" className="font-bold">
                      Upcoming Matches
                    </SelectItem>
                    <SelectItem value="all" className="font-bold">
                      All Matches
                    </SelectItem>
                    {matchDays
                      .sort(
                        (a, b) =>
                          parseInt(a.matchday ?? "0") -
                          parseInt(b.matchday ?? "0"),
                      )
                      .map((matchDay) => {
                        if (matchDay.matchday === null) {
                          throw new Error("Match day is null");
                        }
                        if (matchDay.matchday === "") {
                          throw new Error("Match day is empty");
                        }
                        return (
                          <SelectItem
                            key={matchDay.matchday}
                            value={matchDay.matchday}
                          >
                            {(matchDay.matchday ?? "Unknown") + " Match Day"}
                          </SelectItem>
                        );
                      })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            {/* TODO(memben): gets out of screen for ipad style uis */}
            <MultiSelect
              key={resetKey + 4}
              className="w-full sm:w-auto"
              maxCount={3}
              placeholder="Select teams"
              options={teams.map((team) => ({
                label: team.team_name,
                value: String(team.team_id),
              }))}
              onValueChange={(values) => {
                if (values.length === 0) {
                  setSelectedTeamIds(null);
                  return;
                }
                setSelectedTeamIds(values.map((v) => Number(v)));
              }}
              defaultValue={selectedTeamIds ? selectedTeamIds.map(String) : []}
              disabled={!selectedSeasonId}
            />
          </div>
        </div>
        {fixturesIsLoading ? <Spinner /> : <FixtureList fixtures={fixtures} />}
      </div>
    </Page>
  );
}

function FixtureCard({
  fixture,
}: {
  fixture: InferSelectModel<typeof fixtureInDatahub>;
}) {
  const { orgId } = useParams();
  return (
    <Link to={`/${orgId}/fixtures/${fixture.id}`}>
      <Card>
        <div className="flex cursor-pointer">
          {/* <div className="flex-grow border-r"> */}
          <CardHeader>
            <CardTitle>
              <div className="flex items-center">
                {fixture.sm_home_team_image_path && (
                  <img
                    src={fixture.sm_home_team_image_path}
                    className="mx-2 h-12"
                  />
                )}
                <span>{fixture.sm_home_team_name}</span>
                <span className="mx-2">vs</span>
                <span>{fixture.sm_away_team_name}</span>
                {fixture.sm_away_team_image_path && (
                  <img
                    src={fixture.sm_away_team_image_path}
                    className="mx-2 h-12"
                  />
                )}
              </div>
            </CardTitle>
            <CardDescription>
              {fixture.venue_name && `${fixture.venue_name}, `}
              {fixture.kickoff &&
                format(
                  convertToLocalTime(fixture.kickoff + "Z"),
                  "eee dd MMM, HH:mm",
                )}
            </CardDescription>
          </CardHeader>
          {/* </div> */}
        </div>
      </Card>
    </Link>
  );
}

function FixtureList({
  fixtures,
}: {
  fixtures: InferSelectModel<typeof fixtureInDatahub>[];
}) {
  const fixturesByDay = fixtures.reduce(
    (acc, fixture) => {
      const day = fixture.kickoff
        ? format(convertToLocalTime(fixture.kickoff), "yyyy-MM-dd")
        : "Unknown";
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(fixture);
      return acc;
    },
    {} as Record<string, typeof fixtures>,
  );

  return (
    <div className="space-y-6">
      {Object.entries(fixturesByDay).map(([day, dayFixtures]) => (
        <div key={day}>
          <h3 className="mb-4 border-b text-lg font-semibold text-slate-500">
            {day === "Unknown"
              ? "Unknown"
              : format(parseISO(day), "EEEE - MMMM d, yyyy")}
          </h3>
          <div className="space-y-4">
            {dayFixtures.map((fixture) => (
              <div key={fixture.id}>
                <FixtureCard fixture={fixture} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default FixturesPage;
