import { fixtureInDatahub } from "@/generated/schema";
import Page from "./Page";
import { useCallback } from "react";
import db from "@/db";
import { useDatahub } from "@/hooks";
import { MultiSelect } from "./multi-select";
import { useParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import Spinner from "./Loading";
import { and, isNotNull } from "drizzle-orm";
import { Team } from "./FixturesPage";

function SettingsPage() {
  const { user } = useUser();
  const { orgId } = useParams();

  const teamSelector = useCallback(() => {
    const query = db
      .selectDistinct<{
        team_id: typeof fixtureInDatahub.sm_home_team_id;
        team_name: typeof fixtureInDatahub.sm_home_team_name;
      }>({
        team_id: fixtureInDatahub.sm_home_team_id,
        team_name: fixtureInDatahub.sm_home_team_name,
      })
      .from(fixtureInDatahub)
      .where(
        and(
          isNotNull(fixtureInDatahub.sm_home_team_id),
          isNotNull(fixtureInDatahub.sm_home_team_name),
        ),
      )
      .orderBy(fixtureInDatahub.sm_home_team_name);
    return query;
  }, []);

  // @ts-expect-error wrong type hinting from drizzle-orm
  const [teams, teamsLoading] = useDatahub<Team>(teamSelector);

  if (orgId == undefined) {
    throw Error("orgId is undefined");
  }

  if (teamsLoading) {
    return (
      <Page>
        <Spinner />
      </Page>
    );
  }

  return (
    <Page>
      <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
        Settings
      </h1>
      <section>
        <h2 className="mt-8 text-xl font-bold leading-tight tracking-tight text-gray-900">
          Fixture Search
        </h2>
        <div className="mt-4 space-y-2">
          <div>
            <h4 className="font-semibold">Default Teams</h4>
            <p className="text-sm text-gray-500">
              Select your team(s) to pre-select fixtures involving them.
            </p>
          </div>
          <div className="max-w-lg">
            <MultiSelect
              className="w-full"
              maxCount={3}
              placeholder="Select teams"
              options={teams.map((team) => ({
                label: team.team_name,
                value: String(team.team_id),
              }))}
              onValueChange={(values) => {
                user?.update({
                  unsafeMetadata: {
                    ...user.unsafeMetadata,
                    [orgId]: {
                      ...(user.unsafeMetadata[orgId] ?? {}),
                      fixtures: {
                        ...((user.unsafeMetadata[orgId] as any)?.fixtures ??
                          {}),
                        default_teams:
                          values.length > 0 ? values.map(Number) : undefined,
                      },
                    },
                  },
                });
              }}
              defaultValue={(
                user?.unsafeMetadata[orgId] as any
              )?.fixtures?.default_teams?.map(String)}
            />
          </div>
        </div>
      </section>
    </Page>
  );
}

export default SettingsPage;
