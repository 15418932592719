import { useState } from "react";

interface JsonNodeProps {
  data: any; // The data can be of any type
  keyName?: string; // Optional key name for displaying object keys
}

const JsonNode: React.FC<JsonNodeProps> = ({ data, keyName }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const copyJson = () => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
  };

  if (typeof data === "object" && data !== null) {
    const keys = Object.keys(data);
    return (
      <div>
        <button className="mx-2 text-sm text-gray-300" onClick={copyJson}>
          (Copy)
        </button>
        {keyName && <strong onClick={toggleExpand}>{keyName}: </strong>}
        {isExpanded ? (
          <div style={{ paddingLeft: "20px" }}>
            {keys.map((key) => (
              <JsonNode key={key} keyName={key} data={data[key]} />
            ))}
          </div>
        ) : (
          <span onClick={toggleExpand}>{keyName ? " {...}" : "{...}"}</span>
        )}
      </div>
    );
  }

  return (
    <div>
      {keyName && <strong>{keyName}: </strong>}
      {JSON.stringify(data)}
    </div>
  );
};

interface JsonDisplayProps {
  jsonData: any;
}

const NaiveJsonDisplay = ({ jsonData }: JsonDisplayProps) => {
  return <JsonNode data={jsonData} />;
};

export default NaiveJsonDisplay;
