function getTeamComponentFromStsContext(stsContext: any, teamRole: string) {
  const role = teamRole === "home" ? "home" : "guest";
  const team =
    stsContext.fixture?.Teams?.filter((team: any) => team.Role == role)[0] ||
    {};
  const players = team?.Players || [];

  const startingPlayers = players.filter(
    (player: any) => player.Starting === true,
  );
  const nonStartingPlayers = players.filter(
    (player: any) => player.Starting === false,
  );

  const substitutions = stsContext.events.GameSection.map(
    (section: any) => section.Substitutions || [],
  ).flat();

  const teamSubstitutions = substitutions.filter(
    (sub: any) => sub.TeamRole == role,
  );

  // NOTE(liamvdv): we can have a substitution without a minute of play, when substitutions occur during breaks
  const renderSubsituttion = (sub: any) =>
    `${sub.MinuteOfPlay || (sub.GameSection == "half" ? "HZ" : sub.GameSection)} ${sub.PlayerInLastName} kommt für ${sub.PlayerOutLastName}`;

  const coach = team.TrainerStaff.find(
    (staff: any) => staff.Role == "headcoach",
  );
  const starting11Html = (
    <>{startingPlayers.map((player: any) => player.LastName).join(", ")}</>
  );
  const benchHtml = (
    <>{nonStartingPlayers.map((p: any) => p.LastName).join(", ")}</>
  );
  const substitutionHtml = teamSubstitutions.map((sub: any, index: number) => (
    <span key={index}>
      {renderSubsituttion(sub)}
      <br />
    </span>
  ));
  const coachHtml = (
    <>
      {coach.FirstName} {coach.LastName}
    </>
  );

  return {
    teamName: team.TeamName,
    starting11Html,
    benchHtml,
    substitutionHtml,
    coachHtml,
  };
}

function getTeamComponentFromSmContext(smContext: any, teamRole: string) {
  const team =
    smContext.participants.find(
      (team: any) => team.meta.location === teamRole,
    ) || {};
  const players = smContext.lineups.filter((l: any) => l.team_id == team.id);

  const startingPlayers = players.filter(
    (player: any) => player.type.developer_name == "LINEUP",
  );
  const nonStartingPlayers = players.filter(
    (player: any) => player.type.developer_name == "BENCH",
  );

  const substitutions = smContext.events
    .filter((e: any) => e.type.developer_name == "SUBSTITUTION")
    .sort((a: any, b: any) => a.sort_order - b.sort_order);

  const teamSubstitutions = substitutions.filter(
    (sub: any) => sub.participant_id == team.id,
  );

  // TODO(liamvdv): minute or extra_time?
  const renderSubsituttion = (sub: any) =>
    `${sub.minute}. ${sub.player.lastname} kommt für ${sub.related_player.lastname}`;

  const coach = smContext.coaches.find(
    (c: any) => c.meta.participant_id == team.id,
  );
  const starting11Html = (
    <>{startingPlayers.map((p: any) => p.player.lastname).join(", ")}</>
  );
  const benchHtml = (
    <>{nonStartingPlayers.map((p: any) => p.player.lastname).join(", ")}</>
  );
  const substitutionHtml = teamSubstitutions.map((sub: any, index: number) => (
    <span key={index}>
      {renderSubsituttion(sub)}
      <br />
    </span>
  ));
  const coachHtml = (
    <>
      {coach.firstname} {coach.lastname}
    </>
  );

  return {
    teamName: team.name,
    starting11Html,
    benchHtml,
    substitutionHtml,
    coachHtml,
  };
}

function TeamComponent({
  stsContext,
  smContext,
  teamRole,
}: {
  stsContext: any;
  smContext: any;
  teamRole: "home" | "away";
}) {
  let fn, ctx;
  if (stsContext) {
    fn = getTeamComponentFromStsContext;
    ctx = stsContext;
  } else if (smContext) {
    fn = getTeamComponentFromSmContext;
    ctx = smContext;
  } else return;
  const { teamName, starting11Html, benchHtml, substitutionHtml, coachHtml } =
    fn(ctx, teamRole);
  return (
    <div>
      <h1 className="text-xl font-bold">{teamName}</h1>
      <p>
        <strong>Startaufstellung</strong>
        <br />
        {starting11Html}
      </p>
      <p>
        <strong>Auswechselungen</strong>
        <br />
        {substitutionHtml}
      </p>
      <p>
        <strong>Reservebank</strong>
        <br />
        {benchHtml}
      </p>
      <p>
        <strong>Trainer</strong>
        <br />
        {coachHtml}
      </p>
    </div>
  );
}

export default TeamComponent;
