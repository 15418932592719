// DO NOT EDIT FIELD SIZE VARS. THEY ARE HERE TO REDUCE THE NUMBER OF MAGIC VARIABLES IN THE CODE
const FIELD_WIDTH = 452;
const FIELD_HEIGHT = 684;

const positionCoordinates = new Map([
  ["TW", { x: 226, y: 50 }], // Torwart
  ["LV", { x: 402, y: 200 }], // Linker Verteidiger
  ["IVL", { x: 302, y: 150 }], // Innenverteidiger Links
  ["IVZ", { x: 226, y: 130 }], // Innenverteidiger Zentral
  ["IVR", { x: 150, y: 150 }], // Innenverteidiger Rechts
  ["RV", { x: 50, y: 200 }], // Rechter Verteidiger
  ["DLM", { x: 352, y: 250 }], // Defensives linkes Mittelfeld
  ["DML", { x: 282, y: 220 }], // Defensives Mittelfeld Links
  ["DMZ", { x: 226, y: 200 }], // Defensives Mittelfeld Zentral
  ["DMR", { x: 170, y: 220 }], // Defensives Mittelfeld Rechts
  ["DRM", { x: 100, y: 250 }], // Defensives rechtes Mittelfeld
  ["LM", { x: 332, y: 280 }], // Linkes Mittelfeld
  ["HL", { x: 272, y: 270 }], // Halb Links
  ["MZ", { x: 226, y: 260 }], // Mittelfeld Zentral
  ["HR", { x: 180, y: 270 }], // Halb Rechts
  ["RM", { x: 120, y: 280 }], // Rechtes Mittelfeld
  ["OLM", { x: 312, y: 310 }], // Offensives Linkes Mittelfeld
  ["OHL", { x: 262, y: 300 }], // Offensives Halblinkes Mittelfeld
  ["ZO", { x: 226, y: 290 }], // Zentral Offensiv
  ["OHR", { x: 190, y: 300 }], // Offensives Halbrechtes Mittelfeld
  ["ORM", { x: 140, y: 310 }], // Offensives Rechtes Mittelfeld
  ["HST", { x: 226, y: 320 }], // Hängende Spitze
  ["LA", { x: 352, y: 330 }], // Links Außen
  ["STL", { x: 272, y: 330 }], // Sturm Links
  ["STZ", { x: 226, y: 340 }], // Sturm Zentral
  ["STR", { x: 180, y: 330 }], // Sturm Rechts
  ["RA", { x: 100, y: 330 }], // Rechts Außen
]);

function Jersey({
  x,
  y,
  jerseyNumber,
  playerName,
  isYellow,
  isRed,
  isSubstitutedIn,
  isSubstitutedOut,
  isCaptain,
  mainColor,
  numberColor,
  rotate = 0,
}: {
  x: number;
  y: number;
  jerseyNumber: number;
  playerName: string;
  isYellow: boolean;
  isRed: boolean;
  isSubstitutedIn: boolean;
  isSubstitutedOut: boolean;
  isCaptain: boolean;
  mainColor: string;
  numberColor: string;
  rotate?: number;
}) {
  const scale = 0.28;
  return (
    <g transform={`translate(${x}, ${y}) scale(${scale}) rotate(${rotate})`}>
      <rect
        x="-50"
        y="-80"
        width="100"
        height="120"
        fill={mainColor}
        stroke="black"
        strokeWidth="0"
      />
      <polygon
        points="-50,-80 -70,-40 -50,-30"
        fill={mainColor}
        stroke="black"
        strokeWidth="0"
      />
      <polygon
        points="50,-80 50,-30 70,-40"
        fill={mainColor}
        stroke="black"
        strokeWidth="0"
      />
      <text
        x="0"
        y="-10"
        fontSize="40"
        fontWeight="bold"
        textAnchor="middle"
        fill={numberColor}
      >
        {jerseyNumber}
      </text>
      <text x="0" y="30" textAnchor="middle" fill="black">
        <tspan x="0" dy="45" fontSize="35" fontWeight="bold">
          {playerName || ""}
        </tspan>
      </text>
      {isYellow && !isRed && (
        <rect
          x="40"
          y="-90"
          width="30"
          height="40"
          fill="yellow"
          stroke="black"
          strokeWidth="1"
        />
      )}
      {isRed && (
        <rect
          x="40"
          y="-50"
          width="30"
          height="40"
          fill="red"
          stroke="black"
          strokeWidth="1"
        />
      )}
      {isSubstitutedIn && (
        <path
          d="M-50 -75 L-70 -55 L-90 -75 Z" // Inverted arrow pointing down, horizontal line wider than height
          fill="green"
          stroke="green"
          strokeWidth="1.5"
        />
      )}

      {isSubstitutedOut && (
        <path
          d="M-50 -55 L-70 -75 L-90 -55 Z" // Upward arrow pointing up, horizontal line wider than height
          fill="red"
          stroke="red"
          strokeWidth="1.5"
        />
      )}
      {isCaptain && (
        <text
          x="0"
          y="-50"
          fontSize="35"
          textAnchor="middle"
          fill="gold"
          fontWeight="bold"
        >
          K
        </text>
      )}
    </g>
  );
}

interface PlayerData {
  playerId: string;
  position: string;
  isSubstituted: boolean;
  jerseyNumber: number;
  playerName: string;
  teamRole: "home" | "away";
  jerseyColor: string;
  jerseyNumberColor: string;
  isYellow: boolean;
  isRed: boolean;
  isCaptain: boolean;
}

interface SoccerFieldPlayer extends PlayerData {
  x: number;
  y: number;
}

function getPlayerData(
  stsContext: any,
  teamRole: "home" | "away",
  updateSubstitutions: boolean,
): PlayerData[] {
  const role = teamRole == "home" ? "home" : "guest";
  const team =
    stsContext.fixture?.Teams?.filter((team: any) => team.Role == role)[0] ||
    {};
  const players = team?.Players || [];

  const startingPlayers = players.filter(
    (player: any) => player.Starting === true,
  );
  let data = startingPlayers.map((p: any) => ({
    playerId: p.PersonId,
    position: p.PlayingPosition,
  }));

  // 1. Adjust for substitutions

  const substitutions = stsContext.events.GameSection.map(
    (section: any) => section.Substitutions || [],
  ).flat();
  data = data.map((p: any) => ({ ...p, isSubstituted: false }));
  substitutions.forEach((sub: any) => {
    const replaceAt = data.findIndex((p: any) => p.playerId == sub.PlayerOutId);
    if (replaceAt == -1) return;
    // do not update the data unless live data is requested
    if (updateSubstitutions) data[replaceAt].playerId = sub.PlayerInId;
    data[replaceAt].isSubstituted = true;
  });

  // 2. Adjust for yellow cards
  const cards = stsContext.events.GameSection.map(
    (s: any) => s.Cards || [],
  ).flat();
  data = data.map((p: any) => ({ ...p, isYellow: false, isRed: false }));
  cards.forEach((card: any) => {
    const playerAt = data.findIndex((p: any) => p.playerId == card.PlayerId);
    if (playerAt == -1) return;
    if (card.CardRating == "yellow") data[playerAt].isYellow = true;
    if (card.CardRating == "red" || card.CardRating == "yellowRed")
      data[playerAt].isRed = true;
  });

  // 3. Render the data [only correct people]
  return data.map((d: any) => {
    const player = players.find((p: any) => p.PersonId == d.playerId);
    return {
      teamRole,
      ...d,
      jerseyNumber: player.ShirtNumber,
      playerName: player.LastName,
      // there might be no captain annotated in later stages of the game, because he/she was substituted and we do not have the data on who receieved the captain's armband
      isCaptain: player.TeamLeader, // not necessarily right, but good enough (in start)
      jerseyColor: team.PlayerShirtMainColor,
      jerseyNumberColor: team.PlayerShirtNumberColor,
    };
  });
}

function positionsToCoordinates(arr: PlayerData[]): SoccerFieldPlayer[] {
  return arr.map((o) => {
    const pos = positionCoordinates.get(o.position);
    if (typeof pos === "undefined")
      throw new Error(`Position '${o.position}' not found`);
    const { x, y } = pos;
    return {
      ...o,
      x: o.teamRole == "home" ? x : FIELD_WIDTH - x,
      y: o.teamRole == "home" ? y : FIELD_HEIGHT - y,
    };
  });
}

/**
 * SoccerField component props.
 *
 * @param {object} props - The properties object.
 * @param {any} props.stsContext - The context for the soccer field.
 * @param {boolean} [props.updateSubstitutions=false] - If true, shows the most current players instead of the starting players.
 * @param {number} [props.rotate=0] - Allows rotating the field by up to 360 degrees clockwise.
 * @param {boolean} [props.debug=true] - If true, annotates the player positions for debugging purposes.
 */
const SoccerField = ({
  stsContext,
  updateSubstitutions = false,
  rotate = 0,
  debug = false,
}: {
  stsContext: any;
  updateSubstitutions?: boolean;
  rotate?: number;
  debug?: boolean;
}) => {
  const homePlayerData = positionsToCoordinates(
    getPlayerData(stsContext, "home", updateSubstitutions),
  );
  const awayPlayerData = positionsToCoordinates(
    getPlayerData(stsContext, "away", updateSubstitutions),
  );
  const playerData = homePlayerData.concat(awayPlayerData);

  // Calculate the height of each strip
  const stripHeight = FIELD_HEIGHT / 22;

  // Generate the repeating linear gradient for 24 strips using percentages
  const stripPercentage = (stripHeight / FIELD_HEIGHT) * 100;
  const backgroundImage = `repeating-linear-gradient(
    to bottom,
    #98EE64 0%,
    #98EE64 ${stripPercentage}%,
    #92e45f ${stripPercentage}%,
    #92e45f ${2 * stripPercentage}%
  )`;

  return (
    <div>
      <svg
        transform={`rotate(${rotate})`}
        id="jersey-container"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          // NOTE(liamvdv): we define the background image here on precentages, because pixels would lead to background scrolling on scaling the svg.
          backgroundImage,
          backgroundSize: "100% 100%",
          borderRadius: "6px",
          width: "100%",
          height: "100%",
        }}
        // Allow scaling:
        viewBox={`0 0 ${FIELD_WIDTH} ${FIELD_HEIGHT}`}
        preserveAspectRatio="xMidYMid meet"
      >
        <rect height={FIELD_HEIGHT} width={FIELD_WIDTH} fill="none" ry="6" />

        <g stroke="#efe" strokeWidth="3" fill="none">
          <path
            d={`m11.22 22.62v${FIELD_HEIGHT - 45.24}h${FIELD_WIDTH - 22.44}v-${FIELD_HEIGHT - 45.24}z`}
          />
          <path d={`m11.26 ${FIELD_HEIGHT / 2}h${FIELD_WIDTH - 22.52}`} />
          <circle cy={FIELD_HEIGHT / 2} cx={FIELD_WIDTH / 2} r="54.8" />
          <circle cy={FIELD_HEIGHT / 2} cx={FIELD_WIDTH / 2} r="2" />
          <g id="a">
            <path
              d={`m9.9 30.07c4.85 0 8.82-4 8.82-8.9m162.5 100.8a54.91 54.91 0 0 0 89.6 0m76.3-99.63v99.63h-242.2l.2-99.63m98.6.20v-15.6l44.6.003v15.6m-77.9-.20v34.4h111.2v-34.4m160.5 7.7c-4.9 0-8.8-4-8.8-8.9`}
            />
            <circle cy="90" cx={FIELD_WIDTH / 2} r="2" fill="white" />
          </g>
          <use
            xlinkHref="#a"
            transform={`scale(1,-1) translate(0,-${FIELD_HEIGHT})`}
          />
        </g>

        {playerData.map((player) => (
          <Jersey
            key={player.playerId}
            x={player.x}
            y={player.y}
            jerseyNumber={player.jerseyNumber}
            playerName={
              player.playerName + (debug ? " " + player.position : "")
            }
            isYellow={player.isYellow}
            isRed={player.isRed}
            isSubstitutedIn={player.isSubstituted && updateSubstitutions}
            isSubstitutedOut={player.isSubstituted && !updateSubstitutions}
            isCaptain={player.isCaptain}
            mainColor={player.jerseyColor}
            numberColor={player.jerseyNumberColor}
            rotate={-rotate}
          />
        ))}
      </svg>
    </div>
  );
};

const LineupComponent = ({
  stsContext,
  isVertical,
  updateSubstitutions,
}: {
  stsContext: any;
  isVertical: boolean;
  updateSubstitutions: boolean;
}) => {
  if (stsContext == undefined) return "No data available";
  return (
    <SoccerField
      stsContext={stsContext}
      rotate={isVertical ? 0 : -90}
      updateSubstitutions={updateSubstitutions}
    />
  );
};

export default LineupComponent;
