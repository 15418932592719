import { useFeedAndEntries, useFixture, useSMContext } from "@/hooks";
import { FeedEntry, Period, periods } from "@/types";
import { useParams } from "react-router-dom";
import {
  BeforeGameSummary,
  EventCard,
  FeedPeriodHeader,
  ShowcaseFeedEntryCard,
} from "./showCaseComponents";
import HideOnError from "./HideOnError";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useCurrentFeedSession } from "@/feedSession";

type Event = any;
type SortKey = [number, number, number];

const smPeriod2ltPeriod: Record<string, Period> = {
  "1ST_HALF": "1ST_HALF",
  "2ND_HALF": "2ND_HALF",
  ET: "EXTRA_1ST_HALF",
  ET_2ND_HALF: "EXTRA_2ND_HALF",
  PENALTY_SHOOTOUT: "PENALTY_SHOOTOUT",
};

const createSortKey = (
  period: Period,
  minute: number,
  stoppageMinute: number,
): SortKey => [periods.indexOf(period), minute, stoppageMinute];

const getSortKeyForEntry = (entry: FeedEntry): SortKey =>
  createSortKey(
    entry.game_time.period,
    entry.game_time.minute,
    entry.game_time.stoppage_minute,
  );

const getSortKeyForEvent = (event: Event): SortKey => {
  const period = smPeriod2ltPeriod[event.period.type.developer_name];
  if (!period) {
    throw new Error(`Unknown period type: ${event.period.type.developer_name}`);
  }
  return createSortKey(period, event.minute, event.extra_minute);
};

const compareSortKeys = (a: SortKey, b: SortKey): number => {
  for (let i = 0; i < 3; i++) {
    if (a[i] !== b[i]) return b[i] - a[i]; // Reverse sort
  }
  return 0;
};

const groupEntries = (
  context: { events: Event[] },
  publishedEntries: FeedEntry[],
): Map<Period, (Event | FeedEntry)[]> => {
  const periodsMap = new Map<Period, (Event | FeedEntry)[]>();

  const sortedItems = [
    ...(context.events ?? []).map((event) => ({
      key: getSortKeyForEvent(event),
      item: event,
    })),
    ...publishedEntries.map((entry) => ({
      key: getSortKeyForEntry(entry),
      item: entry,
    })),
  ].sort((a, b) => compareSortKeys(a.key, b.key));

  sortedItems.forEach(({ key, item }) => {
    const period = periods[key[0]];
    if (!periodsMap.has(period)) {
      periodsMap.set(period, []);
    }
    periodsMap.get(period)!.push(item);
  });

  return periodsMap;
};

// session.football_stream_state.period_entered_map;

export default function ShowCasePage() {
  const { orgId, feedId } = useParams();
  const { i18n } = useTranslation();

  if (!orgId || !feedId) {
    throw new Error("Organization ID or Feed ID not found");
  }

  // const fixture = db.select().from(fixtureInDatahub).where;
  const { feed, entries } = useFeedAndEntries(orgId, feedId);
  const { currentSession } = useCurrentFeedSession(orgId, feedId);
  const context = useSMContext(feed?.fixture_id) ?? { events: [] };
  const { fixture } = useFixture(feed?.fixture_id);

  useEffect(() => {
    const language = feed?.language || "english";
    const languageMap: Record<string, string> = {
      english: "en",
      german: "de",
      spanish: "es",
      portuguese: "pt",
      italian: "it",
      french: "fr",
      mandarin: "zh",
    };
    const target = languageMap[language] ?? "en";
    i18n.changeLanguage(target);
  }, [i18n, feed?.language]);

  const publishedEntries = entries.filter(
    (entry) => entry.visibility === "visible",
  );

  const groupedEntries = groupEntries(context, publishedEntries);

  return (
    <div className="mx-2">
      <div className="mx-auto max-w-2xl pt-2">
        {[...periods]
          .slice()
          .reverse()
          .filter(
            (period) =>
              groupedEntries.has(period) ||
              currentSession?.football_stream_state?.period_entered_map[
                period
              ] !== undefined,
          )
          .map((period) => {
            const entries = groupedEntries.get(period) || [];
            return (
              <div key={period} className="">
                <ul className="w-full">
                  {entries.map((entryOrEvent) =>
                    "feed_id" in entryOrEvent ? (
                      <ShowcaseFeedEntryCard
                        entry={entryOrEvent}
                        key={entryOrEvent.id}
                      />
                    ) : (
                      <HideOnError key={entryOrEvent.id}>
                        <EventCard event={entryOrEvent} context={context} />
                      </HideOnError>
                    ),
                  )}
                </ul>
                <FeedPeriodHeader period={period} />
              </div>
            );
          })}
      </div>
      <div className="mx-auto max-w-2xl pt-2">
        {fixture && <BeforeGameSummary fixture={fixture} />}
      </div>
    </div>
  );
}
