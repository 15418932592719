interface GameDataValues {
  gameTitle: string;
  kickOffDate: string;
  gameState: string;
  gameStatus: string;
  minuteOfPlay: string;
}

const getGameDataFromSmContext = (smContext: any): GameDataValues => {
  const renderGameState = (status: string): string => {
    switch (status) {
      case "NS": // Not Started
      case "PENDING": // Pending
      case "TBA": // To Be Announced
        return "Not Started";

      case "INPLAY_1ST_HALF": // 1st Half
      case "INPLAY_2ND_HALF": // 2nd Half
      case "INPLAY_ET": // Extra Time
      case "INPLAY_ET_2ND_HALF": // ET - 2nd Half
      case "INPLAY_PENALTIES": // Penalties
      case "PEN_BREAK": // Penalties - Break
        return "In Progress";

      case "FT": // Full Time
      case "AET": // After Extra Time
      case "FT_PEN": // After Penalties
        return "Finished";

      default:
        console.error("Unknown game status: ", status);
        return "Unknown";
    }
  };
  const renderGameStatus = (status: string): string => {
    switch (status) {
      case "NS": // Not Started
        return "Not Started";

      case "INPLAY_1ST_HALF": // 1st Half
        return "First Half";

      case "HT": // Half Time
        return "Half Time";

      case "INPLAY_2ND_HALF": // 2nd Half
        return "Second Half";

      case "INPLAY_ET": // Extra Time
      case "INPLAY_ET_2ND_HALF": // ET - 2nd Half
        return "Extra Time";

      case "BREAK": // Break
      case "EXTRA_TIME_BREAK": // Extra Time - Break
        return "Break";

      case "INPLAY_PENALTIES": // Penalties
      case "PEN_BREAK": // Penalties - Break
        return "Penalty Shootout";

      case "FT": // Full Time
        return "Full Time";

      case "AET": // After Extra Time
        return "After Extra Time";

      case "FT_PEN": // After Penalties
        return "After Penalty Shootout";

      default:
        console.error("Unknown game status: ", status);
        return status;
    }
  };

  const getMinuteOfPlay = (context: any): string => {
    if (!context.periods) {
      throw new Error("No/Empty periods found in context");
    }
    const current = context.periods.find((period: any) => period.ticking);
    if (!current) {
      // break
      return "";
    }
    const started = new Date(current.started); // unix epoch
    const elapsedMinutesInPeriod = Math.ceil(
      (Date.now() - started.getTime()) / 60000,
    );
    if (elapsedMinutesInPeriod > current.period_length) {
      const added = elapsedMinutesInPeriod - current.period_length;
      return `${current.period_length}.'+${added}`;
    } else {
      return `${elapsedMinutesInPeriod}.'`;
    }
  };

  const gameTitle = smContext.name;
  const kickOffDate = new Date(
    smContext.starting_at_timestamp * 1000,
  ).toLocaleString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const gameState = renderGameState(smContext.state.state);
  const gameStatus = renderGameStatus(smContext.state.state);
  const minuteOfPlay =
    gameState === "In Progress" ? getMinuteOfPlay(smContext) : "";

  return {
    gameTitle,
    kickOffDate,
    gameState,
    gameStatus,
    minuteOfPlay,
  };
};

const getGameDataFromStsContext = (stsContext: any): GameDataValues => {
  const { events } = stsContext;

  const renderGameState = (status: string): string => {
    switch (status) {
      case "preMatch":
        return "Not Started";
      case "firstHalf":
      case "secondHalf":
      case "half":
      case "firstHalfExtra":
      case "secondHalfExtra":
      case "penalty":
        return "In Progress";
      case "finalWhistle":
      case "penaltyShootout":
        return "Finished";
      default:
        console.error("Unknown game state: ", status);
        return "Unknown";
    }
  };

  const renderGameStatus = (status: string): string => {
    switch (status) {
      case "preMatch":
        return "Not Started";
      case "firstHalf":
        return "First Half";
      case "half":
        return "Half Time";
      case "secondHalf":
        return "Second Half";
      case "preExtra":
      case "firstHalfExtra":
        return "First Half of Extra Time";
      case "halfExtra":
        return "Extra Time Half Time";
      case "secondHalfExtra":
        return "Second Half of Extra Time";
      case "prePenalty":
        return "Pre-Penalty Shootout";
      case "penalty":
      case "penaltyShootout":
        return "Penalty Shootout";
      case "finalWhistle":
        return "Full Time";
      default:
        console.error("Unknown game status: ", status);
        return status;
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return {
    gameTitle: events.GameTitle,
    kickOffDate: formatDate(events.KickOff),
    gameState: renderGameState(events.MatchStatus),
    gameStatus: renderGameStatus(events.MatchStatus),
    minuteOfPlay: events.MinuteOfPlay || null,
  };
};

const GameData = ({
  stsContext,
  smContext,
}: {
  stsContext: any;
  smContext: any;
}) => {
  let fn, ctx;
  if (stsContext) {
    fn = getGameDataFromStsContext;
    ctx = stsContext;
  } else if (smContext) {
    fn = getGameDataFromSmContext;
    ctx = smContext;
  } else return;
  const { gameTitle, kickOffDate, gameState, gameStatus, minuteOfPlay } =
    fn(ctx);

  return (
    <div className="mx-auto text-sm">
      <div className="grid grid-cols-4 items-center gap-4 bg-gray-100 p-2">
        <div className="font-semibold">{gameTitle}</div>
        <div>{kickOffDate}</div>
        <div>{gameState}</div>
        <div>
          {gameStatus}
          {minuteOfPlay !== null && gameState !== "Finished" && (
            <span className="ml-2">({minuteOfPlay})</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameData;
