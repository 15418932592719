import { useParams, useSearchParams } from "react-router-dom";
import Page from "./Page";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

import { Card } from "./ui/card";
import { getOrgBaseUrl, getUrlCreateWriter, getUrlViewWriter } from "../utils";
import { useFeatureFlags, useFixture, useWriters } from "@/hooks";
import QueryDateRangePicker from "./QueryDateRangePicker";
import JsonHeaderDisplay from "./JsonHeaderDisplay";
import FilteredByFixtures from "./FilteredByFixture";
import { Task, WriterConfig } from "@/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

function flattenJson(data: any, parentKey: string = "", result: any = {}): any {
  if (typeof data === "object" && data !== null) {
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        flattenJson(
          item,
          `${parentKey}${parentKey ? "." : ""}${index}`,
          result,
        );
      });
    } else {
      Object.keys(data).forEach((key) => {
        flattenJson(
          data[key],
          `${parentKey}${parentKey ? "." : ""}${key}`,
          result,
        );
      });
    }
  } else {
    result[parentKey] = data;
  }
  return result;
}

const writerFields = [
  [
    {
      label: "ID",
      attr: "id",
    },
    {
      label: "Language",
      attr: "definition.metadata.language",
    },
    {
      label: "Command",
      attr: "definition.command",
      formatter: (v: string) => `${v.slice(0, 25)}...`,
      detail: {
        title: "Command",
        fields: [
          [
            {
              label: "Command",
              attr: "definition.command",
            },
          ],
        ],
      },
    },
  ],

  [
    {
      label: "Transcription ID",
      attr: "definition.metadata.transcription_id",
      href: (value: number) =>
        getOrgBaseUrl("transcriptions", String(value)).toString(),
    },
    {
      label: "Transcription URI",
      attr: "definition.metadata.s3_uri",
      href: (value: number) =>
        getOrgBaseUrl("transcriptions", String(value)).toString(),
    },
    {
      label: "Fixture ID",
      attr: "definition.metadata.fixture_id",
      href: (value: number) =>
        getOrgBaseUrl("fixtures", String(value)).toString(),
    },
    {
      label: "Latest Run ID",
      attr: "latest_run_id",
    },
  ],
  [
    {
      label: "Task",
      attr: "definition.task",
    },
    {
      label: "Status",
      attr: "status",
    },
    {
      label: "After",
      attr: "after",
    },
    {
      label: "Dedup ID",
      attr: "dedup_id",
    },
  ],
];

function WriterCard({ writer }: { writer: Task<WriterConfig> }) {
  const { orgId } = useParams();
  const { isFeatureEnabled } = useFeatureFlags();
  const fixture_id = writer.definition.metadata.fixture_id;
  const { fixture, isLoading, error } = useFixture(fixture_id);
  if (isLoading) {
    return <Card>Loading...</Card>;
  }
  if (error) {
    return <Card>Error: {String(error)}</Card>;
  }
  const data = { ...writer, fixture: fixture };
  return (
    <JsonHeaderDisplay
      variant={"card" as const}
      title="Writer"
      data={flattenJson(data)}
      fields={writerFields}
    >
      {isFeatureEnabled("writers") && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <EllipsisVerticalIcon className="h-6 w-6"></EllipsisVerticalIcon>
            {/* <Button variant="outline">Actions</Button> */}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <a
                href={getUrlViewWriter(writer.id, orgId!)}
                target="_blank"
                rel="noreferrer"
              >
                View Writer
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <a
                href={getUrlCreateWriter(
                  writer.definition.metadata.transcription_id,
                  orgId!,
                  writer.definition.metadata.persona_id,
                  writer.definition.metadata.preferred_team_id,
                )}
                target="_blank"
                rel="noreferrer"
              >
                Create from Template
              </a>
            </DropdownMenuItem>
            <DropdownMenuItem disabled={data.latest_run_id != null}>
              <a
                href={getUrlViewWriter(writer.id, orgId!)}
                target="_blank"
                rel="noreferrer"
              >
                Enable/Disable
              </a>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </JsonHeaderDisplay>
  );
}

function WriterList({ writers }: { writers: Task<WriterConfig>[] }) {
  return (
    <ul className="flex flex-col gap-2">
      {writers.map((writer) => (
        <li key={writer.id}>
          <WriterCard writer={writer} />
        </li>
      ))}
    </ul>
  );
}

function WritersPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || undefined;
  const fixture_id = searchParams.get("fixture_id")
    ? parseInt(searchParams.get("fixture_id") || "")
    : undefined;
  const limit = parseInt(searchParams.get("limit") || "50");
  const language = searchParams.get("language") || "any";
  const transcription_id = searchParams.get("transcription_id") || undefined;
  const transcription_uri = searchParams.get("transcription_uri") || undefined;
  const feed_id = searchParams.get("feed_id") || undefined;
  const since = searchParams.get("since");
  const until = searchParams.get("until");

  const { writers, loading, error } = useWriters({
    id,
    fixture_id,
    limit,
    language: language as "german" | "english" | "any",
    transcription_id,
    transcription_uri,
    feed_id,
    since: since ?? undefined,
    until: until ?? undefined,
  });

  if (loading) return <Page>Loading...</Page>;
  if (error) return <Page>Error: {error}</Page>;

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-4">
          <FilteredByFixtures size="default" />
          <Select
            value={language}
            onValueChange={(v) =>
              setSearchParams(
                { ...searchParams, language: v },
                { replace: true },
              )
            }
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filter by Language" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Languages</SelectLabel>
                <SelectItem value="any">Any Language</SelectItem>
                <SelectItem value="german">German</SelectItem>
                <SelectItem value="english">English</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <QueryDateRangePicker />
      </div>
      <div className="mt-4">
        <WriterList writers={writers} />
      </div>
    </Page>
  );
}

export default WritersPage;
export { WriterList, WriterCard };
