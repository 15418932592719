import React, { ReactNode } from "react";

interface PageProps {
  children: ReactNode;
}

const Page: React.FC<PageProps> = ({ children }) => {
  return (
    <div className="mx-1 mt-6 max-w-7xl sm:mx-auto sm:px-6 lg:px-8">
      {children}
    </div>
  );
};

export default Page;
