import {
  pgSchema,
  serial,
  integer,
  varchar,
  date,
  text,
  bigint,
  numeric,
  doublePrecision,
  timestamp,
  boolean,
  jsonb,
  real,
  primaryKey,
  index,
} from "drizzle-orm/pg-core";

export const sportmonks = pgSchema("sportmonks");
export const datahub = pgSchema("datahub");
export const sts = pgSchema("sts");

export const coachInSportmonks = sportmonks.table("coach", {
  id: serial("id").primaryKey().notNull(),
  player_id: integer("player_id"),
  sport_id: integer("sport_id"),
  country_id: integer("country_id"),
  nationality_id: integer("nationality_id"),
  common_name: varchar("common_name"),
  firstname: varchar("firstname"),
  lastname: varchar("lastname"),
  name: varchar("name"),
  display_name: varchar("display_name"),
  image_path: varchar("image_path"),
  height: integer("height"),
  weight: integer("weight"),
  date_of_birth: date("date_of_birth"),
  gender: varchar("gender"),
});

export const fixture_mappingInDatahub = datahub.table("fixture_mapping", {
  sportmonks_id: integer("sportmonks_id"),
  sm_name: varchar("sm_name"),
  sts_name: text("sts_name"),
  sts_id: varchar("sts_id"),
});

export const club_mappingInDatahub = datahub.table("club_mapping", {
  sts_id: varchar("sts_id"),
  sportmonks_id: integer("sportmonks_id"),
  // You can use { mode: "bigint" } if numbers are exceeding js number limitations
  cnt: bigint("cnt", { mode: "number" }),
  total_cnt: numeric("total_cnt"),
  confidence: doublePrecision("confidence"),
});

export const cityInSportmonks = sportmonks.table("city", {
  id: serial("id").primaryKey().notNull(),
  country_id: integer("country_id"),
  region_id: integer("region_id"),
  name: varchar("name"),
  latitude: varchar("latitude"),
  longitude: varchar("longitude"),
});

export const continentInSportmonks = sportmonks.table("continent", {
  id: serial("id").primaryKey().notNull(),
  name: varchar("name"),
  code: varchar("code"),
});

export const playerInSportmonks = sportmonks.table("player", {
  id: serial("id").primaryKey().notNull(),
  sport_id: integer("sport_id"),
  country_id: integer("country_id"),
  nationality_id: integer("nationality_id"),
  city_id: integer("city_id"),
  position_id: integer("position_id"),
  detailed_position_id: integer("detailed_position_id"),
  type_id: integer("type_id"),
  common_name: varchar("common_name"),
  firstname: varchar("firstname"),
  lastname: varchar("lastname"),
  name: varchar("name"),
  display_name: varchar("display_name"),
  image_path: varchar("image_path"),
  height: integer("height"),
  weight: integer("weight"),
  date_of_birth: date("date_of_birth"),
  gender: varchar("gender"),
});

export const countryInSportmonks = sportmonks.table("country", {
  id: serial("id").primaryKey().notNull(),
  continent_id: integer("continent_id"),
  name: varchar("name"),
  official_name: varchar("official_name"),
  fifa_name: varchar("fifa_name"),
  iso2: varchar("iso2"),
  iso3: varchar("iso3"),
  latitude: doublePrecision("latitude"),
  longitude: doublePrecision("longitude"),
  borders: varchar("borders").array(),
  image_path: varchar("image_path"),
});

export const refereeInSportmonks = sportmonks.table("referee", {
  id: serial("id").primaryKey().notNull(),
  sport_id: integer("sport_id"),
  country_id: integer("country_id"),
  common_name: varchar("common_name"),
  firstname: varchar("firstname"),
  lastname: varchar("lastname"),
  name: varchar("name"),
  display_name: varchar("display_name"),
  image_path: varchar("image_path"),
});

export const fixtureInDatahub = datahub.table("fixture", {
  id: integer("id"),
  home_team_name: varchar("home_team_name"),
  home_team_logo: text("home_team_logo"),
  away_team_name: varchar("away_team_name"),
  away_team_logo: text("away_team_logo"),
  venue_name: varchar("venue_name"),
  venue_city: varchar("venue_city"),
  venue_country: varchar("venue_country"),
  kickoff: timestamp("kickoff", { mode: "string" }),
  final_state: varchar("final_state"),
  competition: varchar("competition"),
  season: varchar("season"),
  stage: varchar("stage"),
  matchday: varchar("matchday"),
  sm_id: integer("sm_id"),
  sm_name: varchar("sm_name"),
  sm_sport_id: integer("sm_sport_id"),
  sm_starting_at: timestamp("sm_starting_at", { mode: "string" }),
  sm_result_info: varchar("sm_result_info"),
  sm_leg: varchar("sm_leg"),
  sm_length: integer("sm_length"),
  sm_placeholder: boolean("sm_placeholder"),
  sm_has_odds: boolean("sm_has_odds"),
  sm_starting_at_timestamp: integer("sm_starting_at_timestamp"),
  sm_participants: jsonb("sm_participants"),
  sm_home_team_id: integer("sm_home_team_id"),
  sm_home_team_sport_id: integer("sm_home_team_sport_id"),
  sm_home_team_country_id: integer("sm_home_team_country_id"),
  sm_home_team_venue_id: integer("sm_home_team_venue_id"),
  sm_home_team_gender: varchar("sm_home_team_gender"),
  sm_home_team_name: varchar("sm_home_team_name"),
  sm_home_team_short_code: varchar("sm_home_team_short_code"),
  sm_home_team_founded: integer("sm_home_team_founded"),
  sm_home_team_type: varchar("sm_home_team_type"),
  sm_home_team_placeholder: boolean("sm_home_team_placeholder"),
  sm_home_team_last_played_at: timestamp("sm_home_team_last_played_at", {
    mode: "string",
  }),
  sm_away_team_id: integer("sm_away_team_id"),
  sm_away_team_sport_id: integer("sm_away_team_sport_id"),
  sm_away_team_country_id: integer("sm_away_team_country_id"),
  sm_away_team_venue_id: integer("sm_away_team_venue_id"),
  sm_away_team_gender: varchar("sm_away_team_gender"),
  sm_away_team_name: varchar("sm_away_team_name"),
  sm_away_team_short_code: varchar("sm_away_team_short_code"),
  sm_away_team_founded: integer("sm_away_team_founded"),
  sm_away_team_type: varchar("sm_away_team_type"),
  sm_away_team_placeholder: boolean("sm_away_team_placeholder"),
  sm_away_team_last_played_at: timestamp("sm_away_team_last_played_at", {
    mode: "string",
  }),
  sm_venue_id: integer("sm_venue_id"),
  sm_venue_country_id: integer("sm_venue_country_id"),
  sm_venue_city_id: integer("sm_venue_city_id"),
  sm_venue_name: varchar("sm_venue_name"),
  sm_venue_address: varchar("sm_venue_address"),
  sm_venue_zipcode: varchar("sm_venue_zipcode"),
  sm_venue_latitude: doublePrecision("sm_venue_latitude"),
  sm_venue_longitude: doublePrecision("sm_venue_longitude"),
  sm_venue_capacity: integer("sm_venue_capacity"),
  sm_venue_city_name: varchar("sm_venue_city_name"),
  sm_venue_surface: varchar("sm_venue_surface"),
  sm_venue_national_team: boolean("sm_venue_national_team"),
  sm_venue_country_name: varchar("sm_venue_country_name"),
  sm_state_id: integer("sm_state_id"),
  sm_state_state: varchar("sm_state_state"),
  sm_state_name: varchar("sm_state_name"),
  sm_state_short_name: varchar("sm_state_short_name"),
  sm_state_developer_name: varchar("sm_state_developer_name"),
  sm_league_id: integer("sm_league_id"),
  sm_league_sport_id: integer("sm_league_sport_id"),
  sm_league_country_id: integer("sm_league_country_id"),
  sm_league_name: varchar("sm_league_name"),
  sm_league_active: boolean("sm_league_active"),
  sm_league_short_code: varchar("sm_league_short_code"),
  sm_league_type: varchar("sm_league_type"),
  sm_league_sub_type: varchar("sm_league_sub_type"),
  sm_league_last_played_at: timestamp("sm_league_last_played_at", {
    mode: "string",
  }),
  sm_league_category: integer("sm_league_category"),
  sm_league_has_jerseys: boolean("sm_league_has_jerseys"),
  sm_season_id: integer("sm_season_id"),
  sm_season_sport_id: integer("sm_season_sport_id"),
  sm_season_league_id: integer("sm_season_league_id"),
  sm_season_tie_breaker_rule_id: integer("sm_season_tie_breaker_rule_id"),
  sm_season_name: varchar("sm_season_name"),
  sm_season_finished: boolean("sm_season_finished"),
  sm_season_pending: boolean("sm_season_pending"),
  sm_season_is_current: boolean("sm_season_is_current"),
  sm_season_starting_at: date("sm_season_starting_at"),
  sm_season_ending_at: date("sm_season_ending_at"),
  sm_season_standings_recalculated_at: timestamp(
    "sm_season_standings_recalculated_at",
    { mode: "string" },
  ),
  sm_season_games_in_current_week: boolean("sm_season_games_in_current_week"),
  sm_round_id: integer("sm_round_id"),
  sm_round_sport_id: integer("sm_round_sport_id"),
  sm_round_league_id: integer("sm_round_league_id"),
  sm_round_season_id: integer("sm_round_season_id"),
  sm_round_stage_id: integer("sm_round_stage_id"),
  sm_round_name: varchar("sm_round_name"),
  sm_round_finished: boolean("sm_round_finished"),
  sm_round_is_current: boolean("sm_round_is_current"),
  sm_round_starting_at: date("sm_round_starting_at"),
  sm_round_ending_at: date("sm_round_ending_at"),
  sm_round_games_in_current_week: boolean("sm_round_games_in_current_week"),
  sm_stage_id: integer("sm_stage_id"),
  sm_stage_sport_id: integer("sm_stage_sport_id"),
  sm_stage_league_id: integer("sm_stage_league_id"),
  sm_stage_season_id: integer("sm_stage_season_id"),
  sm_stage_type_id: integer("sm_stage_type_id"),
  sm_stage_name: varchar("sm_stage_name"),
  sm_stage_sort_order: integer("sm_stage_sort_order"),
  sm_stage_finished: boolean("sm_stage_finished"),
  sm_stage_is_current: boolean("sm_stage_is_current"),
  sm_stage_starting_at: date("sm_stage_starting_at"),
  sm_stage_ending_at: date("sm_stage_ending_at"),
  sm_stage_games_in_current_week: boolean("sm_stage_games_in_current_week"),
  sm_home_team_image_path: text("sm_home_team_image_path"),
  sm_away_team_image_path: text("sm_away_team_image_path"),
  sm_venue_image_path: text("sm_venue_image_path"),
  sm_league_image_path: text("sm_league_image_path"),
  sts_id: varchar("sts_id"),
  sts_match_day_id: varchar("sts_match_day_id"),
  sts_match_day: varchar("sts_match_day"),
  sts_match_type: varchar("sts_match_type"),
  sts_season: varchar("sts_season"),
  sts_season_id: varchar("sts_season_id"),
  sts_stadium_name: varchar("sts_stadium_name"),
  sts_neutral_venue: boolean("sts_neutral_venue"),
  sts_match_date_fixed: boolean("sts_match_date_fixed"),
  sts_match_date_time_status: varchar("sts_match_date_time_status"),
  sts_planned_kickoff_time: varchar("sts_planned_kickoff_time"),
  sts_start_date: timestamp("sts_start_date", { mode: "string" }),
  sts_end_date: timestamp("sts_end_date", { mode: "string" }),
  sts_stadium_id: varchar("sts_stadium_id"),
  sts_stadium_uefa_name: varchar("sts_stadium_uefa_name"),
  sts_stadium_city: varchar("sts_stadium_city"),
  sts_stadium_country: varchar("sts_stadium_country"),
  sts_stadium_street: varchar("sts_stadium_street"),
  sts_stadium_postal_code: varchar("sts_stadium_postal_code"),
  sts_stadium_year_of_construction: integer("sts_stadium_year_of_construction"),
  sts_stadium_roofed_over: boolean("sts_stadium_roofed_over"),
  sts_competition_id: varchar("sts_competition_id"),
  sts_competition_name: varchar("sts_competition_name"),
  sts_competition_country: varchar("sts_competition_country"),
  sts_competition_host: varchar("sts_competition_host"),
  sts_competition_type: varchar("sts_competition_type"),
  sts_competition_sport: varchar("sts_competition_sport"),
  sts_home_team_id: varchar("sts_home_team_id"),
  sts_home_team_name: varchar("sts_home_team_name"),
  sts_home_team_three_letter_code: varchar("sts_home_team_three_letter_code"),
  sts_home_team_competition_id: varchar("sts_home_team_competition_id"),
  sts_home_team_competition_name: varchar("sts_home_team_competition_name"),
  sts_home_team_season_id: varchar("sts_home_team_season_id"),
  sts_home_team_season: varchar("sts_home_team_season"),
  sts_home_team_stadium_id: varchar("sts_home_team_stadium_id"),
  sts_home_team_stadium_name: varchar("sts_home_team_stadium_name"),
  sts_home_team_long_name: varchar("sts_home_team_long_name"),
  sts_home_team_short_name: varchar("sts_home_team_short_name"),
  sts_home_team_long_name_english: varchar("sts_home_team_long_name_english"),
  sts_home_team_short_name_english: varchar("sts_home_team_short_name_english"),
  sts_home_team_color_one: varchar("sts_home_team_color_one"),
  sts_home_team_color_one_rgb: varchar("sts_home_team_color_one_rgb"),
  sts_home_team_color_two: varchar("sts_home_team_color_two"),
  sts_home_team_color_two_rgb: varchar("sts_home_team_color_two_rgb"),
  sts_home_team_founded: integer("sts_home_team_founded"),
  sts_home_team_street: varchar("sts_home_team_street"),
  sts_home_team_house_number: varchar("sts_home_team_house_number"),
  sts_home_team_postal_code: varchar("sts_home_team_postal_code"),
  sts_home_team_city: varchar("sts_home_team_city"),
  sts_home_team_country: varchar("sts_home_team_country"),
  sts_home_team_phone: varchar("sts_home_team_phone"),
  sts_home_team_fax: varchar("sts_home_team_fax"),
  sts_home_team_mail: varchar("sts_home_team_mail"),
  sts_home_team_website: varchar("sts_home_team_website"),
  sts_home_team_shirt_one_main_color: varchar(
    "sts_home_team_shirt_one_main_color",
  ),
  sts_home_team_shirt_one_main_color_rgb: varchar(
    "sts_home_team_shirt_one_main_color_rgb",
  ),
  sts_home_team_shirt_one_secondary_color: varchar(
    "sts_home_team_shirt_one_secondary_color",
  ),
  sts_home_team_shirt_one_secondary_color_rgb: varchar(
    "sts_home_team_shirt_one_secondary_color_rgb",
  ),
  sts_home_team_shirt_one_number_color: varchar(
    "sts_home_team_shirt_one_number_color",
  ),
  sts_home_team_shirt_one_number_color_rgb: varchar(
    "sts_home_team_shirt_one_number_color_rgb",
  ),
  sts_home_team_shirt_two_main_color: varchar(
    "sts_home_team_shirt_two_main_color",
  ),
  sts_home_team_shirt_two_main_color_rgb: varchar(
    "sts_home_team_shirt_two_main_color_rgb",
  ),
  sts_home_team_shirt_two_secondary_color: varchar(
    "sts_home_team_shirt_two_secondary_color",
  ),
  sts_home_team_shirt_two_secondary_color_rgb: varchar(
    "sts_home_team_shirt_two_secondary_color_rgb",
  ),
  sts_home_team_shirt_two_number_color: varchar(
    "sts_home_team_shirt_two_number_color",
  ),
  sts_home_team_shirt_two_number_color_rgb: varchar(
    "sts_home_team_shirt_two_number_color_rgb",
  ),
  sts_home_team_shirt_three_main_color: varchar(
    "sts_home_team_shirt_three_main_color",
  ),
  sts_home_team_shirt_three_main_color_rgb: varchar(
    "sts_home_team_shirt_three_main_color_rgb",
  ),
  sts_home_team_shirt_three_secondary_color: varchar(
    "sts_home_team_shirt_three_secondary_color",
  ),
  sts_home_team_shirt_three_secondary_color_rgb: varchar(
    "sts_home_team_shirt_three_secondary_color_rgb",
  ),
  sts_home_team_shirt_three_number_color: varchar(
    "sts_home_team_shirt_three_number_color",
  ),
  sts_home_team_shirt_three_number_color_rgb: varchar(
    "sts_home_team_shirt_three_number_color_rgb",
  ),
  sts_guest_team_id: varchar("sts_guest_team_id"),
  sts_guest_team_name: varchar("sts_guest_team_name"),
  sts_guest_team_three_letter_code: varchar("sts_guest_team_three_letter_code"),
  sts_guest_team_competition_id: varchar("sts_guest_team_competition_id"),
  sts_guest_team_competition_name: varchar("sts_guest_team_competition_name"),
  sts_guest_team_season_id: varchar("sts_guest_team_season_id"),
  sts_guest_team_season: varchar("sts_guest_team_season"),
  sts_guest_team_stadium_id: varchar("sts_guest_team_stadium_id"),
  sts_guest_team_stadium_name: varchar("sts_guest_team_stadium_name"),
  sts_guest_team_long_name: varchar("sts_guest_team_long_name"),
  sts_guest_team_short_name: varchar("sts_guest_team_short_name"),
  sts_guest_team_long_name_english: varchar("sts_guest_team_long_name_english"),
  sts_guest_team_short_name_english: varchar(
    "sts_guest_team_short_name_english",
  ),
  sts_guest_team_color_one: varchar("sts_guest_team_color_one"),
  sts_guest_team_color_one_rgb: varchar("sts_guest_team_color_one_rgb"),
  sts_guest_team_color_two: varchar("sts_guest_team_color_two"),
  sts_guest_team_color_two_rgb: varchar("sts_guest_team_color_two_rgb"),
  sts_guest_team_founded: integer("sts_guest_team_founded"),
  sts_guest_team_street: varchar("sts_guest_team_street"),
  sts_guest_team_house_number: varchar("sts_guest_team_house_number"),
  sts_guest_team_postal_code: varchar("sts_guest_team_postal_code"),
  sts_guest_team_city: varchar("sts_guest_team_city"),
  sts_guest_team_country: varchar("sts_guest_team_country"),
  sts_guest_team_phone: varchar("sts_guest_team_phone"),
  sts_guest_team_fax: varchar("sts_guest_team_fax"),
  sts_guest_team_mail: varchar("sts_guest_team_mail"),
  sts_guest_team_website: varchar("sts_guest_team_website"),
  sts_guest_team_shirt_one_main_color: varchar(
    "sts_guest_team_shirt_one_main_color",
  ),
  sts_guest_team_shirt_one_main_color_rgb: varchar(
    "sts_guest_team_shirt_one_main_color_rgb",
  ),
  sts_guest_team_shirt_one_secondary_color: varchar(
    "sts_guest_team_shirt_one_secondary_color",
  ),
  sts_guest_team_shirt_one_secondary_color_rgb: varchar(
    "sts_guest_team_shirt_one_secondary_color_rgb",
  ),
  sts_guest_team_shirt_one_number_color: varchar(
    "sts_guest_team_shirt_one_number_color",
  ),
  sts_guest_team_shirt_one_number_color_rgb: varchar(
    "sts_guest_team_shirt_one_number_color_rgb",
  ),
  sts_guest_team_shirt_two_main_color: varchar(
    "sts_guest_team_shirt_two_main_color",
  ),
  sts_guest_team_shirt_two_main_color_rgb: varchar(
    "sts_guest_team_shirt_two_main_color_rgb",
  ),
  sts_guest_team_shirt_two_secondary_color: varchar(
    "sts_guest_team_shirt_two_secondary_color",
  ),
  sts_guest_team_shirt_two_secondary_color_rgb: varchar(
    "sts_guest_team_shirt_two_secondary_color_rgb",
  ),
  sts_guest_team_shirt_two_number_color: varchar(
    "sts_guest_team_shirt_two_number_color",
  ),
  sts_guest_team_shirt_two_number_color_rgb: varchar(
    "sts_guest_team_shirt_two_number_color_rgb",
  ),
  sts_guest_team_shirt_three_main_color: varchar(
    "sts_guest_team_shirt_three_main_color",
  ),
  sts_guest_team_shirt_three_main_color_rgb: varchar(
    "sts_guest_team_shirt_three_main_color_rgb",
  ),
  sts_guest_team_shirt_three_secondary_color: varchar(
    "sts_guest_team_shirt_three_secondary_color",
  ),
  sts_guest_team_shirt_three_secondary_color_rgb: varchar(
    "sts_guest_team_shirt_three_secondary_color_rgb",
  ),
  sts_guest_team_shirt_three_number_color: varchar(
    "sts_guest_team_shirt_three_number_color",
  ),
  sts_guest_team_shirt_three_number_color_rgb: varchar(
    "sts_guest_team_shirt_three_number_color_rgb",
  ),
  name: text("name"),
});

export const regionInSportmonks = sportmonks.table("region", {
  id: serial("id").primaryKey().notNull(),
  country_id: integer("country_id"),
  name: varchar("name"),
});

export const stageInSportmonks = sportmonks.table("stage", {
  id: serial("id").primaryKey().notNull(),
  sport_id: integer("sport_id"),
  league_id: integer("league_id"),
  season_id: integer("season_id"),
  type_id: integer("type_id"),
  name: varchar("name"),
  sort_order: integer("sort_order"),
  finished: boolean("finished"),
  is_current: boolean("is_current"),
  starting_at: date("starting_at"),
  ending_at: date("ending_at"),
  games_in_current_week: boolean("games_in_current_week"),
});

export const stateInSportmonks = sportmonks.table("state", {
  id: serial("id").primaryKey().notNull(),
  state: varchar("state"),
  name: varchar("name"),
  short_name: varchar("short_name"),
  developer_name: varchar("developer_name"),
});

export const typeInSportmonks = sportmonks.table("type", {
  id: serial("id").primaryKey().notNull(),
  name: varchar("name"),
  code: varchar("code"),
  developer_name: varchar("developer_name"),
  model_type: varchar("model_type"),
  stat_group: varchar("stat_group"),
});

export const match_dayInSts = sts.table("match_day", {
  id: varchar("id").primaryKey().notNull(),
  match_day: varchar("match_day").notNull(),
  season_id: varchar("season_id").notNull(),
  season: varchar("season").notNull(),
});

export const refereeInSts = sts.table("referee", {
  id: varchar("id").primaryKey().notNull(),
  name: varchar("name").notNull(),
  first_name: varchar("first_name").notNull(),
  last_name: varchar("last_name").notNull(),
  short_name: varchar("short_name").notNull(),
  nationality_german: varchar("nationality_german"),
  nationality_english: varchar("nationality_english"),
  nationality_spanish: varchar("nationality_spanish"),
  height: integer("height"),
  weight: integer("weight"),
  birth_date: date("birth_date"),
  leave_date: date("leave_date"),
  birth_place: varchar("birth_place"),
});

export const countryInDatahub = datahub.table("country", {
  id: serial("id").primaryKey().notNull(),
  alpha2: varchar("alpha2", { length: 2 }).notNull(),
  alpha3: varchar("alpha3", { length: 3 }).notNull(),
  ar: varchar("ar", { length: 75 }).notNull(),
  bg: varchar("bg", { length: 75 }).notNull(),
  cs: varchar("cs", { length: 75 }).notNull(),
  da: varchar("da", { length: 75 }).notNull(),
  de: varchar("de", { length: 75 }).notNull(),
  el: varchar("el", { length: 75 }).notNull(),
  en: varchar("en", { length: 75 }).notNull(),
  eo: varchar("eo", { length: 75 }).notNull(),
  es: varchar("es", { length: 75 }).notNull(),
  et: varchar("et", { length: 75 }).notNull(),
  eu: varchar("eu", { length: 75 }).notNull(),
  fa: varchar("fa", { length: 75 }).notNull(),
  fi: varchar("fi", { length: 75 }).notNull(),
  fr: varchar("fr", { length: 75 }).notNull(),
  hr: varchar("hr", { length: 75 }).notNull(),
  hu: varchar("hu", { length: 75 }).notNull(),
  hy: varchar("hy", { length: 75 }).notNull(),
  it: varchar("it", { length: 75 }).notNull(),
  ja: varchar("ja", { length: 75 }).notNull(),
  ko: varchar("ko", { length: 75 }).notNull(),
  lt: varchar("lt", { length: 75 }).notNull(),
  nl: varchar("nl", { length: 75 }).notNull(),
  no: varchar("no", { length: 75 }).notNull(),
  pl: varchar("pl", { length: 75 }).notNull(),
  pt: varchar("pt", { length: 75 }).notNull(),
  ro: varchar("ro", { length: 75 }).notNull(),
  ru: varchar("ru", { length: 75 }).notNull(),
  sk: varchar("sk", { length: 75 }).notNull(),
  sl: varchar("sl", { length: 75 }).notNull(),
  sr: varchar("sr", { length: 75 }).notNull(),
  sv: varchar("sv", { length: 75 }).notNull(),
  th: varchar("th", { length: 75 }).notNull(),
  uk: varchar("uk", { length: 75 }).notNull(),
  zh: varchar("zh", { length: 75 }).notNull(),
  zh_tw: varchar("zh_tw", { length: 75 }).notNull(),
});

export const venueInSportmonks = sportmonks.table("venue", {
  id: serial("id").primaryKey().notNull(),
  country_id: integer("country_id"),
  city_id: integer("city_id"),
  name: varchar("name"),
  address: varchar("address"),
  zipcode: varchar("zipcode"),
  latitude: doublePrecision("latitude"),
  longitude: doublePrecision("longitude"),
  capacity: integer("capacity"),
  image_path: varchar("image_path"),
  city_name: varchar("city_name"),
  surface: varchar("surface"),
  national_team: boolean("national_team"),
});

export const stadiumInSts = sts.table("stadium", {
  id: varchar("id").primaryKey().notNull(),
  name: varchar("name").notNull(),
  uefa_name: varchar("uefa_name"),
  city: varchar("city").notNull(),
  country: varchar("country"),
  street: varchar("street"),
  postal_code: varchar("postal_code"),
  year_of_construction: integer("year_of_construction"),
  roofed_over: boolean("roofed_over"),
});

export const leagueInSportmonks = sportmonks.table("league", {
  id: serial("id").primaryKey().notNull(),
  sport_id: integer("sport_id"),
  country_id: integer("country_id"),
  name: varchar("name"),
  active: boolean("active"),
  short_code: varchar("short_code"),
  image_path: varchar("image_path"),
  type: varchar("type"),
  sub_type: varchar("sub_type"),
  last_played_at: timestamp("last_played_at", { mode: "string" }),
  category: integer("category"),
  has_jerseys: boolean("has_jerseys"),
});

export const competition_mappingInDatahub = datahub.table(
  "competition_mapping",
  {
    id: serial("id").primaryKey().notNull(),
    sportmonks_id: integer("sportmonks_id").references(
      () => leagueInSportmonks.id,
    ),
    sts_id: varchar("sts_id").references(() => competitionInSts.id),
  },
);

export const competitionInSts = sts.table("competition", {
  id: varchar("id").primaryKey().notNull(),
  name: varchar("name").notNull(),
  country: varchar("country"),
  host: varchar("host"),
  type: varchar("type"),
  sport: varchar("sport"),
});

export const seasonInSportmonks = sportmonks.table("season", {
  id: serial("id").primaryKey().notNull(),
  sport_id: integer("sport_id"),
  league_id: integer("league_id"),
  tie_breaker_rule_id: integer("tie_breaker_rule_id"),
  name: varchar("name"),
  finished: boolean("finished"),
  pending: boolean("pending"),
  is_current: boolean("is_current"),
  starting_at: date("starting_at"),
  ending_at: date("ending_at"),
  standings_recalculated_at: timestamp("standings_recalculated_at", {
    mode: "string",
  }),
  games_in_current_week: boolean("games_in_current_week"),
});

export const seasonInSts = sts.table("season", {
  id: varchar("id").primaryKey().notNull(),
  season: varchar("season").notNull(),
});

export const fixtureInSportmonks = sportmonks.table("fixture", {
  id: serial("id").primaryKey().notNull(),
  sport_id: integer("sport_id"),
  league_id: integer("league_id"),
  season_id: integer("season_id"),
  stage_id: integer("stage_id"),
  round_id: integer("round_id"),
  state_id: integer("state_id"),
  venue_id: integer("venue_id"),
  name: varchar("name"),
  starting_at: timestamp("starting_at", { mode: "string" }),
  result_info: varchar("result_info"),
  leg: varchar("leg"),
  length: integer("length"),
  placeholder: boolean("placeholder"),
  has_odds: boolean("has_odds"),
  starting_at_timestamp: integer("starting_at_timestamp"),
  participants: jsonb("participants"),
});

export const fixtureInSts = sts.table("fixture", {
  id: varchar("id").primaryKey().notNull(),
  competition_id: varchar("competition_id").notNull(),
  competition_name: varchar("competition_name").notNull(),
  competition_type: varchar("competition_type").notNull(),
  match_day_id: varchar("match_day_id").notNull(),
  match_day: varchar("match_day").notNull(),
  match_type: varchar("match_type").notNull(),
  season: varchar("season").notNull(),
  season_id: varchar("season_id").notNull(),
  stadium_name: varchar("stadium_name").notNull(),
  stadium_id: varchar("stadium_id").notNull(),
  neutral_venue: boolean("neutral_venue"),
  home_team_name: varchar("home_team_name").notNull(),
  home_team_id: varchar("home_team_id").notNull(),
  guest_team_name: varchar("guest_team_name").notNull(),
  guest_team_id: varchar("guest_team_id").notNull(),
  match_date_fixed: boolean("match_date_fixed").notNull(),
  match_date_time_status: varchar("match_date_time_status"),
  planned_kickoff_time: varchar("planned_kickoff_time").notNull(),
  start_date: timestamp("start_date", { mode: "string" }).notNull(),
  end_date: timestamp("end_date", { mode: "string" }),
});

export const teamInSportmonks = sportmonks.table("team", {
  id: serial("id").primaryKey().notNull(),
  sport_id: integer("sport_id"),
  country_id: integer("country_id"),
  venue_id: integer("venue_id"),
  gender: varchar("gender"),
  name: varchar("name"),
  short_code: varchar("short_code"),
  image_path: varchar("image_path"),
  founded: integer("founded"),
  type: varchar("type"),
  placeholder: boolean("placeholder"),
  last_played_at: timestamp("last_played_at", { mode: "string" }),
});

export const roundInSportmonks = sportmonks.table("round", {
  id: serial("id").primaryKey().notNull(),
  sport_id: integer("sport_id"),
  league_id: integer("league_id"),
  season_id: integer("season_id"),
  stage_id: integer("stage_id"),
  name: varchar("name"),
  finished: boolean("finished"),
  is_current: boolean("is_current"),
  starting_at: date("starting_at"),
  ending_at: date("ending_at"),
  games_in_current_week: boolean("games_in_current_week"),
});

export const stg_sportmonks__refereeInDatahub = datahub.table(
  "stg_sportmonks__referee",
  {
    id: integer("id"),
    sport_id: integer("sport_id"),
    country_id: integer("country_id"),
    common_name: varchar("common_name"),
    firstname: varchar("firstname"),
    lastname: varchar("lastname"),
    name: varchar("name"),
    display_name: varchar("display_name"),
    image_path: varchar("image_path"),
  },
);

export const int_datahub_venue_mapping_detailedInDatahub = datahub.table(
  "int_datahub_venue_mapping_detailed",
  {
    stadium_id: varchar("stadium_id"),
    stadium_name: varchar("stadium_name"),
    venue_id: integer("venue_id"),
    venue_name: varchar("venue_name"),
    city_name: varchar("city_name"),
    country_name: varchar("country_name"),
    leven_distance: integer("leven_distance"),
    leven_similarity: doublePrecision("leven_similarity"),
    trigram_similarity: real("trigram_similarity"),
    city_leven_distance: integer("city_leven_distance"),
    city_leven_similarity: doublePrecision("city_leven_similarity"),
    city_trigram_similarity: real("city_trigram_similarity"),
    is_substring: integer("is_substring"),
    city_is_substring: integer("city_is_substring"),
  },
);

export const season_mappingInDatahub = datahub.table("season_mapping", {
  name: varchar("name"),
  sts_competition_id: varchar("sts_competition_id"),
  sts_season_id: varchar("sts_season_id"),
  sportmonks_id: integer("sportmonks_id"),
});

export const stg_sts__match_dayInDatahub = datahub.table("stg_sts__match_day", {
  id: varchar("id"),
  match_day: varchar("match_day"),
  season_id: varchar("season_id"),
  season: varchar("season"),
});

export const stg_sportmonks__cityInDatahub = datahub.table(
  "stg_sportmonks__city",
  {
    id: integer("id"),
    country_id: integer("country_id"),
    region_id: integer("region_id"),
    name: varchar("name"),
    latitude: varchar("latitude"),
    longitude: varchar("longitude"),
  },
);

export const stg_sportmonks__roundInDatahub = datahub.table(
  "stg_sportmonks__round",
  {
    id: integer("id"),
    sport_id: integer("sport_id"),
    league_id: integer("league_id"),
    season_id: integer("season_id"),
    stage_id: integer("stage_id"),
    name: varchar("name"),
    finished: boolean("finished"),
    is_current: boolean("is_current"),
    starting_at: date("starting_at"),
    ending_at: date("ending_at"),
    games_in_current_week: boolean("games_in_current_week"),
  },
);

export const stg_sportmonks__coachInDatahub = datahub.table(
  "stg_sportmonks__coach",
  {
    id: integer("id"),
    player_id: integer("player_id"),
    sport_id: integer("sport_id"),
    country_id: integer("country_id"),
    nationality_id: integer("nationality_id"),
    common_name: varchar("common_name"),
    firstname: varchar("firstname"),
    lastname: varchar("lastname"),
    name: varchar("name"),
    display_name: varchar("display_name"),
    image_path: varchar("image_path"),
    height: integer("height"),
    weight: integer("weight"),
    date_of_birth: date("date_of_birth"),
    gender: varchar("gender"),
  },
);

export const stg_sportmonks__continentInDatahub = datahub.table(
  "stg_sportmonks__continent",
  {
    id: integer("id"),
    name: varchar("name"),
    code: varchar("code"),
  },
);

export const stg_sportmonks__venueInDatahub = datahub.table(
  "stg_sportmonks__venue",
  {
    id: integer("id"),
    country_id: integer("country_id"),
    city_id: integer("city_id"),
    name: varchar("name"),
    address: varchar("address"),
    zipcode: varchar("zipcode"),
    latitude: doublePrecision("latitude"),
    longitude: doublePrecision("longitude"),
    capacity: integer("capacity"),
    image_path: varchar("image_path"),
    city_name: varchar("city_name"),
    surface: varchar("surface"),
    national_team: boolean("national_team"),
  },
);

export const stg_sportmonks__countryInDatahub = datahub.table(
  "stg_sportmonks__country",
  {
    id: integer("id"),
    continent_id: integer("continent_id"),
    name: varchar("name"),
    official_name: varchar("official_name"),
    fifa_name: varchar("fifa_name"),
    iso2: varchar("iso2"),
    iso3: varchar("iso3"),
    latitude: doublePrecision("latitude"),
    longitude: doublePrecision("longitude"),
    borders: varchar("borders"),
    image_path: varchar("image_path"),
  },
);

export const stg_sportmonks__seasonInDatahub = datahub.table(
  "stg_sportmonks__season",
  {
    id: integer("id"),
    sport_id: integer("sport_id"),
    league_id: integer("league_id"),
    tie_breaker_rule_id: integer("tie_breaker_rule_id"),
    name: varchar("name"),
    finished: boolean("finished"),
    pending: boolean("pending"),
    is_current: boolean("is_current"),
    starting_at: date("starting_at"),
    ending_at: date("ending_at"),
    standings_recalculated_at: timestamp("standings_recalculated_at", {
      mode: "string",
    }),
    games_in_current_week: boolean("games_in_current_week"),
  },
);

export const stg_sportmonks__fixtureInDatahub = datahub.table(
  "stg_sportmonks__fixture",
  {
    id: integer("id"),
    sport_id: integer("sport_id"),
    league_id: integer("league_id"),
    season_id: integer("season_id"),
    stage_id: integer("stage_id"),
    round_id: integer("round_id"),
    state_id: integer("state_id"),
    venue_id: integer("venue_id"),
    name: varchar("name"),
    starting_at: timestamp("starting_at", { mode: "string" }),
    result_info: varchar("result_info"),
    leg: varchar("leg"),
    length: integer("length"),
    placeholder: boolean("placeholder"),
    has_odds: boolean("has_odds"),
    starting_at_timestamp: integer("starting_at_timestamp"),
    participants: jsonb("participants"),
  },
);

export const stg_sportmonks__leagueInDatahub = datahub.table(
  "stg_sportmonks__league",
  {
    id: integer("id"),
    sport_id: integer("sport_id"),
    country_id: integer("country_id"),
    name: varchar("name"),
    active: boolean("active"),
    short_code: varchar("short_code"),
    image_path: varchar("image_path"),
    type: varchar("type"),
    sub_type: varchar("sub_type"),
    last_played_at: timestamp("last_played_at", { mode: "string" }),
    category: integer("category"),
    has_jerseys: boolean("has_jerseys"),
  },
);

export const stg_sportmonks__playerInDatahub = datahub.table(
  "stg_sportmonks__player",
  {
    id: integer("id"),
    sport_id: integer("sport_id"),
    country_id: integer("country_id"),
    nationality_id: integer("nationality_id"),
    city_id: integer("city_id"),
    position_id: integer("position_id"),
    detailed_position_id: integer("detailed_position_id"),
    type_id: integer("type_id"),
    common_name: varchar("common_name"),
    firstname: varchar("firstname"),
    lastname: varchar("lastname"),
    name: varchar("name"),
    display_name: varchar("display_name"),
    image_path: varchar("image_path"),
    height: integer("height"),
    weight: integer("weight"),
    date_of_birth: date("date_of_birth"),
    gender: varchar("gender"),
  },
);

export const stg_sportmonks__stageInDatahub = datahub.table(
  "stg_sportmonks__stage",
  {
    id: integer("id"),
    sport_id: integer("sport_id"),
    league_id: integer("league_id"),
    season_id: integer("season_id"),
    type_id: integer("type_id"),
    name: varchar("name"),
    sort_order: integer("sort_order"),
    finished: boolean("finished"),
    is_current: boolean("is_current"),
    starting_at: date("starting_at"),
    ending_at: date("ending_at"),
    games_in_current_week: boolean("games_in_current_week"),
  },
);

export const stg_sts__clubInDatahub = datahub.table("stg_sts__club", {
  id: varchar("id"),
  name: varchar("name"),
  three_letter_code: varchar("three_letter_code"),
  competition_id: varchar("competition_id"),
  competition_name: varchar("competition_name"),
  season_id: varchar("season_id"),
  season: varchar("season"),
  stadium_id: varchar("stadium_id"),
  stadium_name: varchar("stadium_name"),
  long_name: varchar("long_name"),
  short_name: varchar("short_name"),
  long_name_english: varchar("long_name_english"),
  short_name_english: varchar("short_name_english"),
  color_one: varchar("color_one"),
  color_one_rgb: varchar("color_one_rgb"),
  color_two: varchar("color_two"),
  color_two_rgb: varchar("color_two_rgb"),
  founded: integer("founded"),
  street: varchar("street"),
  house_number: varchar("house_number"),
  postal_code: varchar("postal_code"),
  city: varchar("city"),
  country: varchar("country"),
  phone: varchar("phone"),
  fax: varchar("fax"),
  mail: varchar("mail"),
  website: varchar("website"),
  shirt_one_main_color: varchar("shirt_one_main_color"),
  shirt_one_main_color_rgb: varchar("shirt_one_main_color_rgb"),
  shirt_one_secondary_color: varchar("shirt_one_secondary_color"),
  shirt_one_secondary_color_rgb: varchar("shirt_one_secondary_color_rgb"),
  shirt_one_number_color: varchar("shirt_one_number_color"),
  shirt_one_number_color_rgb: varchar("shirt_one_number_color_rgb"),
  shirt_two_main_color: varchar("shirt_two_main_color"),
  shirt_two_main_color_rgb: varchar("shirt_two_main_color_rgb"),
  shirt_two_secondary_color: varchar("shirt_two_secondary_color"),
  shirt_two_secondary_color_rgb: varchar("shirt_two_secondary_color_rgb"),
  shirt_two_number_color: varchar("shirt_two_number_color"),
  shirt_two_number_color_rgb: varchar("shirt_two_number_color_rgb"),
  shirt_three_main_color: varchar("shirt_three_main_color"),
  shirt_three_main_color_rgb: varchar("shirt_three_main_color_rgb"),
  shirt_three_secondary_color: varchar("shirt_three_secondary_color"),
  shirt_three_secondary_color_rgb: varchar("shirt_three_secondary_color_rgb"),
  shirt_three_number_color: varchar("shirt_three_number_color"),
  shirt_three_number_color_rgb: varchar("shirt_three_number_color_rgb"),
});

export const stg_sportmonks__stateInDatahub = datahub.table(
  "stg_sportmonks__state",
  {
    id: integer("id"),
    state: varchar("state"),
    name: varchar("name"),
    short_name: varchar("short_name"),
    developer_name: varchar("developer_name"),
  },
);

export const stg_sportmonks__teamInDatahub = datahub.table(
  "stg_sportmonks__team",
  {
    id: integer("id"),
    sport_id: integer("sport_id"),
    country_id: integer("country_id"),
    venue_id: integer("venue_id"),
    gender: varchar("gender"),
    name: varchar("name"),
    short_code: varchar("short_code"),
    image_path: varchar("image_path"),
    founded: integer("founded"),
    type: varchar("type"),
    placeholder: boolean("placeholder"),
    last_played_at: timestamp("last_played_at", { mode: "string" }),
  },
);

export const stg_sportmonks__typeInDatahub = datahub.table(
  "stg_sportmonks__type",
  {
    id: integer("id"),
    name: varchar("name"),
    code: varchar("code"),
    developer_name: varchar("developer_name"),
    model_type: varchar("model_type"),
    stat_group: varchar("stat_group"),
  },
);

export const stg_sts__playerInDatahub = datahub.table("stg_sts__player", {
  id: varchar("id"),
  club_id: varchar("club_id"),
  club_name: varchar("club_name"),
  name: varchar("name"),
  first_name: varchar("first_name"),
  last_name: varchar("last_name"),
  short_name: varchar("short_name"),
  alias: varchar("alias"),
  birth_date: date("birth_date"),
  birth_place: varchar("birth_place"),
  country_of_birth_german: varchar("country_of_birth_german"),
  country_of_birth_english: varchar("country_of_birth_english"),
  country_of_birth_spanish: varchar("country_of_birth_spanish"),
  nationality_german: varchar("nationality_german"),
  nationality_english: varchar("nationality_english"),
  nationality_spanish: varchar("nationality_spanish"),
  additional_nationality_one_german: varchar(
    "additional_nationality_one_german",
  ),
  additional_nationality_one_english: varchar(
    "additional_nationality_one_english",
  ),
  additional_nationality_one_spanish: varchar(
    "additional_nationality_one_spanish",
  ),
  height: integer("height"),
  weight: integer("weight"),
  shirt_number: integer("shirt_number"),
  primary_pool: boolean("primary_pool"),
  playing_position_german: varchar("playing_position_german"),
  playing_position_english: varchar("playing_position_english"),
  playing_position_spanish: varchar("playing_position_spanish"),
  local_player: varchar("local_player"),
  season_id: varchar("season_id"),
  season: varchar("season"),
});

export const stg_sts__competitionInDatahub = datahub.table(
  "stg_sts__competition",
  {
    id: varchar("id"),
    name: varchar("name"),
    country: varchar("country"),
    host: varchar("host"),
    type: varchar("type"),
    sport: varchar("sport"),
  },
);

export const stg_sts__fixtureInDatahub = datahub.table("stg_sts__fixture", {
  id: varchar("id"),
  competition_id: varchar("competition_id"),
  competition_name: varchar("competition_name"),
  competition_type: varchar("competition_type"),
  match_day_id: varchar("match_day_id"),
  match_day: varchar("match_day"),
  match_type: varchar("match_type"),
  season: varchar("season"),
  season_id: varchar("season_id"),
  stadium_name: varchar("stadium_name"),
  stadium_id: varchar("stadium_id"),
  neutral_venue: boolean("neutral_venue"),
  home_team_name: varchar("home_team_name"),
  home_team_id: varchar("home_team_id"),
  guest_team_name: varchar("guest_team_name"),
  guest_team_id: varchar("guest_team_id"),
  match_date_fixed: boolean("match_date_fixed"),
  match_date_time_status: varchar("match_date_time_status"),
  planned_kickoff_time: varchar("planned_kickoff_time"),
  start_date: timestamp("start_date", { mode: "string" }),
  end_date: timestamp("end_date", { mode: "string" }),
});

export const stg_sts__stadiumInDatahub = datahub.table("stg_sts__stadium", {
  id: varchar("id"),
  name: varchar("name"),
  uefa_name: varchar("uefa_name"),
  city: varchar("city"),
  country: varchar("country"),
  street: varchar("street"),
  postal_code: varchar("postal_code"),
  year_of_construction: integer("year_of_construction"),
  roofed_over: boolean("roofed_over"),
});

export const stg_sts__refereeInDatahub = datahub.table("stg_sts__referee", {
  id: varchar("id"),
  name: varchar("name"),
  first_name: varchar("first_name"),
  last_name: varchar("last_name"),
  short_name: varchar("short_name"),
  nationality_german: varchar("nationality_german"),
  nationality_english: varchar("nationality_english"),
  nationality_spanish: varchar("nationality_spanish"),
  height: integer("height"),
  weight: integer("weight"),
  birth_date: date("birth_date"),
  leave_date: date("leave_date"),
  birth_place: varchar("birth_place"),
});

export const stg_sts__seasonInDatahub = datahub.table("stg_sts__season", {
  id: varchar("id"),
  season: varchar("season"),
});

export const int_sportmonks__fixtureInDatahub = datahub.table(
  "int_sportmonks__fixture",
  {
    id: integer("id"),
    name: varchar("name"),
    sport_id: integer("sport_id"),
    starting_at: timestamp("starting_at", { mode: "string" }),
    result_info: varchar("result_info"),
    leg: varchar("leg"),
    length: integer("length"),
    placeholder: boolean("placeholder"),
    has_odds: boolean("has_odds"),
    starting_at_timestamp: integer("starting_at_timestamp"),
    participants: jsonb("participants"),
    home_team_id: integer("home_team_id"),
    home_team_sport_id: integer("home_team_sport_id"),
    home_team_country_id: integer("home_team_country_id"),
    home_team_venue_id: integer("home_team_venue_id"),
    home_team_gender: varchar("home_team_gender"),
    home_team_name: varchar("home_team_name"),
    home_team_short_code: varchar("home_team_short_code"),
    home_team_image_path: varchar("home_team_image_path"),
    home_team_founded: integer("home_team_founded"),
    home_team_type: varchar("home_team_type"),
    home_team_placeholder: boolean("home_team_placeholder"),
    home_team_last_played_at: timestamp("home_team_last_played_at", {
      mode: "string",
    }),
    away_team_id: integer("away_team_id"),
    away_team_sport_id: integer("away_team_sport_id"),
    away_team_country_id: integer("away_team_country_id"),
    away_team_venue_id: integer("away_team_venue_id"),
    away_team_gender: varchar("away_team_gender"),
    away_team_name: varchar("away_team_name"),
    away_team_short_code: varchar("away_team_short_code"),
    away_team_image_path: varchar("away_team_image_path"),
    away_team_founded: integer("away_team_founded"),
    away_team_type: varchar("away_team_type"),
    away_team_placeholder: boolean("away_team_placeholder"),
    away_team_last_played_at: timestamp("away_team_last_played_at", {
      mode: "string",
    }),
    venue_id: integer("venue_id"),
    venue_country_id: integer("venue_country_id"),
    venue_city_id: integer("venue_city_id"),
    venue_name: varchar("venue_name"),
    venue_address: varchar("venue_address"),
    venue_zipcode: varchar("venue_zipcode"),
    venue_latitude: doublePrecision("venue_latitude"),
    venue_longitude: doublePrecision("venue_longitude"),
    venue_capacity: integer("venue_capacity"),
    venue_image_path: varchar("venue_image_path"),
    venue_city_name: varchar("venue_city_name"),
    venue_surface: varchar("venue_surface"),
    venue_national_team: boolean("venue_national_team"),
    venue_country_name: varchar("venue_country_name"),
    state_id: integer("state_id"),
    state_state: varchar("state_state"),
    state_name: varchar("state_name"),
    state_short_name: varchar("state_short_name"),
    state_developer_name: varchar("state_developer_name"),
    league_id: integer("league_id"),
    league_sport_id: integer("league_sport_id"),
    league_country_id: integer("league_country_id"),
    league_name: varchar("league_name"),
    league_active: boolean("league_active"),
    league_short_code: varchar("league_short_code"),
    league_image_path: varchar("league_image_path"),
    league_type: varchar("league_type"),
    league_sub_type: varchar("league_sub_type"),
    league_last_played_at: timestamp("league_last_played_at", {
      mode: "string",
    }),
    league_category: integer("league_category"),
    league_has_jerseys: boolean("league_has_jerseys"),
    season_id: integer("season_id"),
    season_sport_id: integer("season_sport_id"),
    season_league_id: integer("season_league_id"),
    season_tie_breaker_rule_id: integer("season_tie_breaker_rule_id"),
    season_name: varchar("season_name"),
    season_finished: boolean("season_finished"),
    season_pending: boolean("season_pending"),
    season_is_current: boolean("season_is_current"),
    season_starting_at: date("season_starting_at"),
    season_ending_at: date("season_ending_at"),
    season_standings_recalculated_at: timestamp(
      "season_standings_recalculated_at",
      { mode: "string" },
    ),
    season_games_in_current_week: boolean("season_games_in_current_week"),
    round_id: integer("round_id"),
    round_sport_id: integer("round_sport_id"),
    round_league_id: integer("round_league_id"),
    round_season_id: integer("round_season_id"),
    round_stage_id: integer("round_stage_id"),
    round_name: varchar("round_name"),
    round_finished: boolean("round_finished"),
    round_is_current: boolean("round_is_current"),
    round_starting_at: date("round_starting_at"),
    round_ending_at: date("round_ending_at"),
    round_games_in_current_week: boolean("round_games_in_current_week"),
    stage_id: integer("stage_id"),
    stage_sport_id: integer("stage_sport_id"),
    stage_league_id: integer("stage_league_id"),
    stage_season_id: integer("stage_season_id"),
    stage_type_id: integer("stage_type_id"),
    stage_name: varchar("stage_name"),
    stage_sort_order: integer("stage_sort_order"),
    stage_finished: boolean("stage_finished"),
    stage_is_current: boolean("stage_is_current"),
    stage_starting_at: date("stage_starting_at"),
    stage_ending_at: date("stage_ending_at"),
    stage_games_in_current_week: boolean("stage_games_in_current_week"),
  },
);

export const stg_sts__staffInDatahub = datahub.table("stg_sts__staff", {
  id: varchar("id"),
  name: varchar("name"),
  first_name: varchar("first_name"),
  last_name: varchar("last_name"),
  short_name: varchar("short_name"),
  birth_date: varchar("birth_date"),
  birth_place: varchar("birth_place"),
  height: varchar("height"),
  alias: varchar("alias"),
  country_of_birth_german: varchar("country_of_birth_german"),
  country_of_birth_english: varchar("country_of_birth_english"),
  country_of_birth_spanish: varchar("country_of_birth_spanish"),
  nationality_german: varchar("nationality_german"),
  nationality_english: varchar("nationality_english"),
  nationality_spanish: varchar("nationality_spanish"),
  additional_nationality_one_german: varchar(
    "additional_nationality_one_german",
  ),
  additional_nationality_one_english: varchar(
    "additional_nationality_one_english",
  ),
  additional_nationality_one_spanish: varchar(
    "additional_nationality_one_spanish",
  ),
  club_id: varchar("club_id"),
  club_name: varchar("club_name"),
  season_id: varchar("season_id"),
  season: varchar("season"),
});

export const int_datahub_venue_mappingInDatahub = datahub.table(
  "int_datahub_venue_mapping",
  {
    sportmonks_id: integer("sportmonks_id"),
    sts_id: varchar("sts_id"),
    is_autogenerated: boolean("is_autogenerated"),
  },
);

export const player_mappingInDatahub = datahub.table("player_mapping", {
  sportmonks_id: integer("sportmonks_id"),
  sts_id: varchar("sts_id"),
});

export const int_sts__fixtureInDatahub = datahub.table("int_sts__fixture", {
  id: varchar("id"),
  match_day_id: varchar("match_day_id"),
  match_day: varchar("match_day"),
  match_type: varchar("match_type"),
  season: varchar("season"),
  season_id: varchar("season_id"),
  stadium_name: varchar("stadium_name"),
  neutral_venue: boolean("neutral_venue"),
  match_date_fixed: boolean("match_date_fixed"),
  match_date_time_status: varchar("match_date_time_status"),
  planned_kickoff_time: varchar("planned_kickoff_time"),
  start_date: timestamp("start_date", { mode: "string" }),
  end_date: timestamp("end_date", { mode: "string" }),
  stadium_id: varchar("stadium_id"),
  stadium_uefa_name: varchar("stadium_uefa_name"),
  stadium_city: varchar("stadium_city"),
  stadium_country: varchar("stadium_country"),
  stadium_street: varchar("stadium_street"),
  stadium_postal_code: varchar("stadium_postal_code"),
  stadium_year_of_construction: integer("stadium_year_of_construction"),
  stadium_roofed_over: boolean("stadium_roofed_over"),
  competition_id: varchar("competition_id"),
  competition_name: varchar("competition_name"),
  competition_country: varchar("competition_country"),
  competition_host: varchar("competition_host"),
  competition_type: varchar("competition_type"),
  competition_sport: varchar("competition_sport"),
  home_team_id: varchar("home_team_id"),
  home_team_name: varchar("home_team_name"),
  home_team_three_letter_code: varchar("home_team_three_letter_code"),
  home_team_competition_id: varchar("home_team_competition_id"),
  home_team_competition_name: varchar("home_team_competition_name"),
  home_team_season_id: varchar("home_team_season_id"),
  home_team_season: varchar("home_team_season"),
  home_team_stadium_id: varchar("home_team_stadium_id"),
  home_team_stadium_name: varchar("home_team_stadium_name"),
  home_team_long_name: varchar("home_team_long_name"),
  home_team_short_name: varchar("home_team_short_name"),
  home_team_long_name_english: varchar("home_team_long_name_english"),
  home_team_short_name_english: varchar("home_team_short_name_english"),
  home_team_color_one: varchar("home_team_color_one"),
  home_team_color_one_rgb: varchar("home_team_color_one_rgb"),
  home_team_color_two: varchar("home_team_color_two"),
  home_team_color_two_rgb: varchar("home_team_color_two_rgb"),
  home_team_founded: integer("home_team_founded"),
  home_team_street: varchar("home_team_street"),
  home_team_house_number: varchar("home_team_house_number"),
  home_team_postal_code: varchar("home_team_postal_code"),
  home_team_city: varchar("home_team_city"),
  home_team_country: varchar("home_team_country"),
  home_team_phone: varchar("home_team_phone"),
  home_team_fax: varchar("home_team_fax"),
  home_team_mail: varchar("home_team_mail"),
  home_team_website: varchar("home_team_website"),
  home_team_shirt_one_main_color: varchar("home_team_shirt_one_main_color"),
  home_team_shirt_one_main_color_rgb: varchar(
    "home_team_shirt_one_main_color_rgb",
  ),
  home_team_shirt_one_secondary_color: varchar(
    "home_team_shirt_one_secondary_color",
  ),
  home_team_shirt_one_secondary_color_rgb: varchar(
    "home_team_shirt_one_secondary_color_rgb",
  ),
  home_team_shirt_one_number_color: varchar("home_team_shirt_one_number_color"),
  home_team_shirt_one_number_color_rgb: varchar(
    "home_team_shirt_one_number_color_rgb",
  ),
  home_team_shirt_two_main_color: varchar("home_team_shirt_two_main_color"),
  home_team_shirt_two_main_color_rgb: varchar(
    "home_team_shirt_two_main_color_rgb",
  ),
  home_team_shirt_two_secondary_color: varchar(
    "home_team_shirt_two_secondary_color",
  ),
  home_team_shirt_two_secondary_color_rgb: varchar(
    "home_team_shirt_two_secondary_color_rgb",
  ),
  home_team_shirt_two_number_color: varchar("home_team_shirt_two_number_color"),
  home_team_shirt_two_number_color_rgb: varchar(
    "home_team_shirt_two_number_color_rgb",
  ),
  home_team_shirt_three_main_color: varchar("home_team_shirt_three_main_color"),
  home_team_shirt_three_main_color_rgb: varchar(
    "home_team_shirt_three_main_color_rgb",
  ),
  home_team_shirt_three_secondary_color: varchar(
    "home_team_shirt_three_secondary_color",
  ),
  home_team_shirt_three_secondary_color_rgb: varchar(
    "home_team_shirt_three_secondary_color_rgb",
  ),
  home_team_shirt_three_number_color: varchar(
    "home_team_shirt_three_number_color",
  ),
  home_team_shirt_three_number_color_rgb: varchar(
    "home_team_shirt_three_number_color_rgb",
  ),
  guest_team_id: varchar("guest_team_id"),
  guest_team_name: varchar("guest_team_name"),
  guest_team_three_letter_code: varchar("guest_team_three_letter_code"),
  guest_team_competition_id: varchar("guest_team_competition_id"),
  guest_team_competition_name: varchar("guest_team_competition_name"),
  guest_team_season_id: varchar("guest_team_season_id"),
  guest_team_season: varchar("guest_team_season"),
  guest_team_stadium_id: varchar("guest_team_stadium_id"),
  guest_team_stadium_name: varchar("guest_team_stadium_name"),
  guest_team_long_name: varchar("guest_team_long_name"),
  guest_team_short_name: varchar("guest_team_short_name"),
  guest_team_long_name_english: varchar("guest_team_long_name_english"),
  guest_team_short_name_english: varchar("guest_team_short_name_english"),
  guest_team_color_one: varchar("guest_team_color_one"),
  guest_team_color_one_rgb: varchar("guest_team_color_one_rgb"),
  guest_team_color_two: varchar("guest_team_color_two"),
  guest_team_color_two_rgb: varchar("guest_team_color_two_rgb"),
  guest_team_founded: integer("guest_team_founded"),
  guest_team_street: varchar("guest_team_street"),
  guest_team_house_number: varchar("guest_team_house_number"),
  guest_team_postal_code: varchar("guest_team_postal_code"),
  guest_team_city: varchar("guest_team_city"),
  guest_team_country: varchar("guest_team_country"),
  guest_team_phone: varchar("guest_team_phone"),
  guest_team_fax: varchar("guest_team_fax"),
  guest_team_mail: varchar("guest_team_mail"),
  guest_team_website: varchar("guest_team_website"),
  guest_team_shirt_one_main_color: varchar("guest_team_shirt_one_main_color"),
  guest_team_shirt_one_main_color_rgb: varchar(
    "guest_team_shirt_one_main_color_rgb",
  ),
  guest_team_shirt_one_secondary_color: varchar(
    "guest_team_shirt_one_secondary_color",
  ),
  guest_team_shirt_one_secondary_color_rgb: varchar(
    "guest_team_shirt_one_secondary_color_rgb",
  ),
  guest_team_shirt_one_number_color: varchar(
    "guest_team_shirt_one_number_color",
  ),
  guest_team_shirt_one_number_color_rgb: varchar(
    "guest_team_shirt_one_number_color_rgb",
  ),
  guest_team_shirt_two_main_color: varchar("guest_team_shirt_two_main_color"),
  guest_team_shirt_two_main_color_rgb: varchar(
    "guest_team_shirt_two_main_color_rgb",
  ),
  guest_team_shirt_two_secondary_color: varchar(
    "guest_team_shirt_two_secondary_color",
  ),
  guest_team_shirt_two_secondary_color_rgb: varchar(
    "guest_team_shirt_two_secondary_color_rgb",
  ),
  guest_team_shirt_two_number_color: varchar(
    "guest_team_shirt_two_number_color",
  ),
  guest_team_shirt_two_number_color_rgb: varchar(
    "guest_team_shirt_two_number_color_rgb",
  ),
  guest_team_shirt_three_main_color: varchar(
    "guest_team_shirt_three_main_color",
  ),
  guest_team_shirt_three_main_color_rgb: varchar(
    "guest_team_shirt_three_main_color_rgb",
  ),
  guest_team_shirt_three_secondary_color: varchar(
    "guest_team_shirt_three_secondary_color",
  ),
  guest_team_shirt_three_secondary_color_rgb: varchar(
    "guest_team_shirt_three_secondary_color_rgb",
  ),
  guest_team_shirt_three_number_color: varchar(
    "guest_team_shirt_three_number_color",
  ),
  guest_team_shirt_three_number_color_rgb: varchar(
    "guest_team_shirt_three_number_color_rgb",
  ),
});

export const int_sts__stadium_with_countryInDatahub = datahub.table(
  "int_sts__stadium_with_country",
  {
    id: varchar("id"),
    alpha2: varchar("alpha2", { length: 2 }),
    alpha3: varchar("alpha3", { length: 3 }),
    ar: varchar("ar", { length: 75 }),
    bg: varchar("bg", { length: 75 }),
    cs: varchar("cs", { length: 75 }),
    da: varchar("da", { length: 75 }),
    de: varchar("de", { length: 75 }),
    el: varchar("el", { length: 75 }),
    en: varchar("en", { length: 75 }),
    eo: varchar("eo", { length: 75 }),
    es: varchar("es", { length: 75 }),
    et: varchar("et", { length: 75 }),
    eu: varchar("eu", { length: 75 }),
    fa: varchar("fa", { length: 75 }),
    fi: varchar("fi", { length: 75 }),
    fr: varchar("fr", { length: 75 }),
    hr: varchar("hr", { length: 75 }),
    hu: varchar("hu", { length: 75 }),
    hy: varchar("hy", { length: 75 }),
    it: varchar("it", { length: 75 }),
    ja: varchar("ja", { length: 75 }),
    ko: varchar("ko", { length: 75 }),
    lt: varchar("lt", { length: 75 }),
    nl: varchar("nl", { length: 75 }),
    no: varchar("no", { length: 75 }),
    pl: varchar("pl", { length: 75 }),
    pt: varchar("pt", { length: 75 }),
    ro: varchar("ro", { length: 75 }),
    ru: varchar("ru", { length: 75 }),
    sk: varchar("sk", { length: 75 }),
    sl: varchar("sl", { length: 75 }),
    sr: varchar("sr", { length: 75 }),
    sv: varchar("sv", { length: 75 }),
    th: varchar("th", { length: 75 }),
    uk: varchar("uk", { length: 75 }),
    zh: varchar("zh", { length: 75 }),
    zh_tw: varchar("zh_tw", { length: 75 }),
    country_id: integer("country_id"),
  },
);

export const int_datahub_venue_mapping_extraInDatahub = datahub.table(
  "int_datahub_venue_mapping_extra",
  {
    sts_id: varchar("sts_id"),
    sportmonks_id: integer("sportmonks_id"),
    is_autogenerated: boolean("is_autogenerated"),
  },
);

export const venue_mappingInDatahub = datahub.table("venue_mapping", {
  sts_id: varchar("sts_id"),
  sportmonks_id: integer("sportmonks_id"),
  is_autogenerated: boolean("is_autogenerated"),
});

export const int_sportmonks__proxied_fixtureInDatahub = datahub.table(
  "int_sportmonks__proxied_fixture",
  {
    id: integer("id"),
    name: varchar("name"),
    sport_id: integer("sport_id"),
    starting_at: timestamp("starting_at", { mode: "string" }),
    result_info: varchar("result_info"),
    leg: varchar("leg"),
    length: integer("length"),
    placeholder: boolean("placeholder"),
    has_odds: boolean("has_odds"),
    starting_at_timestamp: integer("starting_at_timestamp"),
    participants: jsonb("participants"),
    home_team_id: integer("home_team_id"),
    home_team_sport_id: integer("home_team_sport_id"),
    home_team_country_id: integer("home_team_country_id"),
    home_team_venue_id: integer("home_team_venue_id"),
    home_team_gender: varchar("home_team_gender"),
    home_team_name: varchar("home_team_name"),
    home_team_short_code: varchar("home_team_short_code"),
    home_team_founded: integer("home_team_founded"),
    home_team_type: varchar("home_team_type"),
    home_team_placeholder: boolean("home_team_placeholder"),
    home_team_last_played_at: timestamp("home_team_last_played_at", {
      mode: "string",
    }),
    away_team_id: integer("away_team_id"),
    away_team_sport_id: integer("away_team_sport_id"),
    away_team_country_id: integer("away_team_country_id"),
    away_team_venue_id: integer("away_team_venue_id"),
    away_team_gender: varchar("away_team_gender"),
    away_team_name: varchar("away_team_name"),
    away_team_short_code: varchar("away_team_short_code"),
    away_team_founded: integer("away_team_founded"),
    away_team_type: varchar("away_team_type"),
    away_team_placeholder: boolean("away_team_placeholder"),
    away_team_last_played_at: timestamp("away_team_last_played_at", {
      mode: "string",
    }),
    venue_id: integer("venue_id"),
    venue_country_id: integer("venue_country_id"),
    venue_city_id: integer("venue_city_id"),
    venue_name: varchar("venue_name"),
    venue_address: varchar("venue_address"),
    venue_zipcode: varchar("venue_zipcode"),
    venue_latitude: doublePrecision("venue_latitude"),
    venue_longitude: doublePrecision("venue_longitude"),
    venue_capacity: integer("venue_capacity"),
    venue_city_name: varchar("venue_city_name"),
    venue_surface: varchar("venue_surface"),
    venue_national_team: boolean("venue_national_team"),
    venue_country_name: varchar("venue_country_name"),
    state_id: integer("state_id"),
    state_state: varchar("state_state"),
    state_name: varchar("state_name"),
    state_short_name: varchar("state_short_name"),
    state_developer_name: varchar("state_developer_name"),
    league_id: integer("league_id"),
    league_sport_id: integer("league_sport_id"),
    league_country_id: integer("league_country_id"),
    league_name: varchar("league_name"),
    league_active: boolean("league_active"),
    league_short_code: varchar("league_short_code"),
    league_type: varchar("league_type"),
    league_sub_type: varchar("league_sub_type"),
    league_last_played_at: timestamp("league_last_played_at", {
      mode: "string",
    }),
    league_category: integer("league_category"),
    league_has_jerseys: boolean("league_has_jerseys"),
    season_id: integer("season_id"),
    season_sport_id: integer("season_sport_id"),
    season_league_id: integer("season_league_id"),
    season_tie_breaker_rule_id: integer("season_tie_breaker_rule_id"),
    season_name: varchar("season_name"),
    season_finished: boolean("season_finished"),
    season_pending: boolean("season_pending"),
    season_is_current: boolean("season_is_current"),
    season_starting_at: date("season_starting_at"),
    season_ending_at: date("season_ending_at"),
    season_standings_recalculated_at: timestamp(
      "season_standings_recalculated_at",
      { mode: "string" },
    ),
    season_games_in_current_week: boolean("season_games_in_current_week"),
    round_id: integer("round_id"),
    round_sport_id: integer("round_sport_id"),
    round_league_id: integer("round_league_id"),
    round_season_id: integer("round_season_id"),
    round_stage_id: integer("round_stage_id"),
    round_name: varchar("round_name"),
    round_finished: boolean("round_finished"),
    round_is_current: boolean("round_is_current"),
    round_starting_at: date("round_starting_at"),
    round_ending_at: date("round_ending_at"),
    round_games_in_current_week: boolean("round_games_in_current_week"),
    stage_id: integer("stage_id"),
    stage_sport_id: integer("stage_sport_id"),
    stage_league_id: integer("stage_league_id"),
    stage_season_id: integer("stage_season_id"),
    stage_type_id: integer("stage_type_id"),
    stage_name: varchar("stage_name"),
    stage_sort_order: integer("stage_sort_order"),
    stage_finished: boolean("stage_finished"),
    stage_is_current: boolean("stage_is_current"),
    stage_starting_at: date("stage_starting_at"),
    stage_ending_at: date("stage_ending_at"),
    stage_games_in_current_week: boolean("stage_games_in_current_week"),
    home_team_image_path: text("home_team_image_path"),
    away_team_image_path: text("away_team_image_path"),
    venue_image_path: text("venue_image_path"),
    league_image_path: text("league_image_path"),
  },
);

export const staffInSts = sts.table(
  "staff",
  {
    id: varchar("id").notNull(),
    name: varchar("name").notNull(),
    first_name: varchar("first_name").notNull(),
    last_name: varchar("last_name").notNull(),
    short_name: varchar("short_name"),
    birth_date: varchar("birth_date"),
    birth_place: varchar("birth_place"),
    height: varchar("height"),
    alias: varchar("alias"),
    country_of_birth_german: varchar("country_of_birth_german"),
    country_of_birth_english: varchar("country_of_birth_english"),
    country_of_birth_spanish: varchar("country_of_birth_spanish"),
    nationality_german: varchar("nationality_german"),
    nationality_english: varchar("nationality_english"),
    nationality_spanish: varchar("nationality_spanish"),
    additional_nationality_one_german: varchar(
      "additional_nationality_one_german",
    ),
    additional_nationality_one_english: varchar(
      "additional_nationality_one_english",
    ),
    additional_nationality_one_spanish: varchar(
      "additional_nationality_one_spanish",
    ),
    club_id: varchar("club_id").notNull(),
    club_name: varchar("club_name").notNull(),
    season_id: varchar("season_id").notNull(),
    season: varchar("season").notNull(),
  },
  (table) => {
    return {
      staff_pkey: primaryKey({
        columns: [table.id, table.club_id, table.season_id],
        name: "staff_pkey",
      }),
    };
  },
);

export const playerInSts = sts.table(
  "player",
  {
    id: varchar("id").notNull(),
    club_id: varchar("club_id").notNull(),
    club_name: varchar("club_name").notNull(),
    name: varchar("name").notNull(),
    first_name: varchar("first_name").notNull(),
    last_name: varchar("last_name").notNull(),
    short_name: varchar("short_name").notNull(),
    alias: varchar("alias"),
    birth_date: date("birth_date"),
    birth_place: varchar("birth_place"),
    country_of_birth_german: varchar("country_of_birth_german"),
    country_of_birth_english: varchar("country_of_birth_english"),
    country_of_birth_spanish: varchar("country_of_birth_spanish"),
    nationality_german: varchar("nationality_german"),
    nationality_english: varchar("nationality_english"),
    nationality_spanish: varchar("nationality_spanish"),
    additional_nationality_one_german: varchar(
      "additional_nationality_one_german",
    ),
    additional_nationality_one_english: varchar(
      "additional_nationality_one_english",
    ),
    additional_nationality_one_spanish: varchar(
      "additional_nationality_one_spanish",
    ),
    height: integer("height"),
    weight: integer("weight"),
    shirt_number: integer("shirt_number"),
    primary_pool: boolean("primary_pool").notNull(),
    playing_position_german: varchar("playing_position_german"),
    playing_position_english: varchar("playing_position_english"),
    playing_position_spanish: varchar("playing_position_spanish"),
    local_player: varchar("local_player"),
    season_id: varchar("season_id").notNull(),
    season: varchar("season").notNull(),
  },
  (table) => {
    return {
      ix_sts_player_id: index("ix_sts_player_id").using("btree", table.id),
      player_pkey: primaryKey({
        columns: [table.id, table.club_id, table.season_id],
        name: "player_pkey",
      }),
    };
  },
);

export const clubInSts = sts.table(
  "club",
  {
    id: varchar("id").notNull(),
    name: varchar("name").notNull(),
    three_letter_code: varchar("three_letter_code").notNull(),
    competition_id: varchar("competition_id").notNull(),
    competition_name: varchar("competition_name").notNull(),
    season_id: varchar("season_id").notNull(),
    season: varchar("season").notNull(),
    stadium_id: varchar("stadium_id"),
    stadium_name: varchar("stadium_name"),
    long_name: varchar("long_name").notNull(),
    short_name: varchar("short_name").notNull(),
    long_name_english: varchar("long_name_english"),
    short_name_english: varchar("short_name_english"),
    color_one: varchar("color_one"),
    color_one_rgb: varchar("color_one_rgb"),
    color_two: varchar("color_two"),
    color_two_rgb: varchar("color_two_rgb"),
    founded: integer("founded"),
    street: varchar("street"),
    house_number: varchar("house_number"),
    postal_code: varchar("postal_code"),
    city: varchar("city"),
    country: varchar("country"),
    phone: varchar("phone"),
    fax: varchar("fax"),
    mail: varchar("mail"),
    website: varchar("website"),
    shirt_one_main_color: varchar("shirt_one_main_color"),
    shirt_one_main_color_rgb: varchar("shirt_one_main_color_rgb"),
    shirt_one_secondary_color: varchar("shirt_one_secondary_color"),
    shirt_one_secondary_color_rgb: varchar("shirt_one_secondary_color_rgb"),
    shirt_one_number_color: varchar("shirt_one_number_color"),
    shirt_one_number_color_rgb: varchar("shirt_one_number_color_rgb"),
    shirt_two_main_color: varchar("shirt_two_main_color"),
    shirt_two_main_color_rgb: varchar("shirt_two_main_color_rgb"),
    shirt_two_secondary_color: varchar("shirt_two_secondary_color"),
    shirt_two_secondary_color_rgb: varchar("shirt_two_secondary_color_rgb"),
    shirt_two_number_color: varchar("shirt_two_number_color"),
    shirt_two_number_color_rgb: varchar("shirt_two_number_color_rgb"),
    shirt_three_main_color: varchar("shirt_three_main_color"),
    shirt_three_main_color_rgb: varchar("shirt_three_main_color_rgb"),
    shirt_three_secondary_color: varchar("shirt_three_secondary_color"),
    shirt_three_secondary_color_rgb: varchar("shirt_three_secondary_color_rgb"),
    shirt_three_number_color: varchar("shirt_three_number_color"),
    shirt_three_number_color_rgb: varchar("shirt_three_number_color_rgb"),
  },
  (table) => {
    return {
      club_pkey: primaryKey({
        columns: [table.id, table.competition_id, table.season_id],
        name: "club_pkey",
      }),
    };
  },
);
