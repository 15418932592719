import { FeedEntry, Period } from "@/types";
import { useTranslation } from "react-i18next";
import kickoff from "../svg/kickoff.svg";
import halftime from "../svg/halftime.svg";
import game_end from "../svg/game_end.svg";
import goal from "../svg/goal.svg";
import substituionIn from "../svg/up-chevron-svgrepo-com.svg";
import substituionOut from "../svg/down-chevron-svgrepo-com.svg";
import yellowCard from "../svg/yellow_card.svg";
import redCard from "../svg/red_card.svg";
import yellowRedCard from "../svg/yellow_red_card.svg";
import { periodTranslations } from "@/utils";
import { InferSelectModel } from "drizzle-orm";
import { fixtureInDatahub } from "@/generated/schema";

const periodIcons: { [key in Period]: string | undefined } = {
  BEFORE_MATCH: undefined,
  "1ST_HALF": kickoff,
  "2ND_HALF": kickoff,
  HALF_TIME: halftime,
  FULL_TIME: game_end,
  BEFORE_EXTRA_TIME: undefined,
  EXTRA_1ST_HALF: undefined,
  EXTRA_HALF_TIME: undefined,
  EXTRA_2ND_HALF: undefined,
  EXTRA_FULL_TIME: undefined,
  BEFORE_PENALTY_SHOOTOUT: undefined,
  PENALTY_SHOOTOUT: undefined,
  PENALTY_FULL_TIME: undefined,
};

function getParticipantsBySide(fixture: any) {
  const { participants } = fixture;
  const home = participants.find((p: any) => p.meta.location === "home");
  const away = participants.find((p: any) => p.meta.location === "away");
  return { home, away };
}

export const FeedPeriodHeader = ({ period }: { period: Period }) => {
  const periodSvg: string | undefined = periodIcons[period];
  return (
    <div className="flex items-center justify-center py-4">
      {periodSvg && <img src={periodSvg} className="h-12" />}
      <h3
        className={`ml-4 text-xl font-bold ${
          !periodSvg ? "w-full text-center" : ""
        }`}
      >
        {periodTranslations[period]}
      </h3>
    </div>
  );
};

export const ShowcaseFeedEntryCard = ({ entry }: { entry: FeedEntry }) => {
  return (
    <CardContainer className={"p-4"}>
      <div className="flex flex-row justify-between">
        <div className="shrink-0">
          {entry.game_time.minute !== 0 && (
            <div className="w-14 shrink-0 text-gray-900">
              <GameTime
                minute={entry.game_time.minute}
                stoppage_minute={entry.game_time.stoppage_minute}
              />
            </div>
          )}
        </div>
        <div className="flex-grow text-left">
          <span className="text-slate-700">{entry.text}</span>
        </div>
      </div>
    </CardContainer>
  );
};

export const EventCard = ({ event, context }: any) => {
  if (event.type.developer_name === "GOAL") {
    return <GoalCard event={event} context={context} />;
  }
  if (event.type.developer_name === "OWNGOAL") {
    return <GoalCard event={event} context={context} />;
  }
  if (event.type.developer_name === "SUBSTITUTION") {
    return <SubstitutionCard event={event} context={context} />;
  }
  if ([19, 20, 21].includes(event.type.id))
    // var, yellow card, red card, yellow-red card, ??, VAR_CARD
    return <CardsCard event={event} />;
  // TODO(liamvdv): penalty shootout, missed penalty, 10 (VAR), 1697 (VAR_CARD)
  return null;
};

const CardContainer = ({
  className,
  children,
}: React.PropsWithChildren<{ className: string }>) => (
  <li
    className={`mb-2 flex list-none overflow-hidden rounded border border-gray-100 bg-white shadow-lg ${className} w-full`}
  >
    {children}
  </li>
);

const GameTime = ({
  minute,
  stoppage_minute,
}: {
  minute: number;
  stoppage_minute: number | undefined;
}) => (
  <div className="w-16">
    <span className="font-bold">{minute}'</span>
    {stoppage_minute !== undefined && stoppage_minute !== 0 && (
      <span className="opacity-90">+{stoppage_minute}</span>
    )}
  </div>
);

const GoalCard = ({ event }: any) => {
  const { t } = useTranslation();
  const {
    fixture,
    participant,
    // period,
    minute,
    extra_minute,
    // addition,
    type,
    player,
    // related_player,
    sub_type,
    result,
  } = event;
  if (!["GOAL", "OWNGOAL"].includes(type.developer_name))
    throw new Error("Not a goal event");

  const time = { minute, stoppage_minute: extra_minute };
  const { home, away } = getParticipantsBySide(fixture);
  const [homeValue, awayValue] = result.split("-").map(Number);
  const score = {
    home: {
      value: homeValue,
      changed: participant.id == home.id,
    },
    away: {
      value: awayValue,
      changed: participant.id == away.id,
    },
  };
  const isDisallowed =
    sub_type != null && sub_type.developer_name === "GOAL_DISALLOWED";
  return (
    <CardContainer className={""}>
      <div
        className={`relative ${
          isDisallowed ? "bg-gray-400" : "bg-red-600"
        } w-full text-white`}
      >
        <div className="absolute left-4 top-4 inline-block">
          <GameTime
            minute={time.minute}
            stoppage_minute={time.stoppage_minute}
          />
        </div>
        <div className="flex h-40 flex-col items-center justify-center">
          <img src={goal} className="h-10 fill-inherit" />
          <div className="mt-2 text-3xl font-bold">
            <span
              className={isDisallowed ? " line-through decoration-red-500" : ""}
            >
              {t("GOAL!!!")}
            </span>
            {isDisallowed && <span className="ml-2">{t("disallowed")}</span>}
          </div>
          <div className={`mx-4 space-x-1 text-2xl font-bold`}>
            <span className={score.home.changed ? "" : "opacity-50"}>
              {score.home.value}
            </span>
            <span className="opacity-75">:</span>
            <span className={score.away.changed ? "" : "opacity-50"}>
              {score.away.value}
            </span>
          </div>
          <p>
            {player.firstname}{" "}
            <span className="font-bold">{player.lastname}</span> (
            {participant.id == home.id ? home.name : away.name})
          </p>
        </div>
      </div>
    </CardContainer>
  );
};

const SubstitutionCard = ({ event }: any) => {
  const {
    fixture,
    participant,
    // period,
    minute,
    extra_minute,
    // addition,
    // type,
    player,
    related_player,
    // sub_type,
    // result,
  } = event;
  const { t } = useTranslation();
  const { home, away } = getParticipantsBySide(fixture);

  console.assert(
    event.type.developer_name === "SUBSTITUTION",
    "Not a substitution event",
  );
  const time = { minute, stoppage_minute: extra_minute };
  const inPlayer = player;
  const outPlayer = related_player;
  return (
    <CardContainer className={"p-4"}>
      <div className="flex flex-row justify-between">
        <div className="shrink-0">
          <div className="w-14 shrink-0 text-gray-900">
            <GameTime
              minute={time.minute}
              stoppage_minute={time.stoppage_minute}
            />
          </div>
        </div>

        <div className="flex w-full flex-row items-center gap-4">
          <div className="relative flex w-20 items-center justify-center md:w-28">
            <img
              src={outPlayer.image_path}
              className="h-full w-full object-contain"
            />
            <img
              src={substituionOut}
              className="absolute right-0 top-0 z-20 h-8 -translate-y-1/4 translate-x-1/2 transform "
            />
          </div>
          <div className="relative flex w-20 items-center justify-center md:w-28">
            <img
              src={inPlayer.image_path}
              className="h-full w-full object-contain"
            />
            <img
              src={substituionIn}
              className="absolute right-0 top-0 z-20 h-8 -translate-y-1/4 translate-x-1/2 transform"
            />
          </div>
          <div className="w-full">
            <p className="text-lg font-bold">{t("Substitution")}</p>{" "}
            <span className="">
              {participant.id == home.id ? home.name : away.name}
            </span>
            <p>
              {inPlayer.firstname}{" "}
              <span className="font-bold">{inPlayer.lastname}</span>
              {t(" replaces ")}
              <span className="font-bold">{outPlayer.lastname}.</span>
            </p>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};

const CardsCard = ({ event }: any) => {
  const {
    fixture,
    participant,
    // period,
    minute,
    extra_minute,
    // addition,
    // type,
    player,
    // related_player,
    // sub_type,
    // result,
  } = event;

  const { home, away } = getParticipantsBySide(fixture);

  console.assert(
    ["YELLOWCARD", "REDCARD", "YELLOWREDCARD"].includes(
      event.type.developer_name,
    ),
    "Not a card event",
  );
  const time = { minute: minute, stoppage_minute: extra_minute };

  const values =
    event.type.developer_name == "YELLOWCARD"
      ? { title: "Gelbe Karte", image: yellowCard }
      : event.type.developer_name == "REDCARD"
        ? { title: "Rote Karte", image: redCard }
        : { title: "Gelb-Rote Karte", image: yellowRedCard };
  return (
    <CardContainer className={"p-4"}>
      <div className="flex flex-row justify-between">
        <div className="shrink-0">
          <div className="w-14 shrink-0 text-gray-900">
            <GameTime
              minute={time.minute}
              stoppage_minute={time.stoppage_minute}
            />
          </div>
        </div>

        <div className="flex w-full flex-row items-center gap-4">
          <div className="flex h-28 w-28 items-center justify-center">
            <img
              src={player.image_path}
              className="h-full w-full object-contain"
            />
          </div>
          <img src={values.image} className="h-8" />
          <div className="w-full">
            <p className="text-lg font-bold">{values.title}</p>{" "}
            <span className="">
              {participant.id == home.id ? home.name : away.name}
            </span>
            <p>
              {player.firstname}{" "}
              <span className="font-bold">{player.lastname}</span>
            </p>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};

// const rankFormatter = (rank: string, language: string) => {
//   const rankFormats: Record<string, string> = {
//     german: `${rank}. Platz`,
//     english: `Rank ${rank}`,
//     spanish: `Posición ${rank}`,
//     portuguese: `Posição ${rank}`,
//     italian: `Posizione ${rank}`,
//     french: `Rang ${rank}`,
//     mandarin: `排名 ${rank}`,
//   };

//   return rank ? rankFormats[language] || `Rank ${rank}` : "";
// };

interface TeamBoxProps {
  name: string;
  logo: string;
  // rank?: string;
  // language: string;
}

const TeamBox: React.FC<TeamBoxProps> = ({ name, logo }) => (
  <div className="flex min-h-32 max-w-[40%] flex-grow flex-col items-center justify-center px-2">
    <img src={logo} alt={`${name} logo`} className="h-16" />
    <p className="text-center text-2xl font-bold">{name}</p>
    {/* {rank && <p className="text-gray-600">{rankFormatter(rank, language)}</p>} */}
  </div>
);

export const BeforeGameSummary: React.FC<{
  fixture: InferSelectModel<typeof fixtureInDatahub>;
}> = ({ fixture }) => {
  const { t } = useTranslation();

  const kickoffAt = fixture.sm_starting_at_timestamp
    ? new Date(fixture.sm_starting_at_timestamp * 1000)
    : null;

  return (
    <CardContainer className="p-4">
      <div className="grid w-full grid-cols-1">
        <div className="flex flex-row justify-between pt-2">
          <TeamBox
            name={fixture.home_team_name!}
            logo={fixture.home_team_logo!}
          />
          <div className="relative flex h-full flex-col items-center justify-center text-gray-800">
            <span className="absolute top-0 w-64 text-center text-xs text-gray-700">
              {fixture.competition} {fixture.season} <br />
              {fixture.stage} {fixture.matchday}
              {fixture.sm_leg !== "1/1" && (
                <span>
                  <br /> {t("leg", { val: fixture.sm_leg })}
                </span>
              )}
            </span>
            <span className="text-2xl font-bold">{t("vs.")}</span>
          </div>
          <TeamBox
            name={fixture.away_team_name!}
            logo={fixture.away_team_logo!}
          />
        </div>
        <div className="mt-4 flex justify-center">
          <div className="flex-grow text-center">
            <span className="text-md font-semibold">
              {t("intlDateTime", {
                val: kickoffAt,
                formatParams: {
                  val: {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  },
                },
              })}
              {/* {language === "german" ? " Uhr" : ""} */}
            </span>
            <span className="block text-xs text-gray-600">{t("Live")}</span>
          </div>
        </div>
        <div className="mt-4 flex">
          {/* <div className="shrink-0">
            <div className="w-16 font-bold text-gray-800">
              <img
                src={fixture.sm_venue_image_path}
                alt="Stadium icon"
                className="h-12"
              />
            </div>
          </div> */}
          <div className="flex-grow text-center">
            <span className="text-md font-semibold">
              {fixture.venue_name} ({fixture.venue_city})
            </span>
            <span className="block text-sm text-gray-600"></span>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};
