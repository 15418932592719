import { zodResolver } from "@hookform/resolvers/zod";
import {
  GameStateMachine,
  GameStateMachineSchema,
  Period,
  periods,
} from "@/types";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem,
} from "./ui/select";
import { periodTranslations } from "@/utils";
import { Checkbox } from "./ui/checkbox";
import { Input } from "./ui/input";
import { ref, set } from "firebase/database";
import { v7 as uuidv7 } from "uuid";
import { rdb } from "@/firebase";
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";

export default function InterventionForm({
  sessionURI,
  gameState,
}: {
  sessionURI: string;
  gameState: GameStateMachine;
}) {
  const form = useForm<GameStateMachine>({
    resolver: zodResolver(GameStateMachineSchema),
    defaultValues: {
      ...gameState,
      // NOTE(memben): Firestore Databse does not return nulled values
      period_entered_map: periods.reduce(
        (acc: Record<Period, number | null>, period) => {
          acc[period] = gameState.period_entered_map[period] ?? null; // NOTE(liamvdv): important because 0 is valid, common value.
          return acc;
        },
        {} as Record<Period, number | null>,
      ),
    },
  });

  const { toast } = useToast();

  const submitIntervention = async (
    gameState: GameStateMachine,
  ): Promise<boolean> => {
    try {
      await set(ref(rdb, `${sessionURI}/intervention`), {
        id: uuidv7(),
        game_state_machine: gameState,
      });
      return true;
    } catch (error) {
      console.error("Error submitting intervention:", error);
      return false;
    }
  };

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (data) => {
            const success = await submitIntervention(data);
            if (success) {
              toast({
                title: "Success",
                description:
                  "Intervention dispatched successfully. Might take a minute to take effect.",
              });
            } else {
              toast({
                title: "Error",
                description:
                  "Failed to schedule intervention. Please try again.",
                variant: "destructive",
              });
            }
          })}
          className="flex w-full flex-col space-y-2 pl-4"
        >
          <FormField
            control={form.control}
            name="period"
            render={({ field }) => (
              <FormItem>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select the period" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.entries(periodTranslations).map(([key, value]) => (
                      <SelectItem key={key} value={key}>
                        {value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  Select the current period in the State Machine
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="enable_extra_time"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Enable extra time</FormLabel>
                  <FormDescription>
                    Enable extra time for the game
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="enable_penalty_shootout"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Enable penalty shootout</FormLabel>
                  <FormDescription>
                    Enable penalty shootout for the game
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="enable_state_duration_constraint"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Enable state duration constraint</FormLabel>
                  <FormDescription>
                    Enable state duration constraint for the game
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
          <h2 className="text-lg font-semibold">Period Stream Time</h2>
          {periods.map((period, index) => (
            <FormField
              key={index}
              control={form.control}
              name={`period_entered_map.${period}`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{periodTranslations[period]}</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Enter the time in seconds"
                      defaultValue={field.value ?? ""}
                      {...form.register(`period_entered_map.${period}`, {
                        setValueAs: (v) =>
                          v === "" || v === null ? null : parseInt(v),
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}
          {/* <Button variant={"outline"} onClick={() => form.reset()}>
            Reset
          </Button> */}
          <Button type="submit">Overwrite</Button>
        </form>
      </Form>
    </div>
  );
}
