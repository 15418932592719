import { useNavigate } from "react-router-dom";
import Page from "./Page";
import { Button } from "./ui/button";

function NoOrgFoundPage() {
  const navigate = useNavigate();

  return (
    <Page>
      <div className="mx-auto mt-48 flex max-w-4xl flex-col items-center justify-center">
        <div className="max-w-lg">
          <p>
            Hey there, Liam here! It's great to see you - we still need to
            figure one more thing out: Which organization do you belong to?{" "}
            <br />
            Your account is not yet linked to an organization. <br />
            <strong>Ask your teammate</strong> to send you an invite link or{" "}
            <strong>contact support.</strong>
          </p>
          <div className="mt-4 flex flex-row justify-evenly">
            <Button
              
              onClick={() => {
                window.$crisp.push([
                  "set",
                  "message:text",
                  [
                    "Hey, I need help with linking my account to an organization.",
                  ],
                ]);
                window.$crisp.push(["do", "chat:open"]);
              }}
            >
              Contact Support
            </Button>{" "}
            <Button
              variant={"outline"}
              onClick={() => {
                navigate("/");
              }}
            >
              Refresh
            </Button>{" "}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default NoOrgFoundPage;
