import { InferSelectModel } from "drizzle-orm";
import JsonHeaderDisplay from "./JsonHeaderDisplay";
import { fixtureInDatahub } from "@/generated/schema";
import {
  dateFormatter,
  datetimeFormatter,
  getOrgBaseUrl,
  streamlitUrl,
} from "../utils";
import Spinner from "./Loading";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { useFeatureFlags } from "@/hooks";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { useAuth } from "@clerk/clerk-react";

function teamDetails(team: "home" | "away") {
  const isHome = team === "home";
  const sts_prefix = isHome ? "sts_home_team_" : "sts_guest_team_";
  const sm_prefix = isHome ? "sm_home_team_" : "sm_away_team_";

  const detail = {
    title: isHome ? "Home Team" : "Away Team",
    fields: [
      [
        { label: "Sportmonks ID", attr: `${sm_prefix}id` },
        { label: "STS ID", attr: `${sts_prefix}id` },
        { label: "Gender", attr: `${sm_prefix}gender` },
        { label: "Founded", attr: `${sm_prefix}founded` },
        { label: "Short Code", attr: `${sts_prefix}three_letter_code` },
        { label: "Long Name", attr: `${sts_prefix}long_name` },
        {
          label: "Shirt Main Color",
          attr: `${sts_prefix}shirt_one_main_color_rgb`,
        },
        {
          label: "Shirt Secondary Color",
          attr: `${sts_prefix}shirt_one_secondary_color_rgb`,
        },
      ],
      [
        { label: "Website", attr: `${sts_prefix}website` },
        { label: "Fax", attr: `${sts_prefix}fax` },
        { label: "Mail", attr: `${sts_prefix}mail` },
        { label: "Phone", attr: `${sts_prefix}phone` },
        { label: "Street", attr: `${sts_prefix}street` },
        { label: "Street Nr.", attr: `${sts_prefix}house_number` },
        { label: "Postal Code", attr: `${sts_prefix}postal_code` },
        { label: "City", attr: `${sts_prefix}city` },
      ],
    ],
  };
  return detail;
}

const fixtureFields = [
  [
    {
      label: "Match ID",
      attr: "id",
      href: (id: string) => getOrgBaseUrl("fixtures", id).toString(),
      detail: {
        title: "Match IDs",
        fields: [[{ label: "STS Match ID", attr: "sts_id" }]],
      },
    },
    { label: "Name", attr: "name" },
    { label: "State", attr: "final_state" },
    {
      label: "Kickoff",
      attr: "kickoff",
      formatter: (v: string) => datetimeFormatter(v ? v + "Z" : v),
    },
  ],
  [
    {
      label: "Competition",
      attr: "competition",
      detail: {
        title: "Competition",
        fields: [
          [
            { label: "Sportmonks ID", attr: "sm_league_id" },
            { label: "STS ID", attr: "sts_competition_id" },
          ],
        ],
      },
    },
    {
      label: "Season",
      attr: "season",
      detail: {
        title: "Season",
        fields: [
          [
            { label: "Sportmonks ID", attr: "sm_season_id" },
            { label: "STS ID", attr: "sts_season_id" },
            {
              label: "Start Date",
              attr: "sm_season_starting_at",
              formatter: dateFormatter,
            },
            {
              label: "End Date",
              attr: "sm_season_ending_at",
              formatter: dateFormatter,
            },
            { label: "Is Current", attr: "sm_season_is_current" },
          ],
        ],
      },
    },
    {
      label: "Stage",
      attr: "stage",
      detail: {
        title: "Stage",
        fields: [[{ label: "Sportmonks ID", attr: "sm_stage_id" }]],
      },
    },
    {
      label: "Matchday",
      attr: "matchday",
      detail: {
        title: "Stage",
        fields: [
          [
            { label: "Sportmonks ID", attr: "sm_stage_id" },
            { label: "STS ID", attr: "sts_match_day_id" },
          ],
        ],
      },
    },
  ],
  [
    {
      label: "Venue",
      attr: "venue_name",
      detail: {
        title: "Venue",
        fields: [
          [
            { label: "Sportmonks ID", attr: "sm_venue_id" },
            { label: "STS ID", attr: "sts_stadium_id" },
            { label: "UEFA Name", attr: "sts_stadium_uefa_name" },
            { label: "Capacity", attr: "sm_venue_capacity" },
            {
              label: "Year of Construction",
              attr: "sts_stadium_year_of_construction",
            },
            { label: "Surface", attr: "sm_venue_surface" },
            { label: "Roofed Over", attr: "sts_stadium_roofed_over" },
            // TODO(liamvdv): consider if we want more here.
          ],
        ],
      },
    },
    {
      label: "City",
      attr: "venue_city",
      detail: {
        title: "City",
        fields: [
          [
            { label: "Sportmonks ID", attr: "sm_venue_city_id" },
            // TODO(liamvdv): consider if we want more here.
          ],
        ],
      },
    },
    {
      label: "Country",
      attr: "venue_country",
      detail: {
        title: "Country",
        fields: [[{ label: "Sportmonks ID", attr: "sm_venue_country_id" }]],
      },
    },
  ],
  [
    {
      label: "Home Team",
      attr: "home_team_name",
      detail: teamDetails("home"),
    },
    {
      label: "Away Team",
      attr: "away_team_name",
      detail: teamDetails("away"),
    },
  ],
];

export default function FixtureInfoCard({
  fixture,
  isLoading,
  error,
}: {
  fixture: InferSelectModel<typeof fixtureInDatahub> | null;
  isLoading: boolean;
  error: Error | null;
}) {
  const { isFeatureEnabled } = useFeatureFlags();
  const { orgId } = useAuth();
  if (isLoading) {
    return <Spinner />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (!fixture) {
    return <div>Fixture not found</div>;
  }
  return (
    <JsonHeaderDisplay
      variant="card"
      title="Fixture"
      fields={fixtureFields}
      data={fixture}
    >
      {isFeatureEnabled("transcriptions") && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <EllipsisVerticalIcon className="h-6 w-6"></EllipsisVerticalIcon>
            {/* <Button variant="outline">Actions</Button> */}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <a
                href={streamlitUrl("Create_Transcription", {
                  fixture_id: String(fixture.id),
                  organization_id: orgId!,
                })}
                target="_blank"
                rel="noreferrer"
              >
                Create Transcription
              </a>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </JsonHeaderDisplay>
  );
}
